import React, { useState } from 'react';

// MUI import
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import IconButton from '@mui/material/IconButton';
import CloseIcon from "@mui/icons-material/Close";
import CustomButton from './CustomButton';

const Confirmation = (props) => {

    const {
        open,
        setOpen,
        headerContent = "Confirmación",
        textContent,
        confirmationButtonText = "Confirmar",
        cancelButtonText = "Cancelar",
        confirmationMethod
    } = props;

    // form controls
    const [locked, setLocked] = useState(false);
    // const [errors, setErrors] = useState([]);

    const handleConfirmation = async () => {
        setLocked(true);
        await confirmationMethod();
        setLocked(false);
    }

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>
                    <Grid container alignItems={"center"}>
                        <Grid size={10} align="left">
                            <Typography variant="h5" align="left">
                                <Box sx={{ fontWeight: "bold" }}>
                                    {headerContent}
                                </Box>
                            </Typography>
                        </Grid>
                        <Grid size={2} align="right">
                            <IconButton onClick={() => handleClose()}>
                                <CloseIcon fontSize="medium" color="primary" />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>

                <DialogContent dividers>

                    <Typography variant="h5" align="left">
                        {textContent}
                    </Typography>

                </DialogContent>
                <DialogActions>
                    <CustomButton 
                      locked={locked}
                      variant="contained"
                      color="success"
                      buttonTrigger={handleConfirmation}
                      buttonText={confirmationButtonText}
                    />
                    <Button
                        onClick={handleClose}
                        variant='outlined'
                        color='primary'
                        disabled={locked}
                    >
                        {cancelButtonText}
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );

}

export default Confirmation;