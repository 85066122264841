const { FuzzySet, DefuzzicationType, LinguisticVariable, FuzzyInferenceSystem } = require("javascript-fuzzylogic");
const { generateParameterTrapezoidal, generateParameterTriangular } = require("./FuzzyParameters");

//***** FuzzyLogic for Risk assessment *****//
/// based on the payment(pesos) and delay (Weeks) of a customer, a risk status will be generated 

//***** LinguisticVariable declaration *****//
// - payment (Input 1)
// - delay (Input 2)
// - risk (Output 1)

//***** FuzzySet declaration *****//

//*** Input 1: payment value
const paymentMuyPoco = new FuzzySet('muypoco');
paymentMuyPoco.generateMembershipValues({
    type: 'Trapezoidal',
    parameters: generateParameterTrapezoidal(0, 0, 15, 25, 0, 100, 1)
})

const paymentPoco = new FuzzySet('poco');
paymentPoco.generateMembershipValues({
    type: 'Trapezoidal',
    parameters: generateParameterTrapezoidal(15, 25, 35, 45, 0, 100, 1)
})

const paymentNormal = new FuzzySet('normal');
paymentNormal.generateMembershipValues({
    type: 'Trapezoidal',
    parameters: generateParameterTrapezoidal(35, 45, 55, 65, 0, 100, 1)
})

const paymentMucho = new FuzzySet('mucho');
paymentMucho.generateMembershipValues({
    type: 'Trapezoidal',
    parameters: generateParameterTrapezoidal(55, 65, 75, 85, 0, 100, 1)
})

const paymentTodo = new FuzzySet('todo');
paymentTodo.generateMembershipValues({
    type: 'Trapezoidal',
    parameters: generateParameterTrapezoidal(75, 85, 100, 100, 0, 100, 1)
})


//*** Input 2: delay value
const delayNormal = new FuzzySet('normal');
delayNormal.generateMembershipValues({
    type: 'Trapezoidal',
    parameters: generateParameterTrapezoidal(0, 0, 14, 21, 0, 1200, 1)
})

const delayAlgoDeAtraso = new FuzzySet('algodeatraso');
delayAlgoDeAtraso.generateMembershipValues({
    type: 'Triangular',
    parameters: generateParameterTriangular(14, 21, 35, 0, 1200, 1)
})

const delayMuyAtrasado = new FuzzySet('muyatrasado');
delayMuyAtrasado.generateMembershipValues({
    type: 'Triangular',
    parameters: generateParameterTriangular(28, 35, 49, 0, 1200, 1)
})

const delayCasiPerdido = new FuzzySet('casiperdido');
delayCasiPerdido.generateMembershipValues({
    type: 'Trapezoidal',
    parameters: generateParameterTrapezoidal(42, 49, 1200, 1200, 0, 1200, 1)
})


//*** Output 1: risk value
const riskNormal = new FuzzySet('normal');
riskNormal.generateMembershipValues({
    type: 'Trapezoidal',
    parameters: generateParameterTrapezoidal(0, 20, 20, 30, 0, 100, 1)
})

const riskWarning = new FuzzySet('warning');
riskWarning.generateMembershipValues({
    type: 'Trapezoidal',
    parameters: generateParameterTrapezoidal(20, 30, 45, 55, 0, 100, 1)
})

const riskDanger = new FuzzySet('danger');
riskDanger.generateMembershipValues({
    type: 'Trapezoidal',
    parameters: generateParameterTrapezoidal(45, 55, 70, 80, 0, 100, 1)
})

const riskCritical = new FuzzySet('critical');
riskCritical.generateMembershipValues({
    type: 'Trapezoidal',
    parameters: generateParameterTrapezoidal(70, 80, 100, 100, 0, 100, 1)
})

//***** Fuzzy Variables declaration *****//
const paymentVariable = new LinguisticVariable('payment')
    .addSet(paymentMuyPoco)
    .addSet(paymentPoco)
    .addSet(paymentNormal)
    .addSet(paymentMucho)
    .addSet(paymentTodo)

const delayVariable = new LinguisticVariable('delay')
    .addSet(delayNormal)
    .addSet(delayAlgoDeAtraso)
    .addSet(delayMuyAtrasado)
    .addSet(delayCasiPerdido)

const riskVariable = new LinguisticVariable('risk')
    .addSet(riskNormal)
    .addSet(riskWarning)
    .addSet(riskDanger)
    .addSet(riskCritical)


//***** Fuzzy Inference System declaration *****//

const customerRiskFIS = new FuzzyInferenceSystem('customerRisk')
    .addInput(paymentVariable)
    .addInput(delayVariable)
    .addOutput(riskVariable);


//***** Fuzzy Inference System - Rules declaration *****//

customerRiskFIS.addRule('IF payment IS muypoco AND delay IS normal THEN risk IS normal')
customerRiskFIS.addRule('IF payment IS muypoco AND delay IS algodeatraso THEN risk IS warning')
customerRiskFIS.addRule('IF payment IS muypoco AND delay IS muyatrasado THEN risk IS danger')
customerRiskFIS.addRule('IF payment IS muypoco AND delay IS casiperdido THEN risk IS critical')
customerRiskFIS.addRule('IF payment IS poco AND delay IS normal THEN risk IS normal')
customerRiskFIS.addRule('IF payment IS poco AND delay IS algodeatraso THEN risk IS normal')
customerRiskFIS.addRule('IF payment IS poco AND delay IS muyatrasado THEN risk IS warning')
customerRiskFIS.addRule('IF payment IS poco AND delay IS casiperdido THEN risk IS danger')
customerRiskFIS.addRule('IF payment IS normal AND delay IS normal THEN risk IS normal')
customerRiskFIS.addRule('IF payment IS normal AND delay IS algodeatraso THEN risk IS normal')
customerRiskFIS.addRule('IF payment IS normal AND delay IS muyatrasado THEN risk IS warning')
customerRiskFIS.addRule('IF payment IS normal AND delay IS casiperdido THEN risk IS danger')
customerRiskFIS.addRule('IF payment IS mucho AND delay IS normal THEN risk IS normal')
customerRiskFIS.addRule('IF payment IS mucho AND delay IS algodeatraso THEN risk IS normal')
customerRiskFIS.addRule('IF payment IS mucho AND delay IS muyatrasado THEN risk IS warning')
customerRiskFIS.addRule('IF payment IS mucho AND delay IS casiperdido THEN risk IS warning')
customerRiskFIS.addRule('IF payment IS todo AND delay IS normal THEN risk IS normal')
customerRiskFIS.addRule('IF payment IS todo AND delay IS algodeatraso THEN risk IS normal')
customerRiskFIS.addRule('IF payment IS todo AND delay IS muyatrasado THEN risk IS normal')
customerRiskFIS.addRule('IF payment IS todo AND delay IS casiperdido THEN risk IS warning')

//***** Fuzzy Logic calculation of the Risk *****//
export const calculateCustomerRiskFL = (body) => {
    try {
      const riskValue = customerRiskFIS.solve("Mamdani", body, DefuzzicationType.Centroid);
  
      // Validate if riskValue is a valid number
      if (typeof riskValue !== 'number' || isNaN(riskValue)) {
        console.warn('Invalid risk value detected. Returning default risk value of 1.', body );
        return 1;
      }
  
      return riskValue;
    } catch (error) {
      console.error("Error calculating risk value:",body, error.message);
      // Return default risk value in case of error
      return 1;
    }
  };

