import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
// import WarningIcon from "@mui/icons-material/Warning";
import RemoveCircleOutlineRoundedIcon from "@mui/icons-material/RemoveCircleOutlineRounded";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Grid2 as Grid } from "@mui/material";
import { ItemBox } from "../../components/Helper/MUIStyledComponents";

const EmployeeInfoBox = ({ employee }) => {
  console.log(employee);

  const employeeStatusIcon = () => {
    let icon = "";
    if (employee?.isactive) {
      icon = <CheckCircleIcon color={"success"} sx={{ fontSize: 65 }} />;
    } else {
      icon = (
        <RemoveCircleOutlineRoundedIcon color={"error"} sx={{ fontSize: 65 }} />
      );
    }

    const text = employee?.isactive
      ? "Empleado activo"
      : "Este empleado esta bloqueado.";

    return (
      <Tooltip title={text} placement="left" arrow={true}>
        {icon}
      </Tooltip>
    );
  };

  return (
    <ItemBox sx={{}}>
      <Grid container spacing={0.5}>
        <Grid size={12}>
          <Grid container>
            <Grid size={10}>
              <Typography
                fontSize={{ xs: 20, md: 30 }}
                align="left"
                component="div"
                sx={{ ml: 1 }}
              >
                <Box sx={{}}>{`${employee.employee_fullname}`}</Box>
              </Typography>
            </Grid>
            <Grid size={2} align="right">
              <Box>{employeeStatusIcon()}</Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid size={12}>
          <Grid
            container
            spacing={1}
            padding={1}
            // margin={2}
            alignItems="flex-end"
          >
            <Grid container size={9} spacing={1}>
              <Grid size={12} sx={{ display: "flex", alignItems: "center" }}>
                ID: {employee.employee_id}
              </Grid>
            </Grid>
            <Grid size={3}></Grid>
          </Grid>
        </Grid>
      </Grid>
    </ItemBox>
  );
};

export default EmployeeInfoBox;
