import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import WarningIcon from "@mui/icons-material/Warning";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { GiNightSleep } from "react-icons/gi";
import { VscDebugRestart } from "react-icons/vsc";
import { GiSheep } from "react-icons/gi";
import DoNotTouchIcon from '@mui/icons-material/DoNotTouch';
import { Grid2 as Grid } from "@mui/material";
import { ItemBox } from "../../components/Helper/MUIStyledComponents";
import {
  RiskStatusLabel,
  RiskWithLabel,
  calculateAverageRisk,
  calculateRisk,
  formatRiskIconWithLabel,
} from "../../components/Helper/LoanHelpers";

const CustomerInfoBox = ({ customer, loansOnMeter, currentLoan }) => {
  const riskAverage = calculateAverageRisk(currentLoan, customer.riskaverage);

  const customerStatusTooltip = () => {
    const color =
      customer.status === "BLOCKED" || loansOnMeter.length > 0
        ? "alarm"
        : customer.status === "ACTIVE"
        ? "good"
        : customer.status === "DISABLED"
        ? "disabled"
        : "warning";

    let icon = "";
    if (loansOnMeter.length > 0) {
      icon = <InfoIcon color={color} />;
    } else {
/*       switch (customer.status) {
        case "ACTIVE":
          icon = <GiSheep style={{ color }} size={65} />;
          break;
        case "INACTIVE":
          icon = <VscDebugRestart color={color} size={65} />;
          break;
        case "BLOCKED":
          icon = <DoNotTouchIcon color={color} size={65} />;
          break;
        case "DISABLED":
          icon = <GiNightSleep color={color} size={65}/>
        default:
          break; */
          switch (customer.status) {
            case "ACTIVE":
              icon = <GiSheep className="icon-big good" size={65}/>;
              break;
            case "INACTIVE":
              icon = <VscDebugRestart className="icon-big warning" size={65}/>;
              break;
            case "BLOCKED":
              icon = <DoNotTouchIcon color={color} className="icon-big alarm"  size={65}/>;
              break;
            case "DISABLED":
              icon = <GiNightSleep className="icon-big disabled" size={65}/>
            default:
              break;
      }
    }

    const text =
      customer.status === "BLOCKED"
        ? "Este cliente esta bloqueado."
        : loansOnMeter.length > 0
        ? "Ya existe un crédito activo usando el mismo #Medidor."
        : customer.status === "ACTIVE"
        ? "Este cliente tiene un crédito activo."
        : customer.status === "DISABLED" 
        ? "Este cliente está inactivo"
        : "Este cliente está libre para renovar";

    return (
      <Tooltip title={text} placement="left" arrow={true}>
        <Typography variant="body1" align="right" component="div">
        {icon}
      </Typography>
      </Tooltip>
    );
  };

  return (
    <ItemBox sx={{}}>
      <Grid container spacing={0.5}>
        <Grid size={12}>
          <Grid container>
            <Grid size={10}>
              <Typography
                fontSize={{ xs: 20, md: 30 }}
                align="left"
                component="div"
                sx={{ ml: 1 }}
              >
                <Box sx={{}}>
                  {`${customer.customer_name} ${customer.customer_lastname}`}
                </Box>
              </Typography>
            </Grid>
            <Grid size={2} align="right">
              <Box>{customerStatusTooltip()}</Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid size={12}>
          <Grid
            container
            spacing={1}
            padding={1}
            // margin={2}
            alignItems="flex-end"
          >
            <Grid container size={9} spacing={1}>
              <Grid size={12} sx={{ display: "flex", alignItems: "center" }}>
                <Typography variant="h6" align="left" component="div">
                  <Box>#Medidor:</Box>
                </Typography>
                <Typography variant="h6" align="left" component="div">
                  <Box sx={{ ml: 2 }}>{customer.customer_meternumber}</Box>
                </Typography>
              </Grid>
              <Grid size={12} sx={{ display: "flex", alignItems: "center" }}>
                <Typography variant="h6" align="left" component="div">
                  <Box>Riesgo promedio:</Box>
                </Typography>

                <Typography variant="h6" align="left" component="div">
                  <Box sx={{ ml: 2 }}>
                    {formatRiskIconWithLabel(riskAverage)}
                  </Box>
                </Typography>
              </Grid>
            </Grid>
            <Grid size={3}>
              <Typography
                fontSize={{ xs: 32, md: 40 }}
                // variant="h3"
                align="right"
                component="div"
                // sx={{ ml: 1 }}
              >
                <Box sx={{}}>ID {customer.customer_idnumber}</Box>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ItemBox>
  );
};

export default CustomerInfoBox;
