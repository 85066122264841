import React, { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { menuSettings } from "./MenuData";
import { NovaContext } from "../context/index";

import { Divider, IconButton, ListItemIcon, Tooltip } from "@mui/material";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import MenuItem from "@mui/material/MenuItem";
import { Logout, PersonAdd } from "@mui/icons-material";
import { NavLink } from "react-router-dom";
import { renderIcon } from "../Helper/GlobalHelpers";
import Employee_Password_Edit from "../../pages/Modal/Employee_Password_Edit";
import ThemeModeSwitch from "../Theme/ThemeModeSwitch";

const NavBarMenuProfile = () => {
  const { authenticatedUser, currentRole } = useContext(NovaContext);

  let navigate = useNavigate();

  const [anchorElUser, setAnchorElUser] = useState(null);
  // const [anchorEl, setAnchorEl] = useState(null);
  // const open = Boolean(anchorEl);

  const handleSignout = () => {
    const path = `/signout`;
    navigate(path);
  };

  // const [uiLocation, setUiLocation] = useState(history.location.pathname);
  const location = useLocation();

  const handleOpenNavigationItem = (path) => {
    // setUiLocation(path);
    navigate(path);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  // const initials = authenticatedUser
  //   ? authenticatedUser.employee_name.substring(0, 1) +
  //     authenticatedUser.employee_lastname.substring(0, 1)
  //   : "XX";
  const initials = authenticatedUser
    ? authenticatedUser.employee_name.charAt(0) +
      authenticatedUser.employee_lastname.charAt(0)
    : "XX";

  return (
    <Box>
      <Tooltip title="Menú del perfil">
        <Box
          onClick={handleOpenUserMenu}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Typography
            textAlign="center"
            sx={{ display: { xs: "none", md: "flex" }, color: "white" }}
          >
            {authenticatedUser.employee_name} ( {currentRole} )
          </Typography>
          <IconButton>
            <Avatar
              sx={{
                width: 32,
                height: 32,
                bgcolor: (theme) =>
                  theme.palette.mode === "light" ? "#f30c84" : "gray",  
                color: "white", 
              }}
            >
              {initials}
            </Avatar>{" "}
          </IconButton>
        </Box>
      </Tooltip>
      {/* TBD - define the list of items to display here */}
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
        disableAutoFocus={true}
      >
        <Box
          sx={{
            display: { xs: "flex", md: "none" },
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography textAlign="center">
            {authenticatedUser.employee_name}
          </Typography>
          <Typography textAlign="center">( {currentRole} )</Typography>
        </Box>

        <Employee_Password_Edit employee={authenticatedUser} />

        <ThemeModeSwitch />

        <Divider />
        {menuSettings.map((item) => (
          <NavLink
            to={item.link}
            key={item.link}
            // style={{ textDecoration: "none", color: "black", borderRadius: "4px", ml: { xs:0, md: 1 }}}
            // activeStyle={{ backgroundColor: "#9c27b0", color: "white" }}
          >
            <MenuItem>
              <ListItemIcon>{renderIcon(item)}</ListItemIcon>
              <Typography>{item.title}</Typography>
            </MenuItem>
          </NavLink>
        ))}

        <MenuItem key="setting-Logout" onClick={() => handleSignout()}>
          <ListItemIcon>
            <Logout fontSize="medium" />
          </ListItemIcon>
          <Typography textAlign="center">Cerrar Sessión</Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
};
export default NavBarMenuProfile;
