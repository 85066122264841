import React, { useEffect } from "react";

import { getPayments } from "../../utils/api/api_payments";
import { usePaymentsContext } from "../context/PaymentsContext";

//reducers
import { PaymentActions } from "../../reducers/PaymentReducer";
import { LoadingActions } from "../../reducers/LoadingReducer";

// misc
import { alerts, displayAlert } from "../Helper/AlertToastify";

const PaymentsController = () => {
  const {
    filial,
    dateRange,
    dispatchPaymentList,
    dispatchLoading,
  } = usePaymentsContext();

  const start = dateRange.start.toISOString();
  const end = dateRange.end.toISOString();
  useEffect(() => {
    getPayments(`filial=${filial}&start=${start}&end=${end}`)
      .then((resp) => {
        dispatchPaymentList({
          type: PaymentActions.SET_PAYMENT,
          payload: resp,
        });
      })
      .catch((err) =>
        displayAlert(alerts.ERROR, `No se han podido cargar los pagos: ${err}`)
      )
      .finally(() =>
        dispatchLoading({ type: LoadingActions.SET_LOADING, payload: false })
      );
  }, [filial, dateRange]);

  return null;
};

export default PaymentsController;
