import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { NovaContext } from "../../components/context";
import {
  calculateDueDateChange,
  formatDateToDisplay,
} from "../../components/Helper/LoanHelpers";
import { addHours, format, formatISO, getDay, parseISO } from "date-fns";
import {
  checkAccess,
  getTimeDifferenceInHours,
} from "../../components/Helper/GlobalHelpers";
import CloseIcon from "@mui/icons-material/Close";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  Grid2 as Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import {
  CustomDialog,
  CustomSwitch,
  ItemPaper,
} from "../../components/Helper/MUIStyledComponents";
import ValidateLoan from "../../components/validation/validateLoan";
import ValidateCustomer from "../../components/validation/validateCustomer";
import { loanStatus, WeekDayDueToPayment } from "../../components/Helper/Enums";
import { alerts, displayAlert } from "../../components/Helper/AlertToastify";
import { LoanActions } from "../../reducers/LoansReducer";
import { updateLoan } from "../../utils/api/api_loan";
import { useLoansContext } from "../../components/context/LoansContext";

// import ValidateLoan from "../validation/validateLoan";
// import ValidateCustomer from "../validation/validateCustomer";

const Loan_PostponeDueDate = (props) => {
  const { loan, open, toggleDialog } = props;

  const { actions, filial,  } = useContext(
    NovaContext
  );
  const {
    dispatchLoanList,
  } = useLoansContext();

  const navigate = useNavigate();

  const [dueDay, setDueDay] = useState("1");
  const [dueDate, setDueDate] = useState(loan?.loan_datedue);
  const [extendFlag, setExtendFlag] = useState(false);
  const [errors, setErrors] = useState([]);
  const [lockStatus, setLockStatus] = useState(false);

  const newDueDate = dueDate
    ? calculateDueDateChange(dueDate, dueDay, extendFlag)
    : "";

  const checkForErrors = (fieldId) => {
    const nameError = errors.find((error) => error.label === fieldId);
    return nameError !== undefined ? nameError.message : null;
  };

  const handlePostpone = async (e) => {
    e.preventDefault();
    const tempLoan = {
      dueDay: dueDay,
      dueDate: formatISO(newDueDate),
      filialId: filial,
    };
    // add if/else to see if errors exists. If so then don't allow closure of pop up

    // if (!loanResponse) {
    //   displayAlert(alerts.ERROR, "Algo ha fallado en la solicitud");
    // } else {
    //   displayAlert(alerts.SUCCESS, "Préstamo modificado con éxito");
    // }

    // await actions.updateLoan(tempLoan, loan.loan_id);  //used from the main NovaContext
    await updateLoan(tempLoan, loan.loan_id)
      .then((resp) => {
        if (resp.status === 200) {
          displayAlert(alerts.SUCCESS, "Préstamo modificado con éxito");
          // setConfirmation(false);
          // setSelectedLoan();
          console.log(resp.data);
          
          dispatchLoanList({
            type: LoanActions.UPDATE_LOAN,
            payload: resp.data,
          });
        } else {
          displayAlert(alerts.ERROR, `${resp.data}`);
        }
      })
      .catch((err) =>
        displayAlert(
          alerts.ERROR,
          `Algo ha fallado en la solicitud: ${err.data}`
        )
      );
    // setLockStatus(false);
    closeDialog();
  };

  const handleChange = (event) => {
    const newDueDay = event.target.value;
    console.log(newDueDay, dueDate, loan.loan_datedue);
    setDueDay(newDueDay);
  };

  const handleExtendDueDate = () => {
    setExtendFlag(!extendFlag);
  };

  const closeDialog = () => {
    toggleDialog(false);
    setExtendFlag(false);
  };

  useEffect(() => {
    if (loan) {
      setDueDay(loan.loan_dueday);
      setDueDate(loan.loan_datedue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loan]);

  return (
    <form onSubmit={handlePostpone}>
      {loan ? (
        <CustomDialog open={open} onClose={closeDialog}>
          <DialogTitle id="form-dialog-title">
            <Grid container alignItems={"center"}>
              <Grid size={10} align="left">
                <Typography variant="h5" align="left">
                  <Box sx={{ fontWeight: "bold" }}>Cambiar fecha de pago</Box>
                </Typography>
              </Grid>
              <Grid size={2} align="right">
                <IconButton onClick={() => closeDialog()}>
                  <CloseIcon fontSize="medium" color="primary" />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <Divider />
          <DialogContent>
            <Grid container>
              <ItemPaper>
                <Grid container spacing={2}>
                  <Grid size={12}>
                    <Box>
                      <Typography variant="h6" align="left">
                        {`Fecha de pago original:`}{" "}
                        {` ${WeekDayDueToPayment[loan.loan_dueday - 1].label} `}
                        {`${formatDateToDisplay(loan.loan_datedue)}`}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid size={12}>
                    <TextField
                      id="dueDay"
                      label="Día de pago"
                      sx={{ textAlign: "left" }}
                      value={dueDay}
                      onChange={(e) => handleChange(e)}
                      // slotProps={{ shrink: true }}
                      size="small"
                      select
                      fullWidth
                      helperText={checkForErrors("dueDay")}
                      error={checkForErrors("dueDay") ? true : false}
                    >
                      <MenuItem name="0" value="">
                        -- Seleccionar --
                      </MenuItem>
                      {WeekDayDueToPayment &&
                        WeekDayDueToPayment.map((item, index) => {
                          return (
                            <MenuItem
                              key={index}
                              name={item.label}
                              value={item.value}
                            >
                              {item.label}
                            </MenuItem>
                          );
                        })}
                    </TextField>
                  </Grid>
                  <Grid size={7}>
                    <FormControlLabel
                      control={
                        <CustomSwitch onChange={() => handleExtendDueDate()} />
                      }
                      label={<Typography variant="h6">{"Posponer"}</Typography>}
                      labelPlacement={"end"}
                      sx={{
                        justifyContent: "flex-start", // Align to the left
                        width: "100%", // Ensure it spans the full width of the container
                        ml: 1,
                      }}
                    />
                  </Grid>
                  <Grid size={5}>
                    <Box>
                      <Typography
                        variant="h6"
                        align="right"
                      >{`Nueva Fecha de pago:`}</Typography>
                      {dueDay && newDueDate ? (
                        <Typography variant="h5" align="right" color="primary">
                          {/* {` ${ dueDay ? WeekDayDueToPayment[dueDay - 1].label: '-'}`}{" "}
                        {newDueDate && newDueDate != ''
                          ? format(parseISO(newDueDate), "dd/MM/yyyy")
                          : "-"} */}
                          {WeekDayDueToPayment[dueDay - 1].label +
                            " " +
                            format(newDueDate, "dd/MM/yyyy")}
                          {/* {dueDay  + ' ' + newDueDate} */}
                        </Typography>
                      ) : null}
                    </Box>
                  </Grid>
                </Grid>
              </ItemPaper>
            </Grid>
          </DialogContent>
          <Divider />
          <DialogActions>
            {extendFlag ? (
              <Typography variant="h6" align="left" component="div">
                <Box sx={{ mr: 2, color: "blue" }}> Posponiendo 1 Semana</Box>
              </Typography>
            ) : null}

            <Button
              onClick={handlePostpone}
              color="success"
              variant="contained"
              // disabled= { loan && dueDay === loan.loan_dueday ? false : true}
            >
              Cambiar
            </Button>
            <Button autoFocus onClick={closeDialog} variant="outlined">
              Cancelar
            </Button>
          </DialogActions>
        </CustomDialog>
      ) : null}
    </form>
  );
};
export default Loan_PostponeDueDate;
