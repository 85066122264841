import React, { useContext, useEffect, useState } from "react";
import { NovaContext } from "../../components/context";
import {
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid2 as Grid,
  Paper,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  calculateReportDates,
  checkMinAccess,
  filterFilialsWithAccess,
  getTimeDifferenceInHours,
  noAccessMessage,
} from "../../components/Helper/GlobalHelpers";
import { addHours, format } from "date-fns";
import { calculateReportDataTotals } from "../../components/Helper/ReportsHelpers";
import TableReport from "../../components/TableReport";
import Loading from "../../components/Actions/Loading";

const Item = styled(Paper)(({ theme }) => ({
  // margin: theme.spacing(1),
  padding: theme.spacing(1.5),
  textAlign: "center",
  color: theme.palette.text.primary,
  boxShadow: "none",
  borderRadius: 20,
  height: "100%",
}));

const ItemTitel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: "1.5rem",
  fontWeight: "bold",
}));

const Index = () => {
  const { actions } = useContext(NovaContext);
  const [clusters, setClusters] = useState([]);
  const [selectedCluster, setSelectedCluster] = useState("");
  const [filials, setFilials] = useState([]);
  const [filialsFullList, setFilialsFullList] = useState([]);

  let hasMinRegionalManagerAccess = checkMinAccess(filials, "Gerente Regional");
  let hasMinAuditorAccess = checkMinAccess(filials, "Auditor");

  const [reportMode, setReportMode] = useState("WEEK");
  const [loading, setLoading] = useState(true);
  const [range, setRange] = useState({});

  const [currentDateTime, setCurrentDateTime] = useState("");

  const [reportData1, setReportData1] = useState([]);
  const [reportData2, setReportData2] = useState([]);
  const [reportData3, setReportData3] = useState([]);
  const [reportData4, setReportData4] = useState([]);
  const [reportData5, setReportData5] = useState([]);
  const [reportData6, setReportData6] = useState([]);
  const [reportData7, setReportData7] = useState([]);
  const [totalRow1, setTotalRow1] = useState([]);
  const [totalRow2, setTotalRow2] = useState([]);
  const [totalRow3, setTotalRow3] = useState([]);
  const [totalRow4, setTotalRow4] = useState([]);
  const [totalRow5, setTotalRow5] = useState([]);
  const [totalRow6, setTotalRow6] = useState([]);
  const [totalRow7, setTotalRow7] = useState([]);
  const headersTable1 = ["Sucursal", "Total [$]", "# Clientes"];
  const headersTable2 = [
    "Sucursal",
    "Meta [$]",
    "Monto [$]",
    "% Meta",
    "% Mora",
  ];
  const headersTable3 = ["Sucursal", "# Clientes", "Monto [$]", "% Riesgo"];
  const headersTable4 = ["Sucursal", "Nuevos", "Renovados", "Cancelados"];
  const headersTable5 = [
    "Sucursal",
    "Total [$]",
    "Refrendo [$]",
    "Mora [$]",
    "Capital [$]",
    "Prestado [$]",
    "Crecimiento [$]",
  ];
  const headersTable6 = ["Sucursal", "Oficina [$]", "Nominas [$]", "Total [$]"];
  const headersTable7 = hasMinRegionalManagerAccess
    ? ["Sucursal", "Traslados [$]", "Saldo [$]"]
    : ["Sucursal", "Saldo [$]"];
  const contentPosition = {
    headerStyle: "text-center",
    firstColumnStyle: "text-left",
    otherColumnsStyle: "text-center",
  };

  const handleChange = (event) => {
    setSelectedCluster(event.target.value);
  };

  useEffect(() => {
    setLoading(true);
    setCurrentDateTime(format(new Date(), "dd/MM/yyyy HH:mm:ss") + " hrs");
    const currentDate = addHours(new Date(), +getTimeDifferenceInHours());
    const DataRange = calculateReportDates(reportMode, currentDate);
    setRange(DataRange);
    let tempFilials = [];
    let filteredFilials = [];

    if (selectedCluster === "") {
      actions.getClusterList().then((data) => {
        setClusters(data);
        setSelectedCluster(data[0].id);
      });
    }

    if (filialsFullList && filialsFullList.length !== 0) {
      tempFilials = [...filialsFullList];
      filterFilialsWithAccess(tempFilials);
      if (selectedCluster === "0") {
        filteredFilials = tempFilials;
      } else {
        filteredFilials = tempFilials.filter(
          (filial) => filial.filial_cluster === selectedCluster
        );
      }

      setFilials(filteredFilials);
      getFilialsReportData(filteredFilials, DataRange);
    } else {
      actions.getFilials().then((data) => {
        tempFilials = [...data];
        filterFilialsWithAccess(tempFilials);
        setFilialsFullList(tempFilials);
        filteredFilials = tempFilials.filter(
          (filial) => filial.filial_cluster === selectedCluster
        );
        setFilials(filteredFilials);
        getFilialsReportData(filteredFilials, DataRange);
      });
    }
    // console.log("F1:", tempFilials);
    // console.log("F2:", filteredFilials);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCluster]);

  const getFilialsReportData = async (filialsList, DataRange) => {
    var hasMinAccess = checkMinAccess(filialsList, "Gerente Regional");
    var tempFilialId,
      tempCashRegister,
      tempCountCustomers,
      tempTotalRow,
      tempDelayLoans,
      tempSumDelayLoans;
    var rowData = [];
    var DataTable1 = [],
      DataTable2 = [],
      DataTable3 = [],
      DataTable4 = [],
      DataTable5 = [],
      DataTable6 = [],
      DataTable7 = [];
    var tempDataTable1 = [],
      tempDataTable2 = [],
      tempDataTable3 = [],
      tempDataTable4 = [],
      tempDataTable5 = [],
      tempDataTable6 = [],
      tempDataTable7 = [];
    var tempCashRegisterReport,
      tempCashRegisterLastReport,
      cashregistertotal,
      growth;
    var filialName,
      openloanstotal,
      countCustomers,
      interesttotal,
      arrearsPaidPercentage;
    var arrearstotal,
      capitaltotal,
      loanstotal,
      counternewloans,
      counterrenewals,
      counterexpenses;
    var expensestotal,
      counterpayroll,
      countercashflow,
      filialGoal,
      filialGoalPercentage;
    var countercancelled,
      delayedLoans,
      sumDelayLoans,
      filialRisikPercentage,
      paymenttotal;
    const currentDate = addHours(new Date(), +getTimeDifferenceInHours());

    for (var i = 0; i < filialsList.length; i++) {
      tempFilialId = filialsList[i].filial_id;
      tempCountCustomers = await actions.countActiveCustomers(tempFilialId);
      tempCashRegister = await actions.getCashRegister(tempFilialId);
      tempCashRegisterReport = await actions.getCurrentCashRegisterReport(
        tempFilialId
      );
      tempCashRegisterLastReport = await actions.getLastCashRegisterReport(
        tempFilialId
      );
      tempDelayLoans = await actions.countLoans(
        "OPEN",
        currentDate,
        tempFilialId
      );
      tempSumDelayLoans = await actions.sumLoansRemaining(
        currentDate,
        tempFilialId
      );

      filialName = filialsList[i].filial_name;
      openloanstotal = tempCashRegisterReport.openloanstotal;
      countCustomers = parseInt(tempCountCustomers);
      interesttotal = tempCashRegisterReport.interesttotal;
      arrearstotal = tempCashRegisterReport.arrearstotal;
      capitaltotal = tempCashRegisterReport.capitaltotal;
      paymenttotal = interesttotal + arrearstotal + capitaltotal;
      loanstotal = tempCashRegisterReport.loanstotal;
      counternewloans = tempCashRegisterReport.counternewloans;
      counterrenewals = tempCashRegisterReport.counterrenewals;
      counterexpenses = tempCashRegisterReport.counterexpenses;
      counterpayroll = tempCashRegisterReport.counterpayroll;
      countercashflow = tempCashRegisterReport.countercashflow;
      countercancelled = 0;
      cashregistertotal = tempCashRegisterReport.cashregistertotal;
      expensestotal = counterexpenses + counterpayroll;
      filialGoal = Math.round(tempCashRegisterLastReport.openloanstotal * 0.15);
      filialGoalPercentage =
        filialGoal > 0
          ? parseFloat(((interesttotal / filialGoal) * 100).toFixed(3))
          : 0;
      delayedLoans = parseInt(tempDelayLoans);
      sumDelayLoans = parseInt(tempSumDelayLoans);
      filialRisikPercentage =
        openloanstotal > 0
          ? parseFloat(((sumDelayLoans / openloanstotal) * 100).toFixed(3))
          : 0;
      arrearsPaidPercentage =
        filialGoal > 0
          ? parseFloat(((arrearstotal / filialGoal) * 100).toFixed(3))
          : 0;
      growth = loanstotal - capitaltotal;

      //////////     Table1      //////////
      tempDataTable1 = {
        filialName,
        openloanstotal,
        countCustomers,
      };

      Object.values(tempDataTable1).forEach((val) => {
        const row = { val };
        rowData.push(row);
      });

      DataTable1.push({ rowData });
      rowData = [];

      //////////     Table2       //////////
      tempDataTable2 = {
        filialName,
        filialGoal,
        interesttotal,
        filialGoalPercentage,
        arrearsPaidPercentage,
      };
      Object.values(tempDataTable2).forEach((val) => {
        const row = { val };
        rowData.push(row);
      });

      DataTable2.push({ rowData });
      rowData = [];

      //////////     Table3       //////////
      tempDataTable3 = {
        filialName,
        delayedLoans,
        sumDelayLoans,
        filialRisikPercentage,
      };
      Object.values(tempDataTable3).forEach((val) => {
        const row = { val };
        rowData.push(row);
      });

      DataTable3.push({ rowData });
      rowData = [];

      //////////     Table4       //////////
      tempDataTable4 = {
        filialName,
        counternewloans,
        counterrenewals,
        countercancelled,
      };
      Object.values(tempDataTable4).forEach((val) => {
        const row = { val };
        rowData.push(row);
      });

      DataTable4.push({ rowData });
      rowData = [];

      //////////     Table5       //////////
      tempDataTable5 = {
        filialName,
        paymenttotal,
        interesttotal,
        arrearstotal,
        capitaltotal,
        loanstotal,
        growth,
      };

      Object.values(tempDataTable5).forEach((val) => {
        const row = { val };
        rowData.push(row);
      });

      DataTable5.push({ rowData });
      rowData = [];

      //////////     Table6       //////////

      tempDataTable6 = {
        filialName,
        counterexpenses,
        counterpayroll,
        expensestotal,
      };

      Object.values(tempDataTable6).forEach((val) => {
        const row = { val };
        rowData.push(row);
      });

      DataTable6.push({ rowData });
      rowData = [];

      //////////     Table7       //////////
      tempDataTable7 = hasMinAccess
        ? {
            filialName,
            countercashflow,
            cashregistertotal,
          }
        : {
            filialName,
            cashregistertotal,
          };

      Object.values(tempDataTable7).forEach((val) => {
        const row = { val };
        rowData.push(row);
      });

      DataTable7.push({ rowData });
      rowData = [];
    }

    setReportData1(DataTable1);
    tempTotalRow = calculateReportDataTotals(DataTable1);
    setTotalRow1(tempTotalRow);

    setReportData2(DataTable2);
    tempTotalRow = calculateReportDataTotals(DataTable2);
    setTotalRow2(tempTotalRow);

    setReportData3(DataTable3);
    tempTotalRow = calculateReportDataTotals(DataTable3);
    setTotalRow3(tempTotalRow);

    setReportData4(DataTable4);
    tempTotalRow = calculateReportDataTotals(DataTable4);
    setTotalRow4(tempTotalRow);

    setReportData5(DataTable5);
    tempTotalRow = calculateReportDataTotals(DataTable5);
    setTotalRow5(tempTotalRow);

    setReportData6(DataTable6);
    tempTotalRow = calculateReportDataTotals(DataTable6);
    setTotalRow6(tempTotalRow);

    setReportData7(DataTable7);
    tempTotalRow = calculateReportDataTotals(DataTable7);
    setTotalRow7(tempTotalRow);

    setLoading(false);
  };

  return (
    <Grid
      container
      sx={{ padding: { xs: "10px", md: "25px" } }}
    >
      <Box sx={{ width: "100%" }}>
        <div
          className={`over-page-loading ${
            loading ? "show-loading" : "hide-loading"
          }`}
        >
          <div className="over-page-loading-content">
            <Loading
              text={"Cargando ..."}
              classNames={`over-page-loading ${
                loading ? "show-loading" : "hide-loading"
              }`}
            />
          </div>
        </div>

        {loading ? (
          <div>Cargando...</div>
        ) : (
          <Grid container>
            {!(hasMinRegionalManagerAccess || hasMinAuditorAccess) ? (
              <Grid size={12} pt="200px">
                <Typography
                  variant="h1"
                  component="h1"
                  display="flex"
                  justifyContent="center"
                  sx={{ textAlign: "center" }}
                >
                  {noAccessMessage()}
                </Typography>
              </Grid>
            ) : (
              <Grid container spacing={2}>
                <Grid size={{xs:1, md:7}}>
                  <Typography align="left" component="div">
                    <Box sx={{ m: 0.3 }}>
                      {`Corte de caja al día:  ${currentDateTime}`}
                    </Box>

                    <Box sx={{ m: 0.3 }}>
                      {`Intervalo del corte:  ${range.startDay} - ${range.endDay}`}
                    </Box>
                  </Typography>
                </Grid>

                <Grid size={{xs:12, md:5}}>
                  <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                      <InputLabel id="select-label">Grupo</InputLabel>
                      <Select
                        labelId="select-label"
                        id="select"
                        value={selectedCluster}
                        label="Grupo"
                        onChange={handleChange}
                      >
                        <MenuItem value="0">
                          <em>Todas</em>
                        </MenuItem>
                        {clusters.map((cluster, index) => {
                          return (
                            <MenuItem
                              key={cluster.id}
                              id={cluster.id}
                              value={cluster.id}
                            >
                              {cluster.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid size={12}>
                  <Grid container spacing={2}>
                    <Grid size={{xs:12, md:3}}>
                      <Item>
                        <ItemTitel>CARTERA TOTAL</ItemTitel>
                        <TableReport
                          hideColumn={false}
                          dataList={reportData1}
                          headersList={headersTable1}
                          contentPosition={contentPosition}
                          totalList={totalRow1}
                        />
                      </Item>
                    </Grid>
                    <Grid size={{xs:12, md:3}}>
                      <Item>
                        <ItemTitel>CUMPLIMIENTO DE META</ItemTitel>
                        <TableReport
                          hideColumn={true}
                          dataList={reportData2}
                          headersList={headersTable2}
                          contentPosition={contentPosition}
                          totalList={totalRow2}
                        />
                      </Item>
                    </Grid>
                    <Grid size={{xs:12, md:3}}>
                      <Item>
                        <ItemTitel>CARTERA DE RIESGO</ItemTitel>
                        <TableReport
                          hideColumn={true}
                          dataList={reportData3}
                          headersList={headersTable3}
                          contentPosition={contentPosition}
                          totalList={totalRow3}
                        />
                      </Item>
                    </Grid>
                    <Grid size={{xs:12, md:3}}>
                      <Item>
                        <ItemTitel>NUMERO DE CREDITOS</ItemTitel>
                        <TableReport
                          hideColumn={true}
                          dataList={reportData4}
                          headersList={headersTable4}
                          contentPosition={contentPosition}
                          totalList={totalRow4}
                        />
                      </Item>
                    </Grid>
                    <Grid size={{xs:12, md:7}}>
                      <Item>
                        <ItemTitel>PAGOS INGRESADOS</ItemTitel>
                        <TableReport
                          hideColumn={false}
                          dataList={reportData5}
                          headersList={headersTable5}
                          contentPosition={contentPosition}
                          totalList={totalRow5}
                        />
                      </Item>
                    </Grid>
                    <Grid size={{xs:12, md:3}}>
                      <Item>
                        <ItemTitel>GASTOS</ItemTitel>
                        <TableReport
                          hideColumn={true}
                          dataList={reportData6}
                          headersList={headersTable6}
                          contentPosition={contentPosition}
                          totalList={totalRow6}
                        />
                      </Item>
                    </Grid>
                    <Grid size={{xs:12, md:2}}>
                      <Item>
                        <ItemTitel>CAJA</ItemTitel>
                        <TableReport
                          hideColumn={true}
                          dataList={reportData7}
                          headersList={headersTable7}
                          contentPosition={contentPosition}
                          totalList={totalRow7}
                        />
                      </Item>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        )}
      </Box>
    </Grid>
  );
};
export default Index;
