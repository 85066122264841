import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";

// Material UI import
import { styled, createTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import MuiTabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import ClearIcon from "@mui/icons-material/Clear";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";

// Internal import
import { NovaContext } from "../../components/context";
import {
  checkAccess,
  getTimeDifferenceInHours,
} from "../../components/Helper/GlobalHelpers";
import { GenderItems } from "../../components/Helper/Enums";
import { CustomerStatus } from "../../components/Helper/Enums";
import ValidateCustomer from "../../components/validation/validateCustomer";
import { alerts, displayAlert } from "../../components/Helper/AlertToastify";

// Material UI Date Picker
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

// date-fns import
import format from "date-fns/format";
import formatISO from "date-fns/formatISO";
import parseISO from "date-fns/parseISO";
import differenceInYears from "date-fns/differenceInYears";
import addHours from "date-fns/addHours";
import { CustomSwitch } from "../../components/Helper/MUIStyledComponents";

const theme = createTheme({
  components: {
    fullTextField: {
      display: "flex",
      width: "100%",
      margin: "10px 0px",
    },
    roundButton: {
      borderRadius: "50%",
      padding: "5px 5px",
      minWidth: "auto",
    },
  },
});

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          {/* Removed 30.10 because of error that textfield can't be descended of p */}
          {/* <Typography>{children}</Typography> */}
          <>{children}</>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ItemBox = styled(Paper)(({ theme }) => ({
  // margin: theme.spacing(1),
  padding: theme.spacing(0.5),
  textAlign: "center",
  height: "100%",
  backgroundColor: "#fff",
  boxShadow: "none",
  borderRadius: 20,
}));

const CustomerInfoTabs = (props) => {
  const {
    customer,
    updateCustomerState,
    determineNewStatus,
    alterCustomerPasswordStatus,
    updateCreditLimitBlock,
    updateCustomer,
  } = props;

  const { actions, filial, currentRole } = useContext(NovaContext);

  // component-based state/functions
  const [tabValue, setTabValue] = useState(0);
  const [editCustomerMode, setEditCustomerMode] = useState(false);
  const [editLock, setEditLock] = useState(false);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // errors resulting from edit mode
  const [errors, setErrors] = useState([]);

  const checkForErrors = (fieldId) => {
    const nameError = errors.find((error) => error.label === fieldId);
    return nameError !== undefined ? nameError.message : "";
  };

  // customer-related state
  const [customerComponent, setCustomerComponent] = useState(customer);

  // role-based state
  const hasManagerAccess = checkAccess(currentRole, "Gerente");
  const hasAuditorRegionalManagerAccess = checkAccess(
    currentRole,
    "Auditor o Gerente Regional"
  );

  // State/Methods relating to customer's comments
  const [commentMode, setCommentMode] = useState(false);
  const [commentText, setCommentText] = useState(customer.customer_comment);

  const changeCustomerComments = async () => {
    if (commentMode) {
      await actions.updateCustomerComment(commentText, customer.customer_id);
      setCommentMode(false);
    }
  };

  // State/Methods relating to general data
  const validateCustomer = new ValidateCustomer();
  const [name, setName] = useState(customer.customer_name);
  const [lastName, setLastName] = useState(customer.customer_lastname);
  const [telephone, setTelephone] = useState(customer.customer_telephone);
  const [gender, setGender] = useState(customer.customer_sex);
  const [location, setLocation] = useState(customer.customer_location);
  const [address, setAddress] = useState(customer.customer_address);
  const [dob, setDob] = useState(customer.dob ? parseISO(customer.dob) : "");
  const [meterNumber, setMeterNumber] = useState(customer.customer_meternumber);
  const [customerAge, setCustomerAge] = useState(
    customer.dob ? differenceInYears(new Date(), parseISO(customer.dob)) : 0
  );

  const revertGeneralDataChanges = () => {
    setName(customer.customer_name);
    setLastName(customer.customer_lastname);
    setTelephone(customer.customer_telephone);
    setGender(customer.customer_sex);
    setLocation(customer.customer_location);
    setAddress(customer.customer_address);
    setDob(customer.dob ? parseISO(customer.dob) : "");
    setMeterNumber(customer.customer_meternumber);
    setEditCustomerMode(false);
    setErrors([]);
    if (!dob) {
      setCustomerAge(0);
    }
  };

  const updateCustomerDob = (e) => {
    setDob(e);
    setCustomerAge(differenceInYears(new Date(), e));
  };

  const validateData = () => {
    setErrors([]);
    let isValid = true;

    const firstnamevalidateMsg = validateCustomer.validateName(name);
    if (firstnamevalidateMsg !== "") {
      setErrors((arr) => [
        ...arr,
        { label: "name", message: firstnamevalidateMsg },
      ]);
      isValid = false;
    }

    const lastnamevalidateMsg = validateCustomer.validateName(lastName);
    if (lastnamevalidateMsg !== "") {
      setErrors((arr) => [
        ...arr,
        { label: "lastname", message: lastnamevalidateMsg },
      ]);
      isValid = false;
    }

    // if the flag for gender is not empty then a class is being set on the drop down
    if (!gender) {
      const gendervalidateMsg = "El Genero es requerido";
      setErrors((arr) => [
        ...arr,
        { label: "gender", message: gendervalidateMsg },
      ]);
      isValid = false;
    }

    if (!validateCustomer.validateMeter(meterNumber)) {
      const metervalidateMsg = "El # Medidor es requerido";
      setErrors((arr) => [
        ...arr,
        { label: "meternumber", message: metervalidateMsg },
      ]);
      isValid = false;
    }

    if (!validateCustomer.validateAddress(address)) {
      const addressvalidateMsg = "La dirección es requerida";
      setErrors((arr) => [
        ...arr,
        { label: "address", message: addressvalidateMsg },
      ]);
      isValid = false;
    }

    return isValid;
  };

  const submitCustomerChanges = async (e) => {
    e.preventDefault();
    setEditLock(true);

    let isValid = validateData();
    if (isValid) {
      const currentDate = addHours(new Date(), +getTimeDifferenceInHours());

      const tempCustomer = {
        name: name,
        lastname: lastName,
        location: location,
        telephone: telephone,
        filialId: filial,
        gender: gender,
        address: address,
        meterNumber: meterNumber,
        comment: commentText,
        createdDate: formatISO(currentDate),
        dob: dob ? formatISO(new Date(dob)) : "NULL",
        // keeping this line - currently not sure what I did or why here :(
        // dob: Object.keys(dob).length > 0 ? formatISO(new Date(dob)) : "NULL",
      };

      await actions
        .updateCustomer(tempCustomer, customer.customer_id)
        .then((resp) => {
          updateCustomer(resp);
          displayAlert(alerts.SUCCESS, "Cliente actualizado con éxito");
        })
        .catch((err) => {
          console.log("Error occurred: ", err);
          displayAlert(alerts.ERROR, "Algo ha fallado en la solicitud");
        });

      setEditCustomerMode(false);
      setErrors([]);
    }
    setEditLock(false);
  };

  // State/Methods relating to customer's configuration
  const [creditLimit, setCreditLimit] = useState(customerComponent.currentmaxloanvalue);
  const [editCreditLimit, setEditCreditLimit] = useState(false);
  const [creditLimitBlock, setCreditLimitBlock] = useState(false);

  const setNewCreditLimit = async (newCreditLimit, updatedCounterValue) => {
    if (parseInt(newCreditLimit) !== customerComponent.currentmaxloanvalue) {
      await actions
        .setCustomCreditLimit(
          parseInt(newCreditLimit),
          customerComponent.currentmaxloanvalue,
          updatedCounterValue,
          customerComponent.customer_id
        )
        .then((resp) => {
          if (resp.status === 201) {
            setCustomerComponent({
              ...customerComponent,
              currentmaxloanvalue: newCreditLimit,
              updatedcountervalue: updatedCounterValue,
            });
            setEditCreditLimit(false);
            displayAlert(alerts.SUCCESS, "Límite de crédito actualizado");
          } else {
            displayAlert(alerts.ERROR, "Algo ha fallado en la solicitud");
          }
        });
    } else {
      alert(
        "El nuevo valor máximo del préstamo debe ser diferente del valor actual"
      );
    }
  };

  const changeCustomerStatus = async (status) => {
    if (status) {
      await actions
        .updateCustomerStatus(status, customer.customer_id)
        .then((response) => {
          if (response.status === 200) {
            updateCustomerState(status);
            displayAlert(alerts.SUCCESS, "Estado del cliente actualizado");
            setCustomerComponent((prevState) => {
              return { ...prevState, status };
            });
          }
        })
        .catch((err) => {
          console.log("Error occurred: ", err);
          displayAlert(alerts.ERROR, "Algo ha fallado en la solicitud");
        });
    }
  };

  // block limit for credit limit
  const changeCreditLimitBlock = async (status) => {
    if (!editLock) {
      setEditLock(true);
      await actions
        .setLoanRaiseBlock(customer.customer_id, status)
        .then((resp) => {
          if (resp && resp.status === 201) {
            setCustomerComponent((prevState) => {
              return { ...prevState, raiseblocked: status };
            });
            displayAlert(alerts.SUCCESS, "Estado del cliente actualizado");
            setCreditLimitBlock(status);
            updateCreditLimitBlock(status);
          } else {
            displayAlert(alerts.ERROR, "Algo ha fallado en la solicitud");
            console.log("Algo ha fallado en la solicitud: ", resp.status);
          }
        });
    } else {
      displayAlert(alerts.ERROR, "Algo ha fallado en la solicitud");
    }
    setEditLock(false);
  };

  // customer password
  const [passwordChange, setpasswordChange] = useState(false);
  const [password, setPassword] = useState("");

  const revertPasswordChanges = () => {
    setPassword("");
    setpasswordChange(false);
  };

  const changeCustomerPasswordStatus = async (status) => {
    await actions
      .updateCustomerPasswordStatus(status, customerComponent.customer_id)
      .then((resp) => {
        if (resp.status === 200) {
          setCustomerComponent((prevState) => {
            return { ...prevState, passworddisabled: status };
          });
          alterCustomerPasswordStatus(status);
        }
      })
      .catch((error) => {
        displayAlert(alerts.ERROR, "Algo ha fallado en la solicitud");
        console.log("Error occurred: ", error);
      });
  };

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    if (!editLock) {
      setEditLock(true);
      setErrors([]);
      if (password.length > 0) {
        await actions
          .updateCustomerPassword(password, customer.customer_id)
          .then((resp) => {
            if (resp.status === 200) {
              // placeholder for confirmation page
              changeCustomerPasswordStatus(!customer.passworddisabled);
              revertPasswordChanges();
              displayAlert(alerts.SUCCESS, "Cambio de contraseña del cliente");
            } else {
              errors.push(resp);
            }
          });
      } else {
        errors.push({
          label: "password",
          message: "La contraseña no es lo suficientemente larga",
        });
      }
    }
    setEditLock(false);
  };

  return (
    <ItemBox>
      <Grid container>
        <Grid size={12}>
          <Box>
            <MuiTabs
              value={tabValue}
              onChange={handleTabChange}
              aria-label="customer-info-tabs"
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
            // sx={{ padding: '16px' }}
            >
              <Tab label="Comentarios" {...a11yProps(0)} />
              <Tab label="Datos generales" {...a11yProps(1)} />
              <Tab label="Configuración" {...a11yProps(2)} />
            </MuiTabs>
          </Box>
          {/* Commentarios */}
          <CustomTabPanel value={tabValue} index={0}>
            <Box position={"relative"}>
              <TextField
                id="customer-comments"
                multiline
                minRows={10}
                maxRows={10}
                sx={{
                  width: "100%",
                }}
                value={commentText}
                onChange={(e) => setCommentText(e.target.value)}
                disabled={!commentMode}
                inputRef={(input) => commentMode && input && input.focus()}
              />
              <Box
                component={"div"}
                sx={{ position: "absolute", bottom: 5, right: 5 }}
              >
                {commentMode ? (
                  <>
                    <Tooltip title={"Guardar"} placement="top" arrow={true}>
                      <Button
                        variant="contained"
                        color="success"
                        sx={{
                          borderRadius: "50%",
                          padding: "5px 5px",
                          minWidth: "auto",
                        }}
                        onClick={changeCustomerComments}
                      >
                        <SaveIcon />
                      </Button>
                    </Tooltip>

                    <Tooltip title={"Cancelar"} placement="top" arrow={true}>
                      <Button
                        variant="contained"
                        color="secondary"
                        sx={{
                          borderRadius: "50%",
                          padding: "5px 5px",
                          minWidth: "auto",
                          marginLeft: "10px",
                        }}
                        onClick={() => {
                          setCommentMode(false);
                          setCommentText(customer.customer_comment);
                        }}
                      >
                        <ClearIcon />
                      </Button>
                    </Tooltip>
                  </>
                ) : (
                  <Tooltip title={"Editar"} placement="top" arrow={true}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setCommentMode(true)}
                      sx={{
                        borderRadius: "50%",
                        padding: "5px 5px",
                        minWidth: "auto",
                      }}
                    >
                      <EditIcon />
                    </Button>
                  </Tooltip>
                )}
              </Box>
            </Box>
          </CustomTabPanel>

          {/* General data - Datos generales */}
          <CustomTabPanel value={tabValue} index={1}>
            <Grid container padding={1} spacing={2} position="relative">
              <Grid size={{ xs: 12, md: 6 }}>
                <TextField
                  label="Nombre"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  fullWidth
                  size="small"
                  helperText={checkForErrors("name")}
                  error={Boolean(checkForErrors("name"))}
                  variant={
                    editCustomerMode
                      ? hasAuditorRegionalManagerAccess
                        ? "outlined"
                        : "standard"
                      : "standard"
                  }
                  slotProps={{
                    input: {
                      readOnly: editCustomerMode
                        ? !hasAuditorRegionalManagerAccess // Read-only if editing and no access
                        : true, // Always read-only when not editing
                    },
                  }}
                />
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <TextField
                  label="Apellido"
                  name="lastname"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  fullWidth
                  size="small"
                  helperText={checkForErrors("lastname")}
                  error={checkForErrors("lastname") ? true : false}
                  variant={
                    editCustomerMode
                      ? hasAuditorRegionalManagerAccess
                        ? "outlined"
                        : "standard"
                      : "standard"
                  }
                  slotProps={{
                    input: {
                      readOnly: editCustomerMode
                        ? !hasAuditorRegionalManagerAccess // Read-only if editing and no access
                        : true, // Always read-only when not editing
                    },
                  }}
                />
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                {" "}
                <TextField
                  label="Teléfono"
                  name="telefone"
                  value={telephone}
                  onChange={(e) => setTelephone(e.target.value)}
                  fullWidth
                  size="small"
                  helperText={checkForErrors("telephone")}
                  error={checkForErrors("telephone") ? true : false}
                  variant={
                    editCustomerMode
                      ? hasManagerAccess
                        ? "outlined"
                        : "standard"
                      : "standard"
                  }
                  slotProps={{
                    input: {
                      readOnly: editCustomerMode
                        ? !hasManagerAccess // Read-only if editing and no access
                        : true, // Always read-only when not editing
                    },
                  }}
                />
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <TextField
                  id="sex"
                  label="Género"
                  sx={{ textAlign: "left" }}
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}
                  size="small"
                  select
                  fullWidth
                  helperText={checkForErrors("gender")}
                  error={checkForErrors("gender") ? true : false}
                  variant={
                    editCustomerMode
                      ? hasAuditorRegionalManagerAccess
                        ? "outlined"
                        : "standard"
                      : "standard"
                  }
                  slotProps={{
                    input: {
                      readOnly: editCustomerMode
                        ? !hasAuditorRegionalManagerAccess // Read-only if editing and no access
                        : true, // Always read-only when not editing
                    },
                  }}
                >
                  <MenuItem name="customer_sex" value="">
                    -- Seleccionar --
                  </MenuItem>
                  {GenderItems &&
                    GenderItems.map((item) => {
                      return (
                        <MenuItem
                          key={item.id}
                          name="customer_sex"
                          value={item.id}
                        >
                          {item.title}
                        </MenuItem>
                      );
                    })}
                </TextField>
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label={`Nacimiento ${customerAge !== 0 ? `(${customerAge} años)` : ""
                      }`}
                    disableFuture
                    id="dob"
                    onChange={(e) => updateCustomerDob(e)}
                    value={dob}
                    defaultValue={null}
                    format="dd/MM/yyyy"
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        helperText: checkForErrors("dob"),
                        error: Boolean(checkForErrors("dob")),
                        size: "small",
                        variant: editCustomerMode
                          ? hasAuditorRegionalManagerAccess
                            ? "outlined"
                            : "standard"
                          : "standard",
                      },
                    }}
                    readOnly={
                      editCustomerMode
                        ? !hasAuditorRegionalManagerAccess // Read-only if editing and no access
                        : true // Always read-only when not editing
                    }
                  />
                </LocalizationProvider>
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <TextField
                  label="Localidad"
                  name="locality"
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                  fullWidth
                  size="small"
                  helperText={checkForErrors("locality")}
                  error={checkForErrors("locality") ? true : false}
                  variant={
                    editCustomerMode
                      ? hasAuditorRegionalManagerAccess
                        ? "outlined"
                        : "standard"
                      : "standard"
                  }
                  slotProps={{
                    input: {
                      readOnly: editCustomerMode
                        ? !hasAuditorRegionalManagerAccess // Read-only if editing and no access
                        : true, // Always read-only when not editing
                    },
                  }}
                />
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <TextField
                  label="#Medidor"
                  name="meter-number"
                  value={meterNumber}
                  onChange={(e) => setMeterNumber(e.target.value)}
                  fullWidth
                  size="small"
                  helperText={checkForErrors("meternumber")}
                  error={checkForErrors("meternumber") ? true : false}
                  variant={
                    editCustomerMode
                      ? hasAuditorRegionalManagerAccess
                        ? "outlined"
                        : "standard"
                      : "standard"
                  }
                  slotProps={{
                    input: {
                      readOnly: editCustomerMode
                        ? !hasAuditorRegionalManagerAccess // Read-only if editing and no access
                        : true, // Always read-only when not editing
                    },
                  }}
                />
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <TextField
                  label="Dirección"
                  name="address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  fullWidth
                  size="small"
                  helperText={checkForErrors("address")}
                  variant={
                    editCustomerMode
                      ? hasAuditorRegionalManagerAccess
                        ? "outlined"
                        : "standard"
                      : "standard"
                  }
                  slotProps={{
                    input: {
                      readOnly: editCustomerMode
                        ? !hasAuditorRegionalManagerAccess // Read-only if editing and no access
                        : true, // Always read-only when not editing
                    },
                  }}
                />
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <TextField
                  label="Fecha de creación"
                  name="creation-date"
                  value={format(
                    parseISO(customer.customer_createddate),
                    "dd/MM/yyyy"
                  )}
                  // format="DD/MM/YYYY"
                  fullWidth
                  variant={"standard"}
                  slotProps={{
                    input: {
                      readOnly: true,
                    },
                  }}
                  size="small"
                />
              </Grid>
              {hasManagerAccess || hasAuditorRegionalManagerAccess ? (
                <Box position="absolute" right={0} bottom={-5}>
                  {editCustomerMode ? (
                    <Box display="flex">
                      <Tooltip title="Guardar" placement="top" arrow={true}>
                        <Button
                          variant="contained"
                          color="success"
                          sx={theme.components.roundButton}
                          onClick={(e) => submitCustomerChanges(e)}
                          disabled={editLock}
                        >
                          <SaveIcon />
                        </Button>
                      </Tooltip>
                      <Tooltip title="Cancelar" placement="top" arrow={true}>
                        <Button
                          variant="contained"
                          color="secondary"
                          sx={{
                            ...theme.components.roundButton,
                            marginLeft: "5px",
                          }}
                          onClick={() => revertGeneralDataChanges()}
                          disabled={editLock}
                        >
                          <ClearIcon />
                        </Button>
                      </Tooltip>
                    </Box>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      sx={theme.components.roundButton}
                      onClick={() => setEditCustomerMode(true)}
                      disabled={editLock}
                    >
                      <EditIcon />
                    </Button>
                  )}
                </Box>
              ) : null}
            </Grid>
          </CustomTabPanel>

          {/* Configuration */}
          <CustomTabPanel value={tabValue} index={2}>
            <Grid
              container
              spacing={{ xs: 0.5, md: 2 }}
              sx={{ p: { xs: 1, md: 2 } }}
            >
              {/* limite de crétito - creditmit */}
              <Grid size={12}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {/* <TextField
                    type="number"
                    name="credit-limit"
                    label="Limite de crédito"
                    value={creditLimit}
                    onChange={(e) => setCreditLimit(e.target.value)}
                    variant={"standard"}
                    slotProps={{
                      input: {
                        readOnly: true,
                      },
                    }}
                    sx={{ width: "70%" }}
                    inputRef={(input) =>
                      editCreditLimit && input && input.focus()
                    }
                    slots={{
                      min: 0,
                      step: 100,
                      readOnly: !editCreditLimit,
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                      endAdornment:
                        hasAuditorRegionalManagerAccess ? (
                          editCreditLimit ? (
                            <Box display="flex">
                              <Button
                                variant="contained"
                                color="success"
                                sx={theme.components.roundButton}
                                onClick={() =>
                                  setNewCreditLimit(creditLimit, 0)
                                }
                              >
                                <SaveIcon />
                              </Button>
                              <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => {
                                  setCreditLimit(
                                    customerComponent.currentmaxloanvalue
                                  );
                                  setEditCreditLimit(false);
                                }}
                                sx={{
                                  ...theme.components.roundButton,
                                  marginLeft: "5px",
                                }}
                              >
                                <ClearIcon />
                              </Button>
                            </Box>
                          ) : (
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => setEditCreditLimit(true)}
                              sx={theme.components.roundButton}
                            >
                              <EditIcon />
                            </Button>
                          )
                        ) : null,
                    }}
                  /> */}
                  <TextField
                    type="number"
                    name="credit-limit"
                    label="Límite de crédito"
                    value={creditLimit}
                    autoComplete="off" // Prevents browser autofill
                    onChange={(e) => setCreditLimit(e.target.value)}
                    sx={{ width: "70%" }}
                    inputRef={(input) =>
                      editCreditLimit && input && input.focus()
                    } // Autofocus when editing
                    variant={editCreditLimit ? "outlined" : "standard"}
                    slotProps={{
                      input: {
                        readOnly: editCreditLimit
                          ? !hasAuditorRegionalManagerAccess // Read-only if editing and no access
                          : true, // Always read-only when not editing
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                        endAdornment: hasAuditorRegionalManagerAccess ? (
                          editCreditLimit ? (
                            <Box display="flex">
                              <Button
                                variant="contained"
                                color="success"
                                sx={theme.components.roundButton}
                                onClick={() =>
                                  setNewCreditLimit(creditLimit, 0)
                                }
                              >
                                <SaveIcon />
                              </Button>
                              <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => {
                                  setCreditLimit(
                                    customerComponent.currentmaxloanvalue
                                  );
                                  setEditCreditLimit(false);
                                }}
                                sx={{
                                  ...theme.components.roundButton,
                                  marginLeft: "5px",
                                }}
                              >
                                <ClearIcon />
                              </Button>
                            </Box>
                          ) : (
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => setEditCreditLimit(true)}
                              sx={theme.components.roundButton}
                            >
                              <EditIcon />
                            </Button>
                          )
                        ) : null,
                      },
                      htmlInput: {
                        step: 100, // Step logic enforced
                        min: 0, // Minimum value
                      },
                    }}
                    helperText={checkForErrors("creditLimit")}
                    error={Boolean(checkForErrors("creditLimit"))}
                  />
                </Box>
              </Grid>
              {/* Customer blocking and enabled customer */}
              {/* status DISABLED takes precedence over BLOCKED. If enabled then customer
              set to INACTIVE. Then this changes to the option to BLOCK the customer */}
              <Grid size={12}>
                {
                (hasManagerAccess && customer.status !== "BLOCKED" && customer.status !== "DISABLED") ||
                  hasAuditorRegionalManagerAccess ? (
                  <FormControlLabel
                    sx={{ fontSize: "large" }}
                    control={
                      <CustomSwitch
                        checked={
                          customer.status === "DISABLED" ? true : 
                          customerComponent.status === CustomerStatus[2].value
                        }
                        onChange={() =>
                          changeCustomerStatus(
                            determineNewStatus(customerComponent.status)
                          )
                        }
                      />
                    }
                    label={
                      <Typography variant="h5">
                        {
                          customer.status === 'DISABLED' ?
                            "Inhabilitado"
                            :
                            "Bloqueado"
                        }
                      </Typography>
                    }
                    labelPlacement="start"
                  />
                ) : null}
              </Grid>
              {/* creditLimit blocking */}
              <Grid size={12}>
                {hasAuditorRegionalManagerAccess ? (
                  <FormControlLabel
                    control={
                      <CustomSwitch
                        checked={!creditLimitBlock}
                        onChange={() =>
                          changeCreditLimitBlock(!creditLimitBlock)
                        }
                      />
                    }
                    label={<Typography variant="h5">Aumentos</Typography>}
                    labelPlacement="start"
                  />
                ) : null}
              </Grid>
              {/* Deactivate password*/}
              <Grid size={12}>
                {hasAuditorRegionalManagerAccess ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <FormControlLabel
                      control={
                        <CustomSwitch
                          checked={!customerComponent.passworddisabled}
                          onClick={() =>
                            changeCustomerPasswordStatus(
                              !customerComponent.passworddisabled
                            )
                          }
                        />
                      }
                      label={<Typography variant="h5">Contraseña</Typography>}
                      labelPlacement="start"
                    />
                  </Box>
                ) : null}
                {customer.passworddisabled ? (
                  <TextField
                    name="password"
                    id="password"
                    label="Contraseña"
                    type="password"
                    value={password}
                    autoComplete="off" // Prevents browser autofill
                    onChange={(e) => setPassword(e.target.value)}
                    inputRef={(input) =>
                      passwordChange && input && input.focus()
                    }
                    sx={{ width: "70%" }}
                    slotProps={{
                      input: {
                        // readOnly: openTotalDebit <= 0, // Dynamically enable/disable edit
                        readOnly: !passwordChange,

                        endAdornment: passwordChange ? (
                          <Box display="flex">
                            <Button
                              variant="contained"
                              color="success"
                              sx={theme.components.roundButton}
                              onClick={(e) => handlePasswordChange(e)}
                            >
                              <SaveIcon />
                            </Button>
                            <Button
                              variant="contained"
                              color="secondary"
                              sx={{
                                ...theme.components.roundButton,
                                marginLeft: "5px",
                              }}
                              onClick={() => revertPasswordChanges()}
                            >
                              <ClearIcon />
                            </Button>
                          </Box>
                        ) : (
                          <Button
                            variant="contained"
                            color="primary"
                            sx={theme.components.roundButton}
                            onClick={() => setpasswordChange(true)}
                          >
                            <EditIcon />
                          </Button>
                        ),
                      },
                      htmlInput: {
                        step: 100, // Step logic enforced
                        min: 0, // Minimum value
                      },
                      inputLabel: { shrink: true },
                    }}
                    helperText={checkForErrors("password")}
                    error={checkForErrors("password") ? true : false}
                    disabled={editLock}
                  />
                ) : null}
              </Grid>
            </Grid>
          </CustomTabPanel>
        </Grid>
      </Grid>
    </ItemBox>
  );
};

export default CustomerInfoTabs;
