import React, { useState, useContext } from "react";
import { NovaContext } from "../../components/context/index";

// React router dom
import { Link } from "react-router-dom";

// Internal import
import { checkAccess } from "../../components/Helper/GlobalHelpers";
import {
  prepareAuditColumns,
  getHideableColumns,
  getVisibilityColumns,
} from "../../components/Helper/DataGrid/ColumnDefinitions/Columns_Audit";
import LoanList from "../LoanOverview/LoanList";
import MediaGallery from "../../components/MediaGallery";
import CustomerDocuments from "./CustomerDocuments";

// mui
import Box from "@mui/system/Box";
import Tabs from "@mui/material/Tabs";
import Grid from "@mui/material/Grid2";
import Tab from "@mui/material/Tab";
import {
  CustomDataGrid,
  CustomGridToolbar,
} from "../../components/Helper/MUIStyledComponents";
import Typography from "@mui/material/Typography";
import LoansListWrapper from "../../components/Wrappers/LoansListWrapper";
import { visibilityModelLoanCustomerProfile } from "../../components/Helper/DataGrid/ColumnDefinitions/Columns_Loans";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      sx={{
        width: "100%",
      }}
    >
      {value === index && <Box>{children}</Box>}
    </Box>
  );
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CustomerContent = (props) => {
  const {
    customer,
    customerLoans,
    loansLoaded,
    viewLoanDetails,
    togglePaymentDialog,
    customerAudit,
    auditLoaded,
    customerMedia,
    mediaLoaded,
    updateMedia,
  } = props;

  const [filterModel, setFilterModel] = React.useState({
    items: [],
    quickFilterExcludeHiddenColumns: true,
    quickFilterValues: [""],
  });

  const [selectedTab, setSelectedTab] = useState(0);
  const boxHeight = "70vh";

  const { currentRole } = useContext(NovaContext);
  const hasRegionalManagerAccess = checkAccess(currentRole, "Gerente Regional");

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Box margin={1} paddingTop={{ xs: 5, md: 0 }}>
      <Box>
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          indicatorColor="primary"
        >
          <Tab label="Créditos" {...a11yProps(0)} />
          <Tab label="Imágenes" {...a11yProps(1)} />
          <Tab label="Auditoría" {...a11yProps(2)} />
          <Tab label="Documentos" {...a11yProps(3)} />
        </Tabs>
      </Box>

      <TabPanel value={selectedTab} label="Créditos" index={0}>
        <Grid container>
          <Grid size={12} height={boxHeight} width="100%">
            {/* <LoanList
              loans={customerLoans}
              viewDetails={viewLoanDetails}
              toggleDialog={togglePaymentDialog}
              hideableColumns={getHideableColumns(currentRole)}
              visibilityColumns={getVisibilityColumns()}
            /> */}
            <LoansListWrapper
              columnVisibilityModel={visibilityModelLoanCustomerProfile}
              statusFilter={"none"}
              sortingFilter={"lo.loan_dateissue DESC, lo.loan_datedue DESC"}
              boxHeight={boxHeight}
              customerId={customer.customer_id}
              startDateFilter={true}
              endDateFilter={true}
              hideDateRange={true}
              
            />
          </Grid>
        </Grid>
      </TabPanel>

      <TabPanel value={selectedTab} label="Imágenes" index={1}>
        <Box height={boxHeight} width="100%">
          {customerMedia ? (
            <MediaGallery
              mediaItems={customerMedia?.filter(
                (media) => media.media_type === "IMAGE"
              )}
              width={"100%"}
              height={boxHeight}
              columnNumber={5}
              rowHeight={150}
              loadingType={"lazy"}
              updateMedia={updateMedia}
            />
          ) : (
            <Typography>
              No se ha cargado ningún medio. Haga clic aquí para añadir uno.
            </Typography>
          )}
        </Box>
      </TabPanel>

      <TabPanel value={selectedTab} label="Auditoría" index={2}>
        <Box height={boxHeight} width="100%">
          <CustomDataGrid
            columns={prepareAuditColumns(customerAudit)}
            rows={customerAudit}
            getRowId={(row) => row.id}
            slots={{
              toolbar: hasRegionalManagerAccess ? CustomGridToolbar : null,
            }}
            onFilterModelChange={(newModel) => setFilterModel(newModel)}
            filterModel={filterModel}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  // columnXTohide: hasAdminAccess ? true : false,
                },
              },
              filter: {
                filterModel: {
                  items: [],
                  quickFilterExcludeHiddenColumns: true,
                },
              },
            }}
          />
        </Box>
      </TabPanel>

      <TabPanel value={selectedTab} label="Documentos" index={3}>
        <Box height={boxHeight} width="100%">
          <CustomerDocuments
            documentList={customerMedia?.filter(
              (media) => media.media_type === "DOCUMENT"
            )}
            currentRole={currentRole}
            updateMedia={updateMedia}
          />
        </Box>
      </TabPanel>
    </Box>
  );
};

export default CustomerContent;
