// internal
import {
  checkAccess,
  generalSortComparatorNumbers,
  getLoanStatusIcon,
} from "../../GlobalHelpers";
import {
  calculateDelayedWeeks,
  calculateOpenAmountTotal,
  calculateOpenArrear,
  calculateOpenInterest,
  calculateRisk,
  formatRiskIcon,
} from "../../LoanHelpers";
import AmountDisplay from "../../../common/AmountDisplay";
import { WeekDayDueToPayment } from "../../Enums";
import ModeComment from "../../../common/ModeComment";

// misc
import { Link } from "react-router-dom";

//date-fns
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";

// mui
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import RequestQuoteOutlinedIcon from "@mui/icons-material/RequestQuoteOutlined";
import InfoIcon from "@mui/icons-material/Info";
import DescriptionIcon from "@mui/icons-material/Description";
import Box from "@mui/material/Box";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
// import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
// import DoDisturbOffTwoTone from "@mui/icons-material/DeleteForeverRoundedOffTwoTone";
import CleaningServicesRoundedIcon from "@mui/icons-material/CleaningServicesRounded";
import MoveDownRoundedIcon from "@mui/icons-material/MoveDownRounded";
import Button from "@mui/material/Button";
import { differenceInDays } from "date-fns";
import TooltipIconButton from "../../../controls/TooltipIconButton";

const RiskIcon = (dataArray, loanId) => {
  const risk = loanId ? getRiskValue(dataArray, loanId) : 0;
  return formatRiskIcon(risk);
};

// *AC- something is wrong with this 08.10.2024
const getRiskValue = (dataArray, loanId) => {
  let risk = 0;
  if (dataArray.length > 0) {
    risk = calculateRisk(
      dataArray.find((processedLoans) => processedLoans.loan_id === loanId)
    );
  }
  return risk;
};

export const prepareLoanColumns = (
  loansList,
  setConfirmationRemoveLoan,
  setConfirmationWriteOff,
  setSelectedLoan,
  setOpenPostponeDialog,
  handleNavigation,
  handleClickDrawerLoanDetails,
  handleClickPayment,
  currentRole
  // visibilityModel
) => {
  // console.log("loans:", loansList);

  const hasRegionalManagerAccess = checkAccess(currentRole, "Gerente Regional");
  const hasAuditorAccess = checkAccess(currentRole, "Auditor");
  const hasAdminAccess = checkAccess(currentRole, "Administrador");

  const loanColumns = [
    {
      field: "accionPago",
      headerName: "",
      align: "center",
      width: 10,
      sortable: false,
      hideable: false,
      disableExport: true,
      renderCell: (params) => {
        const viewPaymentDialog = (e) => {
          e.stopPropagation();
          handleClickPayment(params?.row);
        };
        return (
          <Box display="flex" alignItems={"center"}>
            <Tooltip title="Agregar pago" placement="right" arrow>
              <IconButton
                color="primary"
                variant="contained"
                size="small"
                data-target={`#id${params?.row.loan_id}`}
                onClick={viewPaymentDialog}
              >
                {""}
                <RequestQuoteOutlinedIcon
                  variant="contained"
                  fontSize="large"
                />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
    },
    {
      field: "customer_idnumber",
      headerName: "ID",
      renderHeader: () => <strong>ID</strong>,
      align: "right",
      type: "number",
      width: 60,
      hideable: false,
      headerClassName: "GridHeader",
    },
    {
      field: "customer_fullname",
      headerName: "Cliente",
      headerClassName: "GridHeader",
      type: "string",
      flex: 1,
      minWidth: 180,
      renderCell: (params) => {
        return (
          <Link to={`/Customers/${params.row.loan_customerid}`}>
            {params.row.customer_fullname}
          </Link>
        );
      },
    },
    {
      field: "customer_telephone",
      headerName: "Teléfono",
      headerClassName: "GridHeader",
      flex: 1,
      minWidth: 120,
      maxWidth: 130,
    },
    {
      field: "loan_status",
      headerName: "ESTADO",
      headerClassName: "GridHeader",
      headerAlign: "center",
      width: 60,
      hideable: false,
      renderCell: (params) => getLoanStatusIcon(params?.row.loan_status),
      valueGetter: (value) => value,
    },
    {
      field: "risk",
      headerName: "Riesgo",
      headerClassName: "GridHeader",
      // disableExport: true,
      headerAlign: "center",
      align: "center",
      width: 60,
      type: "number",
      renderCell: (params) => {
        return RiskIcon(loansList, params?.id);
      },
      valueGetter: (value, row) => {
        return getRiskValue(loansList, row.loan_id);
      },
      sortComparator: generalSortComparatorNumbers(
        (params) => getRiskValue(loansList, params?.id),
        (params) => getRiskValue(loansList, params?.id)
      ),
    },
    {
      field: "payments",
      headerName: "Pagos",
      headerClassName: "GridHeader",
      width: 75,
      sortable: true,
      valueGetter: (value) => {
        return value !== undefined && !isNaN(value) ? parseFloat(value) : 0;
      },
      renderCell: (params) => {
        const viewLoanDetails = (e) => {
          e.stopPropagation();
          handleClickDrawerLoanDetails(params?.row);
        };

        const hasAuditorRegionalManagerAccess = checkAccess(
          currentRole,
          "Auditor o Gerente Regional"
        );

        return (
          <Box display="flex" alignItems="center">
            <Tooltip title="Lista de pagos" placement="right" arrow>
              <IconButton
                // autoFocus
                color="primary"
                variant="contained"
                size="small"
                // why is data-target needed in icon button?
                data-target={`#id${params?.row.loan_id}`}
                onClick={viewLoanDetails}
              >
                {hasAuditorRegionalManagerAccess ? (
                  <ModeComment count={params?.row.payments} size={15} />
                ) : (
                  <InfoIcon sx={{ fontSize: "2rem" }} />
                )}
              </IconButton >
            </Tooltip>
          </Box>
        );
      },
    },
    {
      field: "loan_amount",
      headerName: "Capital inicial",
      headerClassName: "GridHeader",
      headerAlign: "right",
      type: "number",
      flex: 1,
      minWidth: 80,
      maxWidth: 120,
      renderCell: (params) => (
        <AmountDisplay amount={params?.row.loan_amount} />
      ),
    },
    {
      field: "loan_dueday",
      headerName: "Día de pago",
      headerClassName: "GridHeader",
      flex: 1,
      align: "left",
      type: "string",
      minWidth: 90,
      maxWidth: 120,
      valueFormatter: (value) => WeekDayDueToPayment[value - 1].label,
    },
    {
      field: "loan_dateissue",
      headerName: "Fecha inicial",
      headerClassName: "GridHeader",
      type: "date",
      flex: 1,
      minWidth: 100,
      maxWidth: 150,
      valueFormatter: (value) => format(parseISO(value), "dd/MM/yyyy"),
    },
    {
      field: "loan_datedue",
      headerName: "Fecha de pago",
      headerClassName: "GridHeader",
      type: "date",
      flex: 1,
      minWidth: 100,
      maxWidth: 150,
      valueFormatter: (value) => format(parseISO(value), "dd/MM/yyyy"),
    },
    {
      field: "loan_remaining",
      headerName: "Capital pendiente",
      headerClassName: "GridHeader",
      type: "number",
      flex: 1,
      minWidth: 90,
      maxWidth: 120,
      renderCell: (params) => (
        <AmountDisplay amount={params?.row.loan_remaining} />
      ),
    },
    {
      field: "interest",
      headerName: "Refrendo",
      headerClassName: "GridHeader",
      type: "number",
      flex: 1,
      minWidth: 90,
      maxWidth: 120,
      renderCell: (params) => (
        <AmountDisplay amount={calculateOpenInterest(params?.row)} />
      ),
      valueGetter: (value, row) => `${calculateOpenInterest(row)}`,
    },
    {
      field: "totalpending",
      headerName: "Total pendiente",
      headerClassName: "GridHeader",
      type: "number",
      flex: 1,
      minWidth: 90,
      maxWidth: 120,
      renderCell: (params) => (
        <AmountDisplay amount={calculateOpenAmountTotal(params?.row)} />
      ),
      valueGetter: (value, row) => `${calculateOpenAmountTotal(row)}`,
    },
    {
      field: "arrears",
      headerName: "Mora",
      headerClassName: "GridHeader",
      type: "number",
      flex: 1,
      minWidth: 90,
      maxWidth: 120,
      renderCell: (params) => (
        <AmountDisplay amount={calculateOpenArrear(params?.row)} />
      ),
      valueGetter: (value, row) => `${calculateOpenArrear(row)}`,
    },
    {
      field: "delayedWeeks",
      headerName: "Atraso",
      headerClassName: "GridHeader",
      align: "center",
      type: "number",
      flex: 1,
      minWidth: 80,
      maxWidth: 120,
      renderCell: (params) => calculateDelayedWeeks(params?.row),
      valueGetter: (value, row) => `${calculateDelayedWeeks(row)}`,
    },
    {
      field: "customer_location",
      headerName: "Localidad",
      headerClassName: "GridHeader",
      flex: 1,
      minWidth: 120,
      maxWidth: 350,
    },
    {
      field: "customer_address",
      headerName: "Direccion",
      headerClassName: "GridHeader",
      flex: 1,
      minWidth: 250,
      maxWidth: 400,
    },
    {
      field: "loan_employeeid",
      headerName: "Empleado",
      headerClassName: "GridHeader",
      flex: 1,
      minWidth: 250,
      maxWidth: 350,
      renderCell: (params) => {
        if (hasAuditorAccess || hasRegionalManagerAccess)
          return (
            <Link to={`/Employees/${params?.row.loan_employeeid}`}>
              {params?.row.employee_fullname}
            </Link>
          );
        return params?.row.employee_fullname;
      },
    },
    {
      field: "loanTimeOfIssue",
      headerName: "Hora",
      headerClassName: "GridHeader",
      type: "date",
      flex: 1,
      minWidth: 110,
      maxWidth: 130,
      sortable: false,
      renderCell: (params) => {
        return `${format(parseISO(params.row.loan_dateissue), "HH:mm:ss")} hrs`;
      },
    },
    {
      field: "loan_id",
      headerName: "ticket",
      headerClassName: "GridHeader",
      width: 80,
      sortable: false,
      renderCell: (params) => {
        return (
          <Box display="flex" alignItems={"center"}>
            <Tooltip
              id="button-customer-details"
              title="Ver ticket de préstamo"
            >
              <IconButton
                color="primary"
                variant="contained"
                size="large"
                data-target={`#id${params?.row.customer_id}`}
                onClick={() =>
                  handleNavigation(`Ticket/${params?.row.loan_id}`)
                }
              >
                {""}
                <DescriptionIcon fontSize="large" />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
    },
    {
      field: "accionPosponer",
      headerName: "",
      width: 45,
      hideable: false,
      sortable: false,
      renderCell: (params) => {
        const triggerPostponeDialog = (e) => {
          e.stopPropagation();
          setSelectedLoan(params?.row);
          setOpenPostponeDialog(true);
        };
        const isDisabled = !(
          params?.row.loan_status === "OPEN" &&
          differenceInDays(new Date(), parseISO(params?.row.loan_dateissue)) <=
            3 &&
          params?.row.payments === "0"
        );
        return (
          <TooltipIconButton
            title="Posponer préstamo"
            onClick={triggerPostponeDialog}
            disabled={isDisabled}
            color="warning"
            size="medium"
            variant="contained"
            icon={<MoveDownRoundedIcon fontSize="large" />}
          />
        );
      },
    },
    {
      field: "accionRevocar",
      headerName: "",
      width: 45,
      hideable: false,
      sortable: false,
      renderCell: (params) => {
        const triggerConfirmationPage = (e) => {
          e.stopPropagation();
          setSelectedLoan(params?.row);
          setConfirmationRemoveLoan(true);
        };

        const isDisabled = !(
          params?.row.loan_status === "OPEN" &&
          differenceInDays(new Date(), parseISO(params?.row.loan_dateissue)) <=
            15 &&
          params?.row.payments === "0"
        );
        return (
          <TooltipIconButton
            title="Rechazar préstamo"
            onClick={triggerConfirmationPage}
            disabled={isDisabled}
            color="warning"
            size="medium"
            variant="contained"
            icon={<DeleteForeverRoundedIcon fontSize="large" />}
          />
        );
      },
    },
    {
      field: "accionLimpiar",
      headerName: "",
      width: 45,
      hideable: false,
      sortable: false,
      renderCell: (params) => {
        const triggerConfirmationPage = (e) => {
          e.stopPropagation();
          setSelectedLoan(params?.row);
          setConfirmationWriteOff(true);
        };
        const isDisabled = !(params?.row.loan_status === "OPEN");
        // && differenceInDays(new Date(), parseISO(params?.row.loan_dateissue)) >= 30);
        return (
          <TooltipIconButton
            title="Limpiar préstamo"
            onClick={triggerConfirmationPage}
            disabled={isDisabled}
            color="error"
            size="medium"
            variant="contained"
            icon={<CleaningServicesRoundedIcon fontSize="large" />}
          />
        );
      },
    },
  ];

  return loanColumns;
};

export const visibilityModelLoanContainer = (currentRole) => {
  const hasRegionalManagerAccess = checkAccess(currentRole, "Gerente Regional");
  const hasAuditorAccess = checkAccess(currentRole, "Auditor");

  return {
    accionPago: false,
    // loan_status: false,
    payments: false,
    risk: false,
    customer_location: false,
    customer_address: false,
    loan_employeeid: false,
    accionRevocar: hasAuditorAccess || hasRegionalManagerAccess,
    accionPosponer: hasAuditorAccess || hasRegionalManagerAccess,
    accionLimpiar: false,
  };
};

export const visibilityModelLoanOverview = (currentRole) => {
  const hasRegionalManagerAccess = checkAccess(currentRole, "Gerente Regional");
  const hasAuditorAccess = checkAccess(currentRole, "Auditor");
  const hasAdminAccess = checkAccess(currentRole, "Administrador");
  return {
    // payments: hasAuditorAccess || hasRegionalManagerAccess,
    loan_id: false,
    loan_status: false,
    loan_employeeid: false,
    loanTimeOfIssue: false,
    accionRevocar: false,
    accionPosponer: false,
    accionLimpiar: hasAdminAccess,
  };
};

export const visibilityModelLoanFilialDetailsPager = (currentRole) => {
  const hasRegionalManagerAccess = checkAccess(currentRole, "Gerente Regional");
  const hasAuditorAccess = checkAccess(currentRole, "Auditor");
  const hasAdminAccess = checkAccess(currentRole, "Administrador");
  return {
    loan_status: false,
    payments: false,
    risk: false,
    loan_employeeid: false,
    loan_id: false,
    loanTimeOfIssue: false,
    accionRevocar: false,
    accionPosponer: false,
    accionLimpiar: false,
  };
};

export const visibilityModelLoanEmployeeProfile = (currentRole) => {
  const hasRegionalManagerAccess = checkAccess(currentRole, "Gerente Regional");
  const hasAuditorAccess = checkAccess(currentRole, "Auditor");

  return {
    accionPago: false,
    customer_telephone: false,
    payments: false,
    loan_id: false,
    risk: false,
    loan_status: false,
    customer_location: false,
    customer_address: false,
    loan_employeeid: false,
    accionRevocar: false,
    accionPosponer: false,
    accionLimpiar: false,
  };
};

export const visibilityModelLoanCustomerProfile = (currentRole) => {
  const hasRegionalManagerAccess = checkAccess(currentRole, "Gerente Regional");
  const hasAuditorAccess = checkAccess(currentRole, "Auditor");
  const hasAdminAccess = checkAccess(currentRole, "Administrador");

  return {
    customer_idnumber: false,
    customer_fullname: false,
    customer_telephone: false,
    // payments: hasAuditorAccess || hasRegionalManagerAccess,
    loan_id: false,
    customer_location: false,
    customer_address: false,
    loan_employeeid: false,
    // loan_status: false,
    accionRevocar: false,
    accionPosponer: false,
    accionLimpiar: hasAdminAccess,
  };
};

export const visibilityModelLoanWriteOffContainer = (currentRole) => {
  const hasRegionalManagerAccess = checkAccess(currentRole, "Gerente Regional");
  const hasAuditorAccess = checkAccess(currentRole, "Auditor");

  return {
    accionPago: false,
    // loan_status: false,
    payments: false,
    risk: false,
    customer_location: false,
    customer_address: false,
    loan_employeeid: false,
    loan_id: false,
    accionRevocar: false,
    accionPosponer: false,
    accionLimpiar: false,
  };
};