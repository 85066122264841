import React, { useState, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";

import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles"; // deprecated warning
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";

// import Box from '@mui/system/Box';

// Material UI
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import Grid from "@mui/material/Grid2";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Select from "../../components/controls/Select";
import Person from "@mui/icons-material/Person";
import { NovaContext } from "../../components/context";
import { checkAccess } from "../../components/Helper/GlobalHelpers";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

// export default function CustomizedDialogs() {
export const Filial_NewEdit = (props) => {
  // initialise props
  const { filial, setLockStatus, cluster } = props;

  // Misc variables
  let navigate = useNavigate();

  // context data
  const { actions, currentRole } = React.useContext(NovaContext);
  const isNewMode = !filial;

  const [open, setOpen] = React.useState(false);

  // const filialInfo = "filialInfo";

  const hasAdminAccess = checkAccess(currentRole, "Administrador");

  const [errors, setErrors] = useState([]);

  // Set up refs for the react form -> refs are automatically updated when bound
  const [FilialName, setFilialName] = useState("");
  const [FilialId, setFilialId] = useState("");
  const [Address, setAddress] = useState("");
  const [Telephone, setTelephone] = useState("");
  const [Initialloanvalue, setInitialloanvalue] = useState();
  const [Maxloanvalue, setMaxloanvalue] = useState();

  // const FilialName = useState();
  // const Address = useRef();
  // const Telephone = useRef();
  // const Initialloanvalue = useRef();
  // const Maxloanvalue = useRef();

  // triggers the form's object initialisation as a result of React's multi load rendering
  const initializeForm = (Object) => {
    // console.log(Object,FilialName);
    setFilialId(Object ? (Object.filial_id ? Object.filial_id : "") : "");
    setFilialName(Object ? (Object.filial_name ? Object.filial_name : "") : "");
    setAddress(
      Object ? (Object.filial_address ? Object.filial_address : "") : ""
    );
    setTelephone(Object ? (Object.telephone ? Object.telephone : "") : "");
    setInitialloanvalue(
      Object ? (Object.initialloanvalue ? Object.initialloanvalue : "") : ""
    );
    setMaxloanvalue(
      Object ? (Object.maxloanvalue ? Object.maxloanvalue : "") : ""
    );
  };

  const handleClick = async () => {
    initializeForm(filial);
    if (!isNewMode) {
      // await actions.getFilial(filial.filial_id).then((data) => {
      //   initializeForm(data);
      // });
    } else {
      // setChangesMade(true);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
    handleClick();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const clearFields = () => {
    setErrors([]);
    initializeForm(filial);
  };

  // reverts form data to initial state. Necessary for the shrink attribute of TextField
  const revertChange = () => {
    // setSelectedArea("");
    clearFields();
    handleClose();
  };

  const validateData = () => {
    setErrors([]);
    let isValid = true;
  };

  const handleCreate = async (e) => {
    e.preventDefault();
    setLockStatus(true);
    // const isEntryValid = validateData();
    const isEntryValid = true;
    console.log(
      isNewMode,
      FilialId,
      FilialName,
      Address,
      Telephone,
      Initialloanvalue,
      Maxloanvalue
    );
    if (isEntryValid) {
      const tempFilial = {
        filial: FilialId,
        name: FilialName,
        address: Address,
        telephone: Telephone,
        initialloanvalue: Initialloanvalue,
        maxloanvalue: Maxloanvalue,
        cluster: cluster,
      };
      // console.log("TF:", tempFilial);
      const filialResp = isNewMode
        ? await await actions.addFilial(tempFilial)
        : await actions.updateFilialValues(tempFilial);
      // : await actions.updateFilial(tempFilial, filial.filial_id);

      if (filialResp) {
        revertChange();
        // const path = `/filial/${filial.filial_id}`;
        // navigate(path);
      } else {
        console.log("Filial create/edit failed");
      }
    }

    handleClose();
  };

  return isNewMode || hasAdminAccess ? (
    <Box paddingLeft={1}>
      <Button
        // variant="contained"
        size="small"
        // color='primary'
        onClick={handleClickOpen}
      >
        <AddIcon />
        {isNewMode ? ("AÑADIR SUCURSAL" ): "EDITAR SUCURSAL"}
      </Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          {isNewMode ? "Añadir Sucursal" : "Editar Sucursal"}
        </BootstrapDialogTitle>

        <DialogContent dividers>
          <Grid container padding="10px">
            {/* {isNewMode ? ( */}
            <Box
              component={"span"}
              width={"100%"}
              display={"flex"}
              justifyContent={"space-between"}
              marginBottom={1}
            >
              <TextField
                variant="outlined"
                label="Nombre"
                id="name"
                fullWidth
                // inputRef={FilialName}
                value={FilialName}
                onChange={(e) => setFilialName(e.target.value)}

                // placeholder='Nombre'
              />
            </Box>
            {/* ) : null} */}

            <Box
              component={"span"}
              width={"100%"}
              display={"flex"}
              justifyContent={"space-between"}
              marginBottom={1}
            >
              <TextField
                variant="outlined"
                label="Dirección"
                id="address"
                fullWidth
                // inputRef={Address}
                value={Address}
                onChange={(e) => setAddress(e.target.value)}
                // placeholder='Apellido'
              />
            </Box>
            <Box
              component={"span"}
              width={"100%"}
              display={"flex"}
              justifyContent={"space-between"}
              marginBottom={1}
            >
              <TextField
                variant="outlined"
                label="Teléfono"
                id="telephone"
                fullWidth
                value={Telephone}
                onChange={(e) => setTelephone(e.target.value)}
                placeholder="xxx xxx xxxx"
              />
            </Box>
            {!isNewMode ? (
              <Box
                component={"span"}
                width={"100%"}
                display={"flex"}
                justifyContent={"space-between"}
                marginBottom={1}
              >
                <TextField
                  variant="outlined"
                  label="Máximo valor inicial"
                  id="initialloanvalue"
                  fullWidth
                  value={Initialloanvalue}
                  onChange={(e) => setInitialloanvalue(e.target.value)}
                  // placeholder="xxx xxx xxxx"
                />
              </Box>
            ) : null}

            {!isNewMode ? (
              <Box
                component={"span"}
                width={"100%"}
                display={"flex"}
                justifyContent={"space-between"}
                marginBottom={1}
              >
                <TextField
                  variant="outlined"
                  label="Máximo valor de préstamo"
                  id="maxloanvalue"
                  fullWidth
                  value={Maxloanvalue}
                  onChange={(e) => setMaxloanvalue(e.target.value)}
                  // placeholder="xxx xxx xxxx"
                />
              </Box>
            ) : null}
            {/* </Box> */}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            color="success"
            variant="contained"
            // onClick={handleClose}
            onClick={handleCreate}
          >
            Continuar
          </Button>
          <Button
            autoFocus
            color="secondary"
            variant="contained"
            onClick={() => revertChange()}
          >
            Cancelar
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </Box>
  ) : null;
};

export default Filial_NewEdit;

// Form components
