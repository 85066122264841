import React, { useContext, useEffect, useState } from "react";
import { NovaContext } from "../../components/context";
import { useParams } from "react-router-dom";

// Date fns import
import PropTypes from "prop-types";

// Internal import
import { CustomerStatus } from "../../components/Helper/Enums";
import Loading from "../../components/Actions/Loading";
import defaultProfile from "../../images/profile-default.png";
import CustomerContent from "./CustomerContent";
import Image_NewEdit from "../Modal/Image_NewEdit";
import LoanDetailsDrawer from "../LoanOverview/LoanDetailsDrawerV2";
import CustomerInfoTabs from "./CustomerInfoTabs";
import NewDocument from "../Modal/Document_New";
import {
  calculateOpenArrear,
  calculateRisk,
} from "../../components/Helper/LoanHelpers";
import { uploadDestination } from "../../components/Helper/MediaHelpers";

// Material UI import
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

// Material UI Date Picker
import CustomerHeader from "./CustomerHeader";
import { ItemBox } from "../../components/Helper/MUIStyledComponents";
import Payment_New from "../Modal/Payment_New";
import Loan_New from "../Modal/Loan_New";
import Media_New from "../Modal/Media_New";
import Loan_PostponeDueDate from "../Modal/Loan_EditDueDate";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const Index = () => {
  const { actions } = useContext(NovaContext);

  const params = useParams();
  const customerId = params.id;
  const errors = [];

  // page-related state
  const [loading, setLoading] = useState(true);
  const [lockStatus, setLockStatus] = useState(false);
  const [drawer, setDrawer] = useState(false);
  const [dialogPayment, setDialogPayment] = useState(false);
  const [dialogLoan, setDialogLoan] = useState(false);
  // const [openPostponeDialog, setOpenPostponeDialog] = useState(false);
  const [tabValue, setTabValue] = useState(0);

  // customer-related state
  const [loan, setLoan] = useState({});
  const [loanPage, setLoanPage] = useState(1);
  const [customer, setCustomer] = useState({});
  const [loanList, setLoanList] = useState([]);
  const [audittrailList, setAudittrailList] = useState([]);
  const [selectedLoan, setSelectedLoan] = useState(null);
  // const [selectedLoanId, setSelectedLoanID] = useState(null);
  const [loansOnMeter, setLoansOnMeter] = useState([]);
  const [mediaMode, setMediaMode] = useState(false);
  const [mediaItems, setMediaItems] = useState([]);
  const [nextIncrementValue, setNextIncrementValue] = useState(null);
  const [paymentsList, setPaymentsList] = useState([]);

  // Button pop up states - Picture loading still a work in progress!
  const [profilePic, setProfilePic] = useState("");

  // For picture box -> will be put into its own component at some stage
  const replacePhoto = (uploadedImage) => {
    setProfilePic(uploadedImage);
    setMediaItems((prevState) => {
      return [...prevState, uploadedImage];
    });
  };

  const updateMedia = (newMedia, command) => {
    if (command === "ADD") {
      setMediaItems((prevState) => {
        return [...prevState, newMedia];
      });
    } else {
      setMediaItems((prevState) => {
        return prevState.filter((item) => item.media_id !== newMedia.media_id);
      });
    }
  };

  const handleUploadMedia = (newMedia) => {
    updateMedia(newMedia, "ADD");
  };

  // Configuration
  // credit Limit
  const [editCreditLimit, setEditCreditLimit] = useState(false);

  useEffect(() => {
    // temporary values
    let tempMaxLoanValue = "";
    let tempCustomerLoanCounter = "";

    const fetchData = async () => {
      // get customer data
      await actions
        .getCustomer(customerId)
        .then((resp) => {
          setCustomer(resp);
          tempMaxLoanValue = resp.currentmaxloanvalue;
          tempCustomerLoanCounter = resp.counteratloanvalue;
          return resp;
        })
        .catch((err) => errors.push(err));

      // get customer loans
      await actions
        .getLoansFromCustomer(customerId)
        .then((resp) => {
          const LoanList = resp.map((item) => {
            const fullName = item.customer_name + " " + item.customer_lastname;
            return {
              ...item,
              fullName: fullName,
            };
          });
          setLoanList(LoanList);
          if (LoanList[0]) {
            setLoan(LoanList[0]);
            setSelectedLoan(LoanList[0]);
          }
        })
        .catch((err) => errors.push(err));

      // get AuditTrail list
      await actions
        .getAuditTrail("customer", customerId)
        .then((resp) => {
          setAudittrailList(resp);
        })
        .catch((err) => errors.push(err));

      // get customer active loan
      await actions
        .getLoanFromMeterNumber(customer.customer_meternumber)
        .then((resp) => {
          // temporary solution to convert object to array
          let loanArray = [resp];
          setLoansOnMeter(
            loanArray.filter(
              (loanItem) => loanItem.loan_customerid !== customer.customer_id
            )
          );
        })
        .catch((err) => errors.push(err));

      // Get all customer media
      await actions
        .getMediaReference(customerId)
        .then((resp) => {
          // Set profile pic via reference from DB
          if (resp.data.length > 0) {
            setProfilePic(resp.data.find(({ isprofile }) => isprofile));
          }
          setMediaItems(resp.data);
        })
        .catch((err) => errors.push(err));

      let tempIncrementData = "";
      await actions
        .getLoanIncrement({ value: tempMaxLoanValue })
        .then((resp) => {
          tempIncrementData = resp;
        })
        .catch((err) => errors.push(err));

      // if customer loan counter > specified by the filial then send the initial value from the interval back
      if (tempCustomerLoanCounter >= tempIncrementData.renewals) {
        const nextValueRequest = {
          value: tempMaxLoanValue,
          renewals: tempIncrementData.renewals,
        };
        await actions
          .getLoanIncrementNextValue(nextValueRequest)
          .then((newLoanCreditLimit) => {
            setNextIncrementValue(newLoanCreditLimit);
          })
          .catch((err) => errors.push(err));
      }

      setLoading(false);
    };

    fetchData();
  }, []);

  // toggle for payments list
  const toggleDrawer = (loan) => {
    const loanId = loan.loan_id;
    setPaymentsList(getPaymentsFromLoan(loanId));
    setSelectedLoan(loan);
    setDrawer(true);
  };

  // toggle for payment Dialog
  const togglePaymentDialog = (loan) => {
    setSelectedLoan(loan);
    setDialogPayment(true);
  };

  // toggle for payment Dialog
  const toggleLoanDialog = () => {
    setDialogLoan(true);
  };

  // triggered when the information icon per loan is clicked
  const getPaymentsFromLoan = async (loanId) => {
    await actions
      .getPaymentsFromLoan(loanId)
      .then((resp) => {
        setPaymentsList(resp);
      })
      .catch((err) => console.log(err));
  };

  const updateCustomerState = (status) => {
    setCustomer((prevState) => {
      return { ...prevState, status };
    });
  };

  const calculateLatestRisk = (loan, risk) => {
    // console.log(risk,loan);
    // console.log((calculateRisk(loan) + risk) * 0.5);
    return ((calculateRisk(loan) + risk) * 0.5).toFixed(2);
  };

  const alterCustomerPasswordStatus = (status) => {
    setCustomer((prevState) => {
      return { ...prevState, passworddisabled: status };
    });
  };

  const updateCreditLimitBlock = (status) => {
    setCustomer((prevState) => {
      return { ...prevState, raiseblocked: status };
    });
  };

  // remove this when the bottom bar is removed...
  // but be sure that this is hooked up with new edit loan...
  const setNewCreditLimit = async (newCreditLimit, updatedCounterValue) => {
    await actions
      .setCustomCreditLimit(
        newCreditLimit,
        customer.currentmaxloanvalue,
        updatedCounterValue,
        customer.customer_id
      )
      .then((resp) => {
        if (resp.status === 201) {
          setCustomer({
            ...customer,
            currentmaxloanvalue: newCreditLimit,
            updatedcountervalue: updatedCounterValue,
          });
          setEditCreditLimit(false);
        } else {
          alert("Algo ha fallado en la solicitud: ", resp.status);
        }
      });
  };

  const calculateCustomerArrears = () => {
    var ArrearsSum = 0;
    for (var i = 0; i < loanList.length; i++) {
      if (loanList[i].loan_status === "OPEN") {
        ArrearsSum = ArrearsSum + calculateOpenArrear(loanList[i]);
      } else {
        ArrearsSum = ArrearsSum + parseInt(loanList[i].arrears);
      }
    }
    return ArrearsSum;
  };

  // method triggered when moving customer from blocked or disabled status
  const determineNewStatus = (currentStatus) => {
    let newStatus = "";
    if (currentStatus === CustomerStatus[3].value) {
      newStatus = CustomerStatus[1].value;
    } else if (currentStatus !== CustomerStatus[2].value) {
      newStatus = CustomerStatus[2].value;
    } else {
      if (loanList && loanList.length > 0) {
        newStatus =
          loan.loan_status === "OPEN"
            ? CustomerStatus[0].value
            : CustomerStatus[1].value;
      } else {
        newStatus = CustomerStatus[1].value;
      }
    }
    return newStatus;
  };

  return (
    // <div className="page-container">
    <Grid container sx={{ padding: { xs: "10px", md: " 25px" } }}>
      <div
        className={`over-page-loading ${lockStatus || loading ? "show-loading" : "hide-loading"
          }`}
      >
        <div className="over-page-loading-content">
          <Loading
            text={"Cargando..."}
            classNames={`over-page-loading ${lockStatus || loading ? "show-loading" : "hide-loading"
              }`}
          />
        </div>
      </div>

      {lockStatus || loading ? (
        <div>Cargando...</div>
      ) : (
        <Grid container spacing={2}>
          {/* Header section */}
          <Grid size={12}>
            <CustomerHeader
              customer={customer}
              loan={loan}
              loansOnMeter={loansOnMeter}
            />
          </Grid>
          {/* Details section */}
          <Grid
            container
            size={{ xs: 12, md: 4 }}
            direction="column"
            spacing={2}
          >
            <Grid size={{ xs: 12 }} d>
              <ItemBox>
                <Box
                  padding={1}
                  position={"relative"}
                  sx={{
                    height: 350,
                    orderRadius: "50%",
                    overflow: "hidden",
                  }}
                >
                  <Box
                    component="img"
                    src={profilePic ? profilePic.location : defaultProfile}
                    sx={{
                      height: "100%",
                      // width: "100%",
                      objectFit: "cover",
                      borderRadius: "25%",
                      // borderRadius: "50%",
                    }}
                  />
                  <Box position={"absolute"} right={0} bottom={0}>
                    <Image_NewEdit
                      buttonTitle={`${profilePic ? "Editar" : "Añadir"
                        } foto de perfil`}
                      photo={profilePic ? profilePic : {}}
                      customerId={customer.customer_id}
                      updateProfile={replacePhoto}
                      isProfile={true}
                      roundButton={true}
                    />
                  </Box>
                </Box>
              </ItemBox>
            </Grid>
            <Grid
              size={{ xs: 12 }}
            // display="flex"
            >
              <CustomerInfoTabs
                customer={customer}
                updateCustomerState={updateCustomerState}
                determineNewStatus={determineNewStatus}
                alterCustomerPasswordStatus={alterCustomerPasswordStatus}
                updateCreditLimitBlock={updateCreditLimitBlock}
                updateCustomer={setCustomer}
              />
            </Grid>
          </Grid>

          <Grid size={{ xs: 12, md: 8 }}>
            <Box position={"relative"}>
              <ItemBox padding={1}>
                <CustomerContent
                  customer={customer}
                  customerLoans={loanList}
                  loansLoaded={true}
                  viewLoanDetails={toggleDrawer}
                  togglePaymentDialog={togglePaymentDialog}
                  // toggleLoanPosponeDialog={post}
                  customerAudit={audittrailList}
                  auditLoaded={true}
                  customerMedia={mediaItems}
                  mediaLoaded={false}
                  updateMedia={updateMedia}
                />
                <Payment_New
                  open={dialogPayment}
                  toggleDialog={setDialogPayment}
                  loan={selectedLoan}
                  lockStatus={loading}
                  setLockStatus={setLoading}
                />
                <LoanDetailsDrawer
                  open={drawer}
                  toggleDrawer={setDrawer}
                  loan={selectedLoan}
                  payments={paymentsList}
                />
                <Loan_New
                  open={dialogLoan}
                  toggleDialog={setDialogLoan}
                  lockStatus={loading}
                  setLockStatus={setLoading}
                  customer={customer}
                  customerLoanStatus={customer.status}
                  currentLoanLimit={customer.currentmaxloanvalue}
                  nextEligibleLimit={
                    !customer.raiseblocked ? nextIncrementValue : null
                  }
                  setNewCreditLimit={setNewCreditLimit}
                />
                {/* <Loan_PostponeDueDate
                  open={openPostponeDialog}
                  toggleDialog={setOpenPostponeDialog}
                  loan={selectedLoan}
                /> */}

                <Box
                  position={"absolute"}
                  top={{ xs: 12, md: 20 }}
                  right={12}
                  display={"flex"}
                  alignItems={"center"}
                >
                  <Typography
                    align="right"
                    component="div"
                    sx={{
                      typography: { xs: "body1", md: "h6" },
                      mr: { xs: 0.3, md: 1 },
                    }}
                  >
                    <Box sx={{ fontWeight: "bold" }}>
                      Mora acumulada: ${calculateCustomerArrears()}
                    </Box>
                  </Typography>
                  {loanList.length > 0 ? (
                    <Button
                      key="newpayment"
                      variant="contained"
                      size="small"
                      sx={{ ml: { xs: 0.5, md: 0.5 } }}
                      onClick={() => togglePaymentDialog(loan)}
                    >
                      Agregar pago
                    </Button>
                  ) : null}
                  {customer.status === "INACTIVE" &&
                    loansOnMeter.length === 0 ? (
                    <Button
                      key="newloan"
                      variant="contained"
                      size="small"
                      sx={{ ml: { xs: 0.5, md: 0.5 } }}
                      onClick={() => toggleLoanDialog()}
                    >
                      Agregar préstamo
                    </Button>
                  ) : null}
                  {/* removed 10.11.24 -> add back if integration fails */}
                  {/* <Image_NewEdit
                    buttonTitle="Añadir imagen"
                    photo={null}
                    customerId={customer.customer_id}
                    updateProfile={updateMedia}
                    isProfile={false}
                    roundButton={false}
                  /> */}
                  {/* <NewDocument
                    objectUuid={customer.customer_id}
                    afterAddFunction={handleUploadMedia}
                    destination={uploadDestination.CUSTOMER}
                  /> */}
                  <Media_New
                    objectUuid={customer.customer_id}
                    afterAddFunction={handleUploadMedia}
                    destination={uploadDestination.CUSTOMER}
                  />
                </Box>
              </ItemBox>
            </Box>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default Index;
