import React from "react";
import { IconButton, Tooltip, Box } from "@mui/material";

const TooltipIconButton = ({
  title,
  onClick,
  disabled,
  color,
  size,
  variant,
  icon,
}) => {
  return (
    <Box display="flex" alignItems="center">
      {disabled ? (
        <div>
          {/* Show the button without Tooltip when disabled */}
          <IconButton
            color={color}
            variant={variant}
            size={size}
            onClick={onClick}
            disabled={disabled}
          >
            {icon}
          </IconButton>
        </div>
      ) : (
        <Tooltip title={title} placement="bottom" arrow>
          <IconButton
            color={color}
            variant={variant}
            size={size}
            onClick={onClick}
            disabled={disabled}
          >
            {icon}
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
};

export default TooltipIconButton;
