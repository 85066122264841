import React, { useState, useContext, useEffect } from "react";
import {
  calculateReportDates,
  checkAccess,
  getTimeDifferenceInHours,
} from "../../components/Helper/GlobalHelpers";

import {
  CircularProgressWithLabel,
  ItemPaper,
} from "../../components/Helper/MUIStyledComponents";

// Date fns
import addHours from "date-fns/addHours";
import format from "date-fns/format";

// Internal imports
import { NovaContext } from "../../components/context";

// MUI imports
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
  calculateArrearPaid,
  calculateGoalPercentage,
} from "../../components/Helper/ReportsHelpers";

const FilialGoalItem = React.memo((props) => {
  const { filialId } = props;

  const { actions, currentRole } = useContext(NovaContext);

  const [CashRegisterReport, setCashRegisterReport] = useState(0);

  const [currentDateTime, setCurrentDateTime] = useState();
  const [reportTotalData, setReportTotalData] = useState({});
  const [reportMode, setReportMode] = useState("WEEK");
  const [loading, setLoading] = useState(true);

  const hasRegionalManagerAccess = checkAccess(currentRole, "Gerente Regional");
  const hasAuditorAccess = checkAccess(currentRole, "Auditor");

  useEffect(() => {
    setCurrentDateTime(format(new Date(), "dd/MM/yyyy HH:mm:ss") + " hrs");
    const currentDate = addHours(new Date(), +getTimeDifferenceInHours());

    const fetchData = async () => {

      const cashRegisterReport = await actions.getCurrentCashRegisterReport();
      setCashRegisterReport(cashRegisterReport);

      const DataRange = calculateReportDates(reportMode, currentDate);
      DataRange.filialId = filialId;
      const reportData = await actions.getReports(DataRange);
      setReportTotalData(reportData);
      setLoading(false);
    };

    fetchData();
  }, [filialId]);

  const goalPercentage = calculateGoalPercentage(
    CashRegisterReport,
    reportTotalData
  );

  return (
    <ItemPaper>
      <Typography variant="h3">
        <Box sx={{ fontWeight: "bold", m: 0.3 }}>Meta</Box>
      </Typography>
      {loading ? (
        <div>Cargando...</div>
      ) : (
        <div>
          <CircularProgressWithLabel
            value={Math.floor(goalPercentage)}
            sx={{
              color: (theme) =>
                goalPercentage > 79.999
                  ? goalPercentage > 89.999
                    ? goalPercentage > 94.999
                      ? theme.palette.success.dark
                      : theme.palette.warning.main
                    : theme.palette.error.light
                  : theme.palette.error.dark,
            }}
            size={100}
            thickness={6}
            typographyVariant={"h4"}
          />
          {hasRegionalManagerAccess || hasAuditorAccess ? (
            <Typography variant="h5">
              {`${reportTotalData.interest} de ${Math.round(
                CashRegisterReport.weeklygoal
              )}`}
            </Typography>
          ) : null}
          <Typography variant="h6">
            {`Mora cobrada -> ${calculateArrearPaid(CashRegisterReport)}%`}
          </Typography>
        </div>
      )}
    </ItemPaper>
  );
});

export default FilialGoalItem;
