import { LOANS as loans } from "../config/routes";
import SendRequest from "../../lib/api_request/api_request";
import { GET, POST, DELETE, PATCH } from "./enum_apitypes";
import { ServerURI, ServerPORT } from "../config/uri";
import { NOVACOOKIE } from "../config/novacookies";
import { getCookie } from "../../lib/cookiesjs/cookiesjs";
import apiClient from "../../lib/api_request/api_request_V2";

const sendRequest = new SendRequest();
const endpoint = `${ServerURI}:${ServerPORT}/${loans}`;
const accessToken = await getCookie(NOVACOOKIE);

// commented out on 12.12 - remove when new method is stable
// export const getLoans = async (params) => {
//     return await
//         sendRequest.request(
//             `${endpoint}?${params}`,
//             GET,
//             null,
//             accessToken,
//             null
//         )
//             .then(resp => resp.data)
//             .catch(err => err)
// }

export const getLoans = async (params) => {
    return await
        apiClient.get(`${loans}?${params}`)
            .then(resp => resp.data)
            .catch(err => err)
}

// commented out on 12.12 -> remove when new method is stable
// export const addLoan = async (loan) => {
//     return await
//         sendRequest.request(
//             endpoint,
//             POST,
//             loan,
//             accessToken,
//             null
//         )
//             .then(resp => resp)
//             .catch(err => console.log(`An error occured: ${err}`))
// }

export const addLoan = async (loan) => {
    return await
        apiClient.post(`${loans}`, loan)
            .then(resp => resp)
            .catch(err => err)
}

// commented out on 12.12 -> remove when new method is stable
// export const deleteLoan = async (id) => {
//     return await
//         sendRequest.request(
//             `${endpoint}/${id}`,
//             DELETE,
//             null,
//             accessToken,
//             null
//         )
//             .then(resp => resp)
//             .catch(err => err.response)
// }

export const deleteLoan = async (id) => {
    return await
        apiClient.delete(`${loans}/${id}`)
            .then(resp => resp)
            .catch(err => err)
}

// commented out on 12.12 -> remove when new method is stable
// export const updateLoan = async (loan, id) => {
//     return await
//         sendRequest.request(
//             `${endpoint}/${id}`,
//             PATCH,
//             loan,
//             accessToken,
//             null
//         )
//             .then(resp => resp)
//             .catch(err => console.log(`An error occured: ${err}`))
// }

export const updateLoan = async (loan, id) => {
    return await
        apiClient.patch(`${loans}/${id}`, loan)
            .then(resp => resp)
            .catch(err => err)
}

// commented out on 12.12 -> remove when new method is stable
// export const writeOffLoan = async (id, filial) => {
//     return await
//         sendRequest.request(
//             `${endpoint}/writeoff/${id}?filial=${filial}`,
//             POST,
//             null,
//             accessToken,
//             null
//         )
//             .then(resp => resp)
//             .catch(err => err.response)
// }

// question: what is behind id? Is it loanId = <uuid>?
export const writeOffLoan = async (id, filial) => {
    return await
        apiClient.post(`${loans}/writeoff/${id}?filial=${filial}`)
            .then(resp => resp)
            .catch(err => err)
}