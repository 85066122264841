import React, { useState, useContext } from 'react';

// mui
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { Grid2 as Grid, Paper, Typography } from '@mui/material';

// misc
import { addHours, format } from 'date-fns';
import { getTimeDifferenceInHours } from '../../components/Helper/GlobalHelpers';

// internal
import { NovaContext } from '../../components/context';
import { alerts, displayAlert } from '../../components/Helper/AlertToastify';
import { createMediaObject, determineMediaType, getAcceptedMediaFormats, uploadDestination, validateMediaUpload } from '../../components/Helper/MediaHelpers';
import { createMedia } from '../../utils/api/api_document';
import { ItemPaper } from '../LoanOverview/LoanDetailsDrawer';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const Media_New = (props) => {
    const { objectUuid, afterAddFunction, destination } = props;
    const { authenticatedUser, filial } = useContext(NovaContext);
    const [open, setOpen] = React.useState(false);
    const [mediaPath, setMediaPath] = useState();
    const [selectedMedia, setSelectedMedia] = useState('');
    const [lock, setLock] = useState(false);
    const [errors, setErrors] = useState([]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setMediaPath('');
        setSelectedMedia('');
        setOpen(false);
    };

    const handleSelection = (e) => {
        e.preventDefault();
        setMediaPath(e.target.value);
        const uploadedMedia = e.target.files[0];
        setSelectedMedia(uploadedMedia);
    }

    const handleDocumentSubmit = async (e) => {
        e.preventDefault();
        setLock(true);
        setErrors('');

        const errorArray = validateMediaUpload(mediaPath);


        if (errorArray.length > 0) {
            setErrors(errorArray);
        } else {
            const newMedia = createMediaObject(selectedMedia, objectUuid, authenticatedUser.employee_id, filial, false, destination);
            console.log(newMedia);
            console.log(mediaPath);
            
            
            const mediaType = determineMediaType(mediaPath).toLowerCase();

            await createMedia(`${mediaType}s`, newMedia)
                .then(resp => {
                    if (resp.status && resp.status === 500) {
                        setErrors(resp.data);
                        setLock(false);
                        displayAlert(alerts.ERROR, `Algo ha fallado en la solicitud: ${resp.data}`);
                    } else if (resp.status && resp.status === 400) {
                        setErrors(resp.data);
                        setLock(false);
                        displayAlert(alerts.ERROR, `Algo ha fallado en la solicitud: ${resp.data}`);
                    } else {
                        afterAddFunction(resp);
                        displayAlert(alerts.SUCCESS, "Medio cargada correctamente");
                        setLock(false);
                        handleClose();
                    }
                })
                .catch(err => {
                    setLock(false);
                    setErrors(err.data);
                    displayAlert(alerts.ERROR, `Algo ha fallado en la solicitud: ${err}`);
                })
        }
    }

    return (
        <Box
            sx={{ ml: { xs: 0.5, md: 0.5 } }}
        >
            <Button
                variant="contained"
                color="success"
                size='small'
                onClick={handleClickOpen}
            >
                + Documento
            </Button>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Añadir Documento
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={(theme) => ({
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme.palette.grey[500],
                    })}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Grid container>
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            width="100%"
                        >
                            <Box width="65%">
                                <TextField
                                    label={mediaPath ? "" : "Medio"}
                                    name="media-upload"
                                    value={mediaPath}
                                    disabled
                                    size='small'
                                    fullWidth
                                />
                            </Box>

                            <Button
                                variant="contained"
                                component="label"
                                sx={{ marginRight: "1rem", width: "25%" }}
                                disabled={lock}
                            >
                                Cargar medio
                                <input
                                    type="file"
                                    accept={getAcceptedMediaFormats()}
                                    hidden
                                    // ref={fileRef}
                                    onChange={handleSelection}
                                />
                            </Button>
                        </Box>
                        <Box width="100%">
                            {errors.length > 0 ?
                                <Box
                                    width="inherit"
                                    marginTop={2}
                                >
                                    {errors.map((error, index) => (
                                        <Typography key={index} color='error'>
                                            {error}
                                        </Typography>
                                    ))}
                                </Box>
                                :
                                ""
                            }
                        </Box>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        color='success'
                        variant='contained'
                        disabled={lock}
                        onClick={(e) => handleDocumentSubmit(e)}>
                        Guardar
                    </Button>
                    <Button
                        variant="outlined"
                        disabled={lock}
                        onClick={handleClose}>
                        Cancelar
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </Box>
    )
}

export default Media_New;