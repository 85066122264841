//import { React, Fragment } from 'react';
import React, { Fragment, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "../components/Actions/Loading";
import { NovaContext } from "../components/context";
import {
  checkAccess,
  noAccessMessage,
} from "../components/Helper/GlobalHelpers";
import { calculateRisk } from "../components/Helper/LoanHelpers";
import TableReport from "../components/TableReport";
import TableRiskWithCustomers from "../components/TableRiskWithCustomers";
import { styled } from "@mui/material/styles";

// date-fns
import { formatDistance, format } from 'date-fns';
import { es } from 'date-fns/locale';

// mui import
import { Box, Button, Grid2 as Grid, Paper, Typography } from "@mui/material";
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import Link from "@mui/material/Link";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

const Item = styled(Paper)(({ theme }) => ({
  // margin: theme.spacing(1),
  padding: theme.spacing(1.5),
  textAlign: "center",
  color: theme.palette.text.primary,
  boxShadow: "none",
  borderRadius: 20,
  height: "100%",
}));

const ItemTitel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: "1.5rem",
  fontWeight: "bold",
}));

const RiskIndicators = () => {
  const { actions, currentRole } = useContext(NovaContext);
  const hasManagerAccess = checkAccess(currentRole, "Gerente");
  const hasAdminAccess = checkAccess(currentRole, "Administrador");

  const [arrearCounters, setArrearCounters] = useState([]);
  const [hideRowDetails, setHideRowDetails] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loans, setLoans] = useState([]);
  const errors = [];
  let navigate = useNavigate();

  // number of payments
  const [numberPaymentFilter, setNumberPaymentFilter] = useState(5);

  const [tableData1, setTableData1] = useState([]);
  const contentPosition1 = {
    headerStyle: "text-center",
    firstColumnStyle: "TableFirstRow",
    // secondColumnStyle: "text-center",
    otherColumnsStyle: "text-center",
  };
  const contentPosition2 = {
    headerStyle: "text-center",
    firstColumnStyle: "text-left",
    otherColumnsStyle: "text-center",
  };
  const tableHeaders1 = ["Crítico", "Alarma", "Advertencia", "Total"];
  const tableHeaders2 = ["1 A 3", "4 A 6", "7 o MÁS", "TOTALES"];
  const tableData2 = [
    {
      rowData: [
        { val: arrearCounters.level1 },
        { val: arrearCounters.level2 },
        { val: arrearCounters.level3 },
        { val: arrearCounters.total },
      ],
    },
  ];
  const tableHeaders3 = [
    " ",
    "LUN",
    "MAR",
    "MIE",
    "JUE",
    "VIE",
    "SAB",
    "SEMANAL",
  ];
  const tableData3 = [
    {
      rowData: [
        { val: "6 A 13 HRS" },
        { val: "9" },
        { val: "3" },
        { val: "4" },
        { val: "5" },
        { val: "2" },
        { val: "3" },
        { val: "26" },
      ],
    },
    {
      rowData: [
        { val: "13 A 16 HRS" },
        { val: "7" },
        { val: "5" },
        { val: "2" },
        { val: "3" },
        { val: "1" },
        { val: "1" },
        { val: "19" },
      ],
    },
    {
      rowData: [
        { val: "16 HRS en Ad.." },
        { val: "9" },
        { val: "3" },
        { val: "4" },
        { val: "5" },
        { val: "2" },
        { val: "3" },
        { val: "26" },
      ],
    },
  ];
  const tableTotals3 = [
    { value: "25" },
    { value: "11" },
    { value: "10" },
    { value: "13" },
    { value: "5" },
    { value: "7" },
    { value: "71" },
  ];
  const tableHeaders4 = [
    " ",
    "LUN",
    "MAR",
    "MIE",
    "JUE",
    "VIE",
    "SAB",
    "SEMANAL",
    "%",
  ];
  const tableData4 = [
    {
      rowData: [
        { val: "6 A 13 HRS" },
        { val: "1" },
        { val: "1" },
        { val: "0" },
        { val: "0" },
        { val: "0" },
        { val: "6" },
        { val: "8" },
        { val: "36.36%" },
      ],
    },
    {
      rowData: [
        { val: "13 A 16 HRS" },
        { val: "2" },
        { val: "0" },
        { val: "1" },
        { val: "0" },
        { val: "1" },
        { val: "1" },
        { val: "5" },
        { val: "29.41%" },
      ],
    },
    {
      rowData: [
        { val: "16 HRS en Ad.." },
        { val: "2" },
        { val: "1" },
        { val: "3" },
        { val: "4" },
        { val: "5" },
        { val: "0" },
        { val: "15" },
        { val: "60.00%" },
      ],
    },
  ];
  const tableTotals4 = [
    { value: "5" },
    { value: "2" },
    { value: "4" },
    { value: "4" },
    { value: "6" },
    { value: "7" },
    { value: "28" },
    { value: "43.75%" },
  ];

  const handleNumberPaymentSelection = (event) => {
    setNumberPaymentFilter(event.target.value);
  }

  useEffect(() => {
    if (hasManagerAccess) {
      setLoading(true);
      const fetchData = async () => {
        if (arrearCounters.length === 0) {
          await actions
            .countArrears()
            .then((resp) => {
              setArrearCounters(resp);
            })
            .catch((err) => errors.push(err));
        }

        if (loans.length === 0) {
          await actions
            .getLoansOpen()
            .then((resp) => {
              console.log("risk: ", resp);
              const respPrepared = resp.map((item) => {
                item.fullName =
                  item.customer_idnumber +
                  " - " +
                  item.customer_name +
                  " " +
                  item.customer_lastname;
              });
              setLoans(resp);
              calculateRiskTable(resp);
            })
            .catch((err) => errors.push(err));
        } else {
          calculateRiskTable(loans);
        }

        // get payment list


        setLoading(false);
      };

      fetchData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hideRowDetails]);

  const calculateRiskTable = (loanList) => {
    var riskLevels = { warning: 0, danger: 0, critical: 0, total: 0 };
    var riskCustomers = { warning: [], danger: [], critical: [] };
    var customerObj = {};

    loanList.forEach(async (loan) => {
      const singleRisk = calculateRisk(loan);
      const CustomerID = loan.loan_customerid;
      const CustomerName = loan.fullName;
      customerObj = { Id: CustomerID, Name: CustomerName };
      if (singleRisk > 25) {
        if (singleRisk > 50) {
          if (singleRisk > 75) {
            riskLevels.critical++;
            riskCustomers.critical.push(customerObj);
          } else {
            riskLevels.danger++;
            riskCustomers.danger.push(customerObj);
          }
        } else {
          riskLevels.warning++;
          riskCustomers.warning.push(customerObj);
        }
        riskLevels.total++;
      }
    });
    const tableData = [
      {
        rowData: [
          { val: riskLevels.critical },
          { val: riskLevels.danger },
          { val: riskLevels.warning },
          { val: riskLevels.total },
        ],
      },
      {
        rowData: [
          { val: riskCustomers.critical },
          { val: riskCustomers.danger },
          { val: riskCustomers.warning },
          { val: "" },
        ],
        rowHide: hideRowDetails,
      },
    ];
    setTableData1(tableData);
  };

  const calculateRiskForOldList = (loanList) => {
    console.log("Inicial:", loanList);
    loanList.forEach(async (loan) => {
      const singleRisk = calculateRisk(loan);
      loan.risk = singleRisk; // -> only if we need to store this values
    });
    console.log("Final:", loanList);
  };

  const handleDetailsTable1 = (info) => {
    setHideRowDetails(!hideRowDetails);
    setTableData1(tableData1);
  };

  const triggerRiskHistorial = () => {
    actions
      .getLoansClosed()
      .then((resp) => {
        calculateRiskForOldList(resp);
      })
      .catch((err) => errors.push(err));
  };

  const redirectToCustomer = (customerId) => {
    const path = `/Customers/${customerId}`;
    navigate(path);
  };

  return (
    <Box sx={{ padding: { xs: "10px", md: " 25px" } }}>
      <div
        className={`over-page-loading ${loading ? "show-loading" : "hide-loading"
          }`}
      >
        <div className="over-page-loading-content">
          <Loading
            text={"Cargando ..."}
            classNames={`over-page-loading ${loading ? "show-loading" : "hide-loading"
              }`}
          />
        </div>
      </div>

      {loading ? (
        <div>Cargando...</div>
      ) : (
        <Grid container spacing={2}>
          <Grid size={12}>
            <Typography
              variant="h3"
              component="h3"
              display="flex"
              justifyContent="center"
              sx={{ textAlign: "center", mt: 1, mb: 2 }}
            >
              Indicadores de Riesgo
            </Typography>
          </Grid>

          <Grid size={{ xs: 12, md: 6 }}>
            <Item>
              <ItemTitel>Indicador Semaforo de Riesgo</ItemTitel>
              <TableRiskWithCustomers
                hideColumn={false}
                dataList={tableData1}
                headersList={tableHeaders1}
                contentPosition={contentPosition1}
                clickAction={redirectToCustomer}
              // totalList={}
              />
              <Button
                variant="outlined"
                onClick={() => handleDetailsTable1(true)}
              >
                {`${hideRowDetails ? "ver " : "ocultar "} detalles`}
              </Button>
              {/* {currentRole === "Root" ? (
                          <button
                            className="btn btn-outline-danger"
                            onClick={() => triggerRiskHistorial()}
                          >
                            {`Historial de riesgos`}
                          </button>
                        ) : null} */}
            </Item>
          </Grid>

          <Grid size={{ xs: 12, md: 6 }}>
            <Item>
              <ItemTitel>Indicador Adeudos</ItemTitel>
              <TableReport
                hideColumn={false}
                dataList={tableData2}
                headersList={tableHeaders2}
                contentPosition={contentPosition1}
              // totalList={}
              />
            </Item>
          </Grid>
          {hasAdminAccess ? (
            <Grid size={{ xs: 12, md: 6 }}>
              <Item>
                <ItemTitel>Indicador "Pagos Totales" (WIP)</ItemTitel>
                <TableReport
                  hideColumn={false}
                  dataList={tableData3}
                  headersList={tableHeaders3}
                  contentPosition={contentPosition2}
                  totalList={tableTotals3}
                />
              </Item>
            </Grid>
          ) : null}

          {hasAdminAccess ? (
            <Grid size={{ xs: 12, md: 6 }}>
              <Item>
                <ItemTitel>Indicador "Pagos Refrendo" (WIP)</ItemTitel>
                <TableReport
                  hideColumn={false}
                  dataList={tableData4}
                  headersList={tableHeaders4}
                  contentPosition={contentPosition2}
                  totalList={tableTotals4}
                />
              </Item>
            </Grid>
          ) : null}
          <Grid size={{ xs: 12, md: 6 }}>
            <Item>
              <ItemTitel>Indicador número de pagos</ItemTitel>
              <FormControl size="small" sx={{ float: "right", width: "20%" }}>
                <InputLabel id="demo-simple-select-label">Numero de pagos</InputLabel>
                <Select
                  value={numberPaymentFilter}
                  label="Pagos"
                  onChange={handleNumberPaymentSelection}
                >
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={15}>15</MenuItem>
                </Select>
              </FormControl>
              {hasAdminAccess ? (
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Cliente</TableCell>
                      <TableCell>Préstamo comenzado</TableCell>
                      <TableCell>Risgo</TableCell>
                      <TableCell>Capital incial</TableCell>
                      <TableCell>Moras</TableCell>
                      <TableCell>Número de pagos</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      loans?.
                        filter(loan => loan.payments >= numberPaymentFilter)
                        .sort(function (a, b) {
                          return b.payments - a.payments;
                        })
                        .map(loan => (
                          <TableRow>
                            <TableCell>
                              <Link
                                component="button"
                                variant="body2"
                                className='TableElementLongText'
                                onClick={() => { redirectToCustomer(loan.loan_customerid) }}
                              >
                                {loan.customer_fullname}
                              </Link>
                            </TableCell>
                            <TableCell>{format(loan.loan_dateissue, 'dd-MM-yyyy')} ({formatDistance(loan.loan_dateissue, new Date(), { locale: es })})</TableCell>
                            <TableCell>{loan.risk}</TableCell>
                            <TableCell>${loan.loan_amount}</TableCell>
                            <TableCell>${loan.arrears}</TableCell>
                            <TableCell>{loan.payments}</TableCell>
                          </TableRow>
                        ))
                    }
                  </TableBody>
                </Table>
              ) : null
              }
            </Item>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default RiskIndicators;
