import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// internal misc
import {
  CustomDataGrid,
  CustomGridToolbar,
} from "../../../components/Helper/MUIStyledComponents";
import { alerts, displayAlert } from "../../../components/Helper/AlertToastify";
import { useLoansContext } from "../../../components/context/LoansContext";
import { getPayments } from "../../../utils/api/api_payments";
import { deleteLoan, writeOffLoan } from "../../../utils/api/api_loan";
import { LoanActions } from "../../../reducers/LoansReducer";

// components
import DateRange from "../../../components/common/DateRange/DateRange";
import { DateRangeActions } from "../../../components/common/DateRange/DateRangeReducer";
import LoanDetailsDrawer from "../../LoanOverview/LoanDetailsDrawerV2";
import Confirmation from "../../../components/common/Confirmation";

// helper
import {
  // getLoanVisibleColumns,
  prepareLoanColumns,
} from "../../../components/Helper/DataGrid/ColumnDefinitions/Columns_Loans";

// mui
import Box from "@mui/material/Box";
import { renderNoRowDataOverlay } from "../../../components/Helper/DataGrid/Overlays/RenderNoRowDataOverlay";
import CustomLoadingOverlay from "../../../components/Helper/DataGrid/Overlays/CustomLoadingOverlay";
import Payment_New from "../../Modal/Payment_New";
import Loan_PostponeDueDate from "../../Modal/Loan_EditDueDate";

// for manual refresh of token -> remove when no longer needed
import { Button } from "@mui/material";
import axios from "axios";
import Cookies from 'js-cookie'
import { checkAccess } from "../../../components/Helper/GlobalHelpers";

const LoansList = ({
  columnVisibilityModel,
  boxHeight = "85vh",
  hideDateRange = false,
}) => {
  const [selectedLoan, setSelectedLoan] = useState();
  const {
    filial,
    currentRole,
    loading,
    loansList,
    dateRange,
    dispatchDateRange,
    dispatchLoanList,
    dispatchLoading,
  } = useLoansContext();
  const navigate = useNavigate();

  // console.log('LOANS',loansList)

  // modal states
  const [confirmationRemoveLoan, setConfirmationRemoveLoan] = useState(false);
  const [confirmationWriteOff, setConfirmationWriteOff] = useState(false);
  const [dialogPayment, setDialogPayment] = useState(false);
  const [openPostponeDialog, setOpenPostponeDialog] = useState(false);
  const hasAdminAccess = checkAccess(currentRole, "Administrador");

  // what does filter model do?
  const [filterModel, setFilterModel] = React.useState({
    items: [],
    quickFilterExcludeHiddenColumns: true,
    quickFilterValues: [""],
  });

  const changeDateRange = (updatedDates) => {
    dispatchDateRange({
      type: DateRangeActions.SET_DATE_RANGE_FILTER,
      payload: updatedDates,
    });
  };

  const handleRemoveLoan = async () => {
    await deleteLoan(selectedLoan?.loan_id)
      .then((resp) => {
        if (resp.status === 200) {
          displayAlert(alerts.SUCCESS, "Préstamo eliminado");
          setConfirmationRemoveLoan(false);
          setSelectedLoan();
          dispatchLoanList({
            type: LoanActions.REMOVE_LOAN,
            payload: selectedLoan?.loan_id,
          });
        } else {
          displayAlert(alerts.ERROR, `${resp.data[0]}`);
        }
      })
      .catch((err) =>
        displayAlert(
          alerts.ERROR,
          `No se ha podido eliminar el préstamo: ${err.data}`
        )
      );
  };

  // const handleRefresh = () => {
  //   navigate(0); // Navigates to the current route
  // };

  const handleWriteOff = async () => {
    console.log("disparando la limpia", selectedLoan);

    await writeOffLoan(selectedLoan?.loan_id, filial)
      .then((resp) => {
        if (resp.status === 200) {
          displayAlert(alerts.SUCCESS, "Préstamo limpiado");
          setConfirmationWriteOff(false);
          setSelectedLoan();
          dispatchLoanList({
            type: LoanActions.UPDATE_LOAN,
            payload: selectedLoan?.loan_id,
          });
          navigate(0);
        } else {
          displayAlert(alerts.ERROR, `${resp.data[0]}`);
        }
      })
      .catch((err) =>
        displayAlert(
          alerts.ERROR,
          `No se ha podido eliminar el préstamo: ${err.data}`
        )
      );
  };

  const handleNavigation = (newPath) => {
    const path = `/${newPath}`;
    navigate(path);
  };

  // drawer data
  const [drawer, setDrawer] = useState(false);
  const [payments, setPayments] = useState([]);

  const handleClickDrawerLoanDetails = async (loan) => {
    await getPayments(`/loan/${loan.loan_id}`)
      .then((resp) => {
        setSelectedLoan(loan);
        setPayments(resp);
        setDrawer(true);
      })
      .catch((err) =>
        displayAlert(
          alerts.ERROR,
          `No se han podido recuperar los pagos: ${err}`
        )
      );
  };

  // toggle for payment Dialog
  const handleClickPayment = (loan) => {
    setSelectedLoan(loan);
    setDialogPayment(true);
  };

  const component1Content = hideDateRange ? null : (
    <DateRange dateObject={dateRange} changeMethod={changeDateRange} />
  );

  const visibilityModel = columnVisibilityModel(currentRole);

  return (
    <div>
      {loading ? (
        "Cargando..."
      ) : (
        <>
          <Box height={boxHeight} width="100%">
            <CustomDataGrid
              columns={prepareLoanColumns(
                loansList,
                setConfirmationRemoveLoan,
                setConfirmationWriteOff,
                setSelectedLoan,
                setOpenPostponeDialog,
                handleNavigation,
                handleClickDrawerLoanDetails,
                handleClickPayment,
                currentRole
              )}
              rows={loansList}
              getRowId={(row) => row.loan_id}
              slots={{
                toolbar: CustomGridToolbar,
                oadingOverlay: CustomLoadingOverlay,
                noRowsOverlay: () =>
                  renderNoRowDataOverlay("No hay créditos en este periodo"),
              }}
              slotProps={{
                toolbar: {
                  component1: component1Content,
                  component2:
                    (
                      hasAdminAccess ? (
                        <Button
                          variant="contained"
                          onClick={async () => {
                            console.log("I am being clicked! ")
                            await axios.post('http://localhost:5000/auth/refreshtoken', null, { headers: { Authorization: `Bearer ${Cookies.get('nn-client-refresh')}` } })
                              .then(response => {
                                Cookies.set("nn-client-access", response.data.accessToken);
                                Cookies.set("nn-client-refresh", response.data.refreshToken);
                              })
                          }}>
                          Manual refresh of token
                        </Button>
                      ) : null
                    )
                },
              }}
              onFilterModelChange={(newModel) => setFilterModel(newModel)}
              filterModel={filterModel}
              initialState={{
                columns: {
                  columnVisibilityModel: visibilityModel,
                },
                filter: {
                  filterModel: filterModel,
                },
              }}
            />
          </Box>
          <LoanDetailsDrawer
            open={drawer}
            toggleDrawer={setDrawer}
            loan={selectedLoan}
            payments={payments}
          />
          <Loan_PostponeDueDate
            open={openPostponeDialog}
            toggleDialog={setOpenPostponeDialog}
            loan={selectedLoan}
          />
          <Confirmation
            open={confirmationRemoveLoan}
            setOpen={setConfirmationRemoveLoan}
            textContent={"Estás seguro de que quieres ELIMINAR este préstamo?"}
            confirmationMethod={handleRemoveLoan}
          />
          <Confirmation
            open={confirmationWriteOff}
            setOpen={setConfirmationWriteOff}
            textContent={"Estás seguro de que quieres LIMPIAR este préstamo?"}
            confirmationMethod={handleWriteOff}
          />
          <Payment_New
            open={dialogPayment}
            toggleDialog={setDialogPayment}
            loan={selectedLoan}
            lockStatus={loading}
          // setLockStatus={setLoading}
          />
        </>
      )}
    </div>
  );
};

export default LoansList;
