import React from "react";

import Grid from "@mui/material/Grid2";
import { ItemPaper } from "../components/Helper/MUIStyledComponents";
import AccessDeniedGIF from "../images/StopGiphy.gif"; // add a GIF or illustration

const NoAccessComponent = () => {
  return (
    <Grid
      container
      sx={{
        height: "95vh", // take up the whole page vertically
        width: "100%",
        padding: { xs: "10px", md: "25px" },
        display: "flex",
        justifyContent: "center",
        alignItems: "center", // center the content vertically
      }}
    >
      <ItemPaper sx={{ width: "100%", margin: "auto", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"  }}>
        <Grid
          size={12}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <h1
            style={{
                fontSize: "42px",
              fontWeight: "bold",
              color: (theme) => theme.palette.primary.main,
            }}
          >
            Accesso denegado
          </h1>
        </Grid>
        <Grid size={12}>
          <img
            src={AccessDeniedGIF}
            alt="Access denied"
            style={{ width: "250px", height: "250px" }}
          />
        </Grid>
        <Grid size={12}>
          <h3
            style={{
              color: (theme) => theme.palette.text.secondary,
            }}
          >
            Lo sentimos, pero no tienes los permisos necesarios para esta
            página.
          </h3>
          <h3
            style={{
              color: (theme) => theme.palette.text.secondary,
            }}
          >
            Si crees que esto es un error, por favor contacta a un
            administrador.
          </h3>
        </Grid>
      </ItemPaper>
    </Grid>
  );
};
export default NoAccessComponent;
