import React, { useState, useContext } from "react";

// mui
import Box from "@mui/material/Box";
import { Grid2 as Grid } from "@mui/material";

// internal
import { NovaContext } from "../../components/context";
import {
  CustomDataGrid,
  CustomGridToolbar,
} from "../../components/Helper/MUIStyledComponents";
import { prepareDocumentColumns } from "../../components/Helper/DataGrid/ColumnDefinitions/Columns_Documents";
import NewDocument from "../Modal/Document_New";
import CustomLoadingOverlay from "../../components/Helper/DataGrid/Overlays/CustomLoadingOverlay";
import { renderNoRowDataOverlay } from "../../components/Helper/DataGrid/Overlays/RenderNoRowDataOverlay";
import Confirmation from "../../components/common/Confirmation";
import { uploadDestination } from "../../components/Helper/MediaHelpers";
import { checkMinAccess } from "../../components/Helper/GlobalHelpers";
import Image_NewEdit from "../Modal/Image_NewEdit";
import Media_New from "../Modal/Media_New";

const FilialDocuments = ({ documentList, updateDocumentList }) => {
  const { filials, currentRole, filial } = useContext(NovaContext);
  const [selectedDocument, setSelectedDocument] = useState("");
  const [confirmation, setConfirmation] = useState(false);
  const hasMinRegionalManagerAccess = checkMinAccess(
    filials,
    "Gerente Regional"
  );
  const hasMinAuditorAccess = checkMinAccess(filials, "Auditor");
  // console.log("regional: ", hasMinRegionalManagerAccess)
  // console.log("auditor: ", hasMinAuditorAccess)
  // 31.10.24 - question regarding access rights to Demo-El Rancho as employee

  // what does filter model do?
  const [filterModel, setFilterModel] = React.useState({
    items: [],
    quickFilterExcludeHiddenColumns: true,
    quickFilterValues: [""],
  });

  const handleUploadMedia = (newMedia) => {
    updateDocumentList(newMedia, "ADD");
    setConfirmation(false);
    setSelectedDocument("");
  };

  const handleRemoveMedia = () => {
    updateDocumentList(selectedDocument, "REMOVE");
    setConfirmation(false);
    setSelectedDocument("");
  };

  return (
    <Grid container size={12}>
      <Confirmation
        open={confirmation}
        setOpen={setConfirmation}
        textContent={"Está seguro de que quieres revocar este documento?"}
        confirmationMethod={handleRemoveMedia}
      />
      <Box width="100%" height="500px">
        <CustomDataGrid
          columns={prepareDocumentColumns(
            setSelectedDocument,
            setConfirmation,
            currentRole
          )}
          rows={documentList}
          getRowId={(row) => row.media_id}
          slots={{
            toolbar: CustomGridToolbar,
            loadingOverlay: CustomLoadingOverlay,
            noRowsOverlay: () =>
              renderNoRowDataOverlay("No hay documentos para este sucursal"),
          }}
          slotProps={{
            toolbar: {
              component2:
                hasMinRegionalManagerAccess || hasMinAuditorAccess ? (
                  <Media_New
                    objectUuid={filial}
                    afterAddFunction={handleUploadMedia}
                    destination={uploadDestination.FILIAL}
                  />
                ) : (
                  ""
                ),
            },
          }}
          onFilterModelChange={(newModel) => setFilterModel(newModel)}
          filterModel={filterModel}
          initialState={{
            columns: {
            //   columnVisibilityModel: visibilityModel,
            },
            filter: {
              filterModel: filterModel,
            },
          }}
        />
      </Box>
    </Grid>
  );
};

export default FilialDocuments;
