import { Box, IconButton, Tooltip } from "@mui/material";
import TooltipIconButton from "../../../controls/TooltipIconButton";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

export const prepareLoanIncrementColumns = (
//   loanIncrements,
  setSelectedLoanIncrement,
  setLoanIncrementModal
) => {
  const loanIncrementColumns = [
    {
      field: "value",
      headerName: "Valor de Crédito",
      editable: false,
      flex: 1,
    },
    {
      field: "renewals",
      headerName: "# de Renovaciones",
      editable: false,
      flex: 1,
    },
    {
      field: "actions",
      type: "actions",
      width: 100,
      // renderCell: (params) => [
      //   <Box display="flex" alignItems={"center"}>
      //     <ActionOpenNewEdit
      //       params={params}
      //       setItemForEdit={setItemForEdit}
      //       setOpenForm={setOpenForm}
      //       icon={<EditOutlinedIcon />}
      //     />
      //     <ActionChanges params={params} rowId={rowId} setRowId={setRowId} />,
      //   </Box>,
      // ],
      renderCell: (params) => {
        const viewLoanIncrementDialog = (e) => {
          e.stopPropagation();
          setSelectedLoanIncrement(params?.row);
          setLoanIncrementModal(true);
        };
        return (
          <Box display="flex" alignItems={"center"}>
            <TooltipIconButton
              title="editar incremento"
              onClick={viewLoanIncrementDialog}
              //   disabled={isDisabled}
              data-target={`#id${params?.row.id}`}
              color="primary"
              size="small"
              variant="contained"
              icon={<EditOutlinedIcon fontSize="large" />}
            />
          </Box>
        );
      },
    },
  ];
  return loanIncrementColumns;
};
