import React, { useContext, useState } from 'react';

// Internal import
import { NovaContext } from '../context';
import { alerts, displayAlert } from '../Helper/AlertToastify';
import { addCashflow } from '../../utils/api/api_cashflow';

// Helper import
import { cashFlowOptions } from "../Helper/Enums";
import { getTimeDifferenceInHours } from '../Helper/GlobalHelpers';

// Date fns import
import addHours from 'date-fns/addHours';
import formatISO from 'date-fns/formatISO';

// MUI import
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import IconButton from '@mui/material/IconButton';
import CloseIcon from "@mui/icons-material/Close";

const NewCashFlow = (props) => {

  const {
    open,
    setOpen,
    updateCashflowList,
    showButton = false
  } = props;

  // form controls
  const [locked, setLocked] = useState(false);
  const [errors, setErrors] = useState([]);
  const { actions, filial, authenticatedUser } = useContext(NovaContext);

  const handleClose = () => {
    setCashFlow(defaultValues);
    setOpen(false);
  };

  const checkForErrors = (fieldId) => {
    const nameError = errors.find((error) => error.label === fieldId);
    return nameError !== undefined ? nameError.message : "";
  };


  // form contents
  const defaultValues = {
    cashflowtype: cashFlowOptions[0].value,
    amount: 0,
    description: ""
  }

  const [cashFlow, setCashFlow] = useState(defaultValues);


  // form methods
  const handleInputchange = (e) => {
    const { name, value } = e.target;
    setCashFlow({
      ...cashFlow,
      [name]: value
    });
  }

  const validate = () => {
    let validateStatus = true;
    if (cashFlow.amount < 1) {
      setErrors((arr) => [
        ...arr,
        { label: "amount", message: "El valor debe ser superior o igual a 1" }
      ]);
      validateStatus = false;
    };
    if (cashFlow.description === 'undefined'
      || cashFlow.description === 'null'
      || cashFlow.description.length === 0) {
      setErrors((arr) => [
        ...arr,
        { label: "description", message: "La descripción es obligatoria" }
      ]);
      validateStatus = false;
    };
    return validateStatus;
  }

  // form submit
  const handleCreate = async (e) => {
    e.preventDefault();
    setErrors([]);
    if (!locked) {
      setLocked(true);
      const validateCheck = validate();
      if (validateCheck) {
        const currentDate = addHours(new Date(), +getTimeDifferenceInHours());
        const newAmount = cashFlow.cashflowtype === '1' ? cashFlow.amount : (cashFlow.amount * (-1));
        const tempCashFlow =
        {
          "amount": newAmount,
          "description": cashFlow.description,
          "createdate": formatISO(currentDate),
          "employeeId": authenticatedUser.employee_id,
          "filialId": filial
        }
        if (showButton) {
          addCashflow(tempCashFlow)
            .then(resp => { 
              displayAlert(alerts.SUCCESS, "Transacción registrada con éxito");
              updateCashflowList(resp);
              setLocked(false);
              handleClose();
            })
            .catch(err => {
              displayAlert(alerts.ERROR, "Algo ha fallado en la solicitud");
              console.log("Error trace: ", err);
              setLocked(false);
            })

        } else {
          await actions.addCashFlow(tempCashFlow)
            .then(resp => {
              displayAlert(alerts.SUCCESS, "Transacción registrada con éxito");
              setLocked(false);
              handleClose();
            })
            .catch(err => {
              displayAlert(alerts.ERROR, "Algo ha fallado en la solicitud");
              console.log("Error trace: ", err);
              setLocked(false);
            });
        }
      }
      setLocked(false);
    }
  }

  return (
    <React.Fragment>
      {showButton === true ? (
        <Button variant="contained" color="success" size="small" onClick={setOpen}>
          + transacción
        </Button>
      ) : (
        ""
      )}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <Grid container alignItems={"center"}>
            <Grid size={10} align="left">
              <Typography variant="h5" align="left">
                <Box sx={{ fontWeight: "bold" }}>Agregar transacción de caja</Box>
              </Typography>
            </Grid>
            <Grid size={2} align="right">
              <IconButton onClick={() => handleClose()}>
                <CloseIcon fontSize="medium" color="primary" />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent dividers>

          <TextField
            name='cashflowtype'
            label="Tipo de movimiento"
            select
            required
            margin='normal'
            onChange={handleInputchange}
            fullWidth
            slotProps={{ shrink: true }}
            value={cashFlow.value}
            defaultValue={cashFlowOptions[0].value}
          >
            {cashFlowOptions.map((cfo, index) => (
              <MenuItem
                key={index}
                value={cfo.value}
              >
                {cfo.label}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            name='amount'
            label='Cantidad en efectivo'
            type='number'
            variant='outlined'
            fullWidth
            autoFocus
            onChange={handleInputchange}
            required
            slots={{ startAdornment: <InputAdornment position="start" sx={{ paddingRight: "5px" }}>$</InputAdornment> }}
            disabled={locked}
            error={checkForErrors("amount") ? true : false}
            helperText={checkForErrors("amount")}
            margin='normal'
          />

          <TextField
            name='description'
            label="Descripción del movimiento"
            type="text"
            fullWidth
            onChange={handleInputchange}
            required
            multiline
            rows={5}
            margin='normal'
            slotProps={{ shrink: true }}
            disabled={locked}
            error={checkForErrors("description") ? true : false}
            helperText={checkForErrors("description")}
          />

        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCreate}
            variant='contained'
            color='success'
            disabled={locked}
          >
            Enviar
          </Button>

          <Button
            onClick={handleClose}
            variant='outlined'
            color='primary'
            disabled={locked}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );

}

export default NewCashFlow;