import { createTheme, ThemeProvider } from "@mui/material";
import { createContext, useContext, useMemo, useState } from "react";
import { NovaContext } from "../context";

export const MUIWrapperContext = createContext({
  toggleColorMode: () => {},
});

export default function MUIWrapper({ children }) {
  const { actions, themeMode } = useContext(NovaContext);

  const [mode, setMode] = useState(themeMode ? themeMode : "light");
  const muiWrapperUtils = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
        actions.changeThemeState(mode === "light" ? "dark" : "light");
      },
    }),
    []
  );

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          ...(mode === "light"
            ? {
                // palette values for light mode
                primary: {
                  main: "#1976d2",
                },
                secondary: {
                  main: "#e30d7d",
                },
                background: {
                  default: "#c6c6c67d",
                  paper: "#fff",
                },
              }
            : {
                // palette values for dark mode
                primary: {
                  main: "#1976d2",
                },
                background: {
                  // default: "#AAAA",
                  // paper: "#c6c6c6",
                },
              }),
        },
        components: {
          MuiFormControl: {
            styleOverrides: {
              root: {
                // // Optionally increase the font size or other styles globally for the FormControl
                "&.MuiOutlinedInput-root": {
                  fontSize: "1.1rem", // Adjusts input font size
                },
              },
            },
          },
          MuiFormLabel: {
            styleOverrides: {
              root: {
                // // Optionally increase the font size or other styles globally for the FormControl
                "&.MuiOutlinedInput-root": {
                  fontSize: "1.3rem", // Adjusts input font size
                },
              },
            },
          },
          // Customizing the MuiInputLabel (to affect the label size)
          MuiInputLabel: {
            styleOverrides: {
              root: {
                ...(mode === "light" && {
                  color: "#333", // Darker color for labels in light mode
                }),
                fontSize: "1.2rem", // Adjusts label font size
                // fontWeight: "bold", // You can adjust other styles like font weight
              },
              outlined: {
                padding: "0 4px", // Adjust the label's padding for the outlined variant
              },
            },
          },
          // Customizing the MuiInputBase (for text input size)
          MuiInputBase: {
            styleOverrides: {
              input: {
                fontSize: "1.4rem", // Adjust the font size for the input text
              },
            },
          },
          // Customizing the MuiFormHelperText (for helper text size)
          MuiFormHelperText: {
            styleOverrides: {
              root: {
                fontSize: "1.2rem", // Adjust the font size for helper text
              },
            },
          },
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "1.2rem", // Set the font size for the tooltip text
              },
            },
          },
          MuiMenuItem: {
            styleOverrides: {
              root: {
                py: 0, // adjust vertical padding
                px: 0, // adjust horizontal padding
              },
            },
          },
          MuiMenu: {
            styleOverrides: {
              list: {
                padding: 0, // or adjust to a smaller value, e.g. 2
              },
            },
          },
          MuiList: {
            styleOverrides: {
              root: {
                padding: 0, // or adjust to a smaller value, e.g. 2
              },
            },
          },
        },
      }),
    [mode]
  );

  return (
    <MUIWrapperContext.Provider value={muiWrapperUtils}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </MUIWrapperContext.Provider>
  );
}
