import React, { Fragment, useState } from "react";

const EditFilial = ({ filial }) => {

    const [description, setDescription] = useState(filial.description);

    //edit filial function
    const updateDescription = async e => {
        e.preventDefault();
    };

    return <Fragment>
                <button type="button" 
                        className="btn btn-primary" 
                        data-toggle="modal" 
                        data-target={`#id${filial.filial_id}`}>
                    Edit
                </button>

                <div className="modal" 
                    id={`id${filial.id}`} 
                    onClick={() => setDescription(filial.description)}
                >
                    <div className="modal-dialog">
                        <div className="modal-content">

                            <div className="modal-header">
                                <h4 className="modal-title">Edit {filial.title}</h4>
                                <button type="button" 
                                        className="close" 
                                        data-dismiss="modal" 
                                        onClick={() => setDescription(filial.description)}>&times;
                                </button>
                            </div>

                            <div className="modal-body">
                                <input  type="text" 
                                        className="form-control" 
                                        value={description} 
                                        onChange={e => setDescription(e.target.value)} 
                                />
                            </div>

                            <div className="modal-footer">
                                <button type="button" 
                                        className="btn btn-success" 
                                        data-dismiss="modal" 
                                        onClick={e => updateDescription(e)}
                                >
                                            Continuar
                                </button>
                                <button type="button" 
                                        className="btn btn-default" 
                                        data-dismiss="modal"
                                        onClick={() => setDescription(filial.description)}
                                >
                                            Cancelar
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
            </Fragment>
};

export default EditFilial;