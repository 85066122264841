import React, { Fragment, useContext } from "react";

// Date-fns
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";

// Material UI import
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { styled, Grid2 as Grid, IconButton } from "@mui/material";
import { createStyles } from "@mui/styles";

import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
// import { ItemPaper } from "../../components/Helper/MUIStyledComponents";
import {
  calculateOpenAmountTotal,
  calculateOpenArrear,
  calculateOpenInterest,
  RiskIcon,
  loanPercentagePaid,
  RiskWithLabel,
} from "../../components/Helper/LoanHelpers";
import ProgressBar from "../../components/ProgressBar";
import { checkAccess } from "../../components/Helper/GlobalHelpers";
import { NovaContext } from "../../components/context";

export const ItemPaper = styled(Paper)(({ theme }) => ({
  //   margin: theme.spacing(1),
  // padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.primary,
  boxShadow: "1",
  borderRadius: 20,
  // height: "100%",
}));

const drawerWidth = 500;
const drawerWidthMobile = "100%";
const drawerHeight = "calc(100% - 70px)";
const drawerHeightMobile = "100%";

const styleSheet = createStyles({
  paper: {
    height: "calc(100% - 64px)",
    top: 64,
  },
});

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 11,
  },
}));

const calculatePaymentsSummary = (payments = []) => {
  // console.log("Pays:", payments);
  if (payments instanceof Promise) {
    return {
      numberOfPayments: 0,
      totalAmountPaid: 0,
    };
  }

  const numberOfPayments = payments.length;
  let totalAmountPaid = 0;
  payments.forEach((payment) => {
    totalAmountPaid += payment.payment_total;
  });

  return {
    numberOfPayments,
    totalAmountPaid,
  };
};

const LoanDetailsDrawer = (props) => {
  const { open, toggleDrawer, loan, payments = [] } = props;
  const { filials, currentRole } = useContext(NovaContext);

  // const paper = styleSheet.paper;
  // const hasMinAdminAccess = checkMinAccess(filials, "Administrador");
  const hasAdminAccess = checkAccess(currentRole, "Administrador");
  const summary = calculatePaymentsSummary(payments);

  const handleClose = () => {
    toggleDrawer(false);
  };

  return (
    <Fragment>
      {loan ? (
        <ClickAwayListener onClickAway={handleClose}>
          <Drawer
            open={open}
            anchor="right"
            variant="persistent"
            PaperProps={{
              sx: {
                width: { xs: drawerWidthMobile, md: drawerWidth },
                height: { xs: drawerHeightMobile, md: drawerHeight },
                margin: { xs: "0", md: "70px 0 0 0" },
                bgcolor: "#ebedf3",
              },
            }}
          >
            <Box width="100%" role="presentation">
              <Grid container >
                <Grid size={12} >
                  <Grid container>
                    <Grid size={10} align="left">
                      {/* 
                      <Typography variant="h5" align="left" component="div">
                        <Box sx={{ fontWeight: "bold", m: 0.5 }}>
                          {/* ToDo (10.10.2024): probably change retrieval to merge names together in query 
                          {loan.fullName
                            ? `${loan.customer_idnumber} - ${loan.fullName}`
                            : `${loan.customer_idnumber} - ${loan.customer_name} ${loan.customer_lastname}`}
                        </Box>
                      </Typography> 
                      */}
                      <Typography variant="h6" align="left" component="div">
                        <Box sx={{ m: 1 }}>{loan.loan_id}</Box>
                      </Typography>
                    </Grid>
                    <Grid size={2} align="right">
                      <IconButton margin={1} onClick={() => handleClose()}>
                        <CloseIcon fontSize="large" color="primary" />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid size={12} margin={0.75}>
                  <ItemPaper>
                    <Grid container padding={0.5}>
                      <Grid size={12} align="left">
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            mb: 1,
                          }}
                        >
                          <Typography
                            variant="h5"
                            align="left"
                            sx={{
                              textTransform: "uppercase",
                              fontWeight: "bold",
                            }}
                          >
                            {loan.customer_fullname} (ID:
                            {loan.customer_idnumber})
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid size={{ xs: 6, md: 4 }} sx={{ paddingRight: 1 }}>
                        <Typography variant="h6" align="left" component="div">
                          <Box sx={{ m: 0.2 }}>Capital Inicial</Box>
                        </Typography>
                        <Typography variant="h6" align="left" component="div">
                          <Box sx={{ m: 0.2 }}>Capital Pendiente</Box>
                        </Typography>
                        <Typography variant="h6" align="left" component="div">
                          <Box sx={{ m: 0.2 }}>Refrendo</Box>
                        </Typography>
                        <Typography variant="h6" align="left" component="div">
                          <Box sx={{ m: 0.2 }}>Total de Adeudo</Box>
                        </Typography>
                      </Grid>
                      <Grid size={{ xs: 6, md: 2 }}>
                        <Typography variant="h5" align="right" component="div">
                          <Box sx={{ m: 0.2 }}>${loan.loan_amount}</Box>
                        </Typography>
                        <Typography variant="h5" align="right" component="div">
                          <Box sx={{ m: 0.2 }}>${loan.loan_remaining}</Box>
                        </Typography>
                        <Typography variant="h5" align="right" component="div">
                          <Box sx={{ m: 0.2 }}>
                            ${calculateOpenInterest(loan)}
                          </Box>
                        </Typography>
                        <Typography variant="h5" align="right" component="div">
                          <Box sx={{ fontWeight: "bold", m: 0.2 }}>
                            ${calculateOpenAmountTotal(loan)}
                          </Box>
                        </Typography>
                      </Grid>
                      <Grid container size={{ xs: 12, md: 6 }}>
                        {/* <Grid container> */}
                        <Grid size={{ xs: 12 }}>
                          {/* <Typography
                          // variant="h6"
                          // align="center"
                          // component="div"
                        > */}
                          <ProgressBar percentage={loanPercentagePaid(loan)} />
                          {/* <Box>{loanPercentagePaid(loan)} pagado</Box> */}
                          {/* </Typography> */}
                        </Grid>

                        <Grid size={{ xs: 6 }}>
                          <Typography variant="h6" align="left" component="div">
                            <Box sx={{ m: 0.2 }}>Fecha de pago</Box>
                          </Typography>
                          <Typography variant="h6" align="left" component="div">
                            <Box sx={{ m: 0.2 }}>Mora</Box>
                          </Typography>
                          <Typography variant="h6" align="left" component="div">
                            <Box sx={{ m: 0.2 }}>Riesgo</Box>
                          </Typography>
                        </Grid>
                        <Grid size={{ xs: 6 }}>
                          <Typography
                            variant="h5"
                            align="right"
                            component="div"
                          >
                            <Box sx={{ fontWeight: "bold", m: 0.2 }}>
                              {format(
                                parseISO(loan.loan_datedue),
                                "dd/MM/yyyy "
                              )}{" "}
                            </Box>
                          </Typography>
                          <Typography
                            variant="h5"
                            align="right"
                            component="div"
                          >
                            <Box sx={{ m: 0.2 }}>
                              ${calculateOpenArrear(loan)}
                            </Box>
                          </Typography>
                          <Typography
                            variant="h5"
                            component="div"
                            display="flex"
                            justifyContent="flex-end"
                          >
                            <Box sx={{ m: 0.2 }}>{RiskWithLabel(loan)}</Box>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* </Grid> */}
                  </ItemPaper>
                </Grid>
                <Grid size={12}>
                  <Grid container>
                    <Grid size={6}>
                      <Typography variant="h5" align="left" component="div">
                        <Box sx={{ ml: 1 }}>Historial de pagos</Box>
                      </Typography>
                    </Grid>
                    <Grid size={6}>
                      {hasAdminAccess ? (
                        <Typography variant="h6" align="right" component="div">
                          <Box sx={{}}>
                            ${summary?.totalAmountPaid} en{" "}
                            {summary?.numberOfPayments} pago(s)
                          </Box>
                        </Typography>
                      ) : null}
                    </Grid>
                    <Grid size={12}>
                      <TableContainer component={Paper}>
                        <Table
                          sx={{
                            maxWidth: {
                              xs: drawerWidthMobile,
                              md: drawerWidth,
                            },
                            '& .MuiTableCell-root': {
                              fontSize: '1rem', // Adjust font size for all table cells
                            },
                            '& .MuiTableHead-root .MuiTableCell-root': {
                              fontSize: '1.2rem', // Adjust font size for table headers
                              fontWeight: 'bold', // Optional: Make headers bold
                            },
                            '& .MuiTableBody-root .MuiTableCell-root': {
                              fontSize: '1rem', // Adjust font size for table body cells
                            },
                          }}
                          aria-label="payments-table"
                        >
                          <TableHead id="table-header">
                            <TableRow id="header-payments">
                              <TableCell>Total</TableCell>
                              <TableCell>Interés</TableCell>
                              <TableCell>Mora</TableCell>
                              <TableCell>Capital</TableCell>
                              <TableCell>Fecha</TableCell>
                              <TableCell>Empleado</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {payments &&
                              payments.length > 0 &&
                              payments.map((payment) => (
                                <StyledTableRow id={payment.payment_id}>
                                  <StyledTableCell>
                                    ${payment.payment_total}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    ${payment.payment_interest}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    ${payment.payment_delay}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    ${payment.payment_capital}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {format(
                                      parseISO(payment.payment_createdate),
                                      "dd/MM/yyyy"
                                    )}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {payment.employee_name}
                                  </StyledTableCell>
                                </StyledTableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Drawer>
        </ClickAwayListener>
      ) : null}
    </Fragment>
  );
};

export default LoanDetailsDrawer;
