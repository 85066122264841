import axios from 'axios';
import Cookies from "js-cookie";
import { ServerURI, ServerPORT } from '../../utils/config/uri';
import { AUTH } from '../../utils/config/routes';

const apiClient = axios.create({
    baseURL: `${ServerURI}:${ServerPORT}`
});

apiClient.interceptors.request.use(
    (config) => {
        const token = Cookies.get("nn-client-access");
        if (token) {
            config.headers.Authorization = `Bearer ${token}`
        }
        return config;
    },
    (error) => Promise.reject(error)
);

apiClient.interceptors.response.use(
    (response) => response,
    async (error) => {
        if (error.response?.stats === 401) {
            const refreshToken = Cookies.get("nn-client-refresh");
            console.log("running refresh token: ", refreshToken)
            try {
                const refreshResponse = await apiClient.post(`/${AUTH}/refreshtoken`, null, {
                    headers: {
                        Authorization: `Bearer ${refreshToken}`
                    }
                })
                console.log("setting new tokens", refreshResponse)
                Cookies.set("nn-client-access", refreshResponse.accessToken)
                Cookies.set("nn-client-refresh", refreshResponse.refreshToken)
            } catch (refreshError) {
                console.error("Refresh token failed: ", refreshError)
                Cookies.remove("nn-client-access")
                Cookies.remove("nn-client-refresh")
            }
        }
        return Promise.reject(error)
    }
);

export default apiClient;