import { AUDITTRAIL as audit } from "../config/routes";
import SendRequest from "../../lib/api_request/api_request";
import { GET } from "./enum_apitypes";
import { ServerURI, ServerPORT } from "../config/uri";
import { NOVACOOKIE } from "../config/novacookies";
import { getCookie } from "../../lib/cookiesjs/cookiesjs";
import { parseApiError } from "./api_errorhandler";
import apiClient from "../../lib/api_request/api_request_V2";

const sendRequest = new SendRequest();
const endpoint = `${ServerURI}:${ServerPORT}/${audit}`;
const accessToken = await getCookie(NOVACOOKIE);

// commented out on 12.12 -> remove when new method is stable
// export const getAuditTrail = async (type, params) => {
//     return await
//         sendRequest.request(
//             `${endpoint}/${type}?${params}`,
//             GET,
//             null,
//             accessToken,
//             null
//         )
//             .then(resp => resp.data)
//             .catch(err => {
//                 // console.log("err: ", err)
//                 // return ({ "code": 401, "text": err })
//                 return parseApiError(err);
//             })
//     // ideal solution - ToDo - look at structure in parseApiError
//     // .catch(err => parseApiError(err))
// }

export const getAuditTrail = async (type, params) => {
    return await
        apiClient.get(`${audit}/${type}?${params}`)
            .then(resp => resp.data)
            .catch(err => err)
}