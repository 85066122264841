import { PAYMENTS as payments } from "../config/routes";
import SendRequest from "../../lib/api_request/api_request";
import { GET, POST, DELETE } from "./enum_apitypes";
import { ServerURI, ServerPORT } from "../config/uri";
import { NOVACOOKIE } from "../config/novacookies";
import { getCookie } from "../../lib/cookiesjs/cookiesjs";
import apiClient from "../../lib/api_request/api_request_V2";

const sendRequest = new SendRequest();
const endpoint = `${ServerURI}:${ServerPORT}/${payments}`;
const accessToken = await getCookie(NOVACOOKIE);

// commented out on 12.12 - remove when new method is stable
// export const getPayments = async (params) => {
//     console.log(params);
    
//     return await
//         sendRequest.request(
//             `${endpoint}${params}`,
//             GET,
//             null,
//             accessToken,
//             null
//         )
//             .then(resp => resp.data)
//             .catch(err => {
//                 console.log("Api Error: ", err)
//                 return err
//             });
//     // return this.validationStatusError(err);
// }

// validation status error?
// return this.validationStatusError(err);
export const getPayments = async (params) => {
    return await
        apiClient.get(`${payments}?${params}`)
            .then(resp => resp.data)
            .catch(err => err)
}

// commented out on 12.12 - remove when new method is stable
// export const deletePayment = async (paymentId) => {
//     return await
//         sendRequest.request(
//             `${endpoint}/${paymentId}`,
//             DELETE,
//             null,
//             accessToken,
//             null
//         )
//             .then((resp) => resp)
//             // .catch(err => {
//             //     console.log("api ", err)
//             // });
//             // need a better methodology for error handling 10.10.24
//             .catch(err => {return {code: err.response.status, text: err.response.data}})
// }

export const deletePayment = async (id) => {
    return await
        apiClient.delete(`${payments}/${id}`)
            .then(resp => resp)
            .catch(err => err)
}

