import { PAYCHECKS as paychecks } from "../config/routes";
import SendRequest from "../../lib/api_request/api_request";
import { GET, POST, DELETE } from "./enum_apitypes";
import { ServerURI, ServerPORT } from "../config/uri";
import { NOVACOOKIE } from "../config/novacookies";
import { getCookie } from "../../lib/cookiesjs/cookiesjs";
import { parseApiError } from "./api_errorhandler";
import apiClient from "../../lib/api_request/api_request_V2";

const sendRequest = new SendRequest();
const endpoint = `${ServerURI}:${ServerPORT}/${paychecks}`;
const accessToken = await getCookie(NOVACOOKIE);

// commented out on 12.12 - remove when new method is stable
// export const getPaychecks = async (params) => {
//     return await
//         sendRequest.request(
//             `${endpoint}?${params}`,
//             GET,
//             null,
//             accessToken,
//             null
//         )
//             .then(resp => resp.data)
//             .catch(err => ({ "code": 401, "text": err }))
//     // ideal solution - ToDo - look at structure in parseApiError
//     // .catch(err => parseApiError(err))
// }

export const getPaychecks = async (params) => {
    console.log("params: ", params)
    return await
        apiClient.get(`${paychecks}?${params}`)
            .then(resp => resp.data)
            .catch(err => err)
}

// commented out on 12.12 - remove when new method is stable
// export const addPaycheck = async (payCheck) => {
//     return await
//         sendRequest.request(
//             endpoint,
//             POST,
//             payCheck,
//             accessToken,
//             null
//         )
//             .then(resp => resp.data)
//             .catch(err => console.log(`An error occured: ${err}`))
// }

export const addPaycheck = async (payCheck) => {
    return await
        apiClient.post(`${paychecks}`, payCheck)
            .then(resp => resp.data)
            .catch(err => err)
}

// commented out on 12.12 - remove when new method is stable
// export const deletePaycheck = async (id) => {
//     return await
//         sendRequest.request(
//             `${endpoint}/${id}`,
//             DELETE,
//             null,
//             accessToken,
//             null
//         )
//             .then(resp => resp)
//             .catch(err => console.log(`An error occured: ${err}`))
// }

export const deletePaycheck = async (id) => {
    return await
        apiClient.delete(`${paychecks}/${id}`)
            .then(resp => resp)
            .catch(err => err)
}