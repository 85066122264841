import React from "react";

// Material UI import
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarExport,
  GridToolbarQuickFilter,
  GridToolbarFilterButton,
  GridFooterContainer,
  GridFooter,
  useGridApiContext,
} from "@mui/x-data-grid";
import {
  Grid2 as Grid,
  Box,
  CircularProgress,
  Typography,
  Paper,
  Dialog,
  Switch,
  Button,
} from "@mui/material";
import { darken, lighten, styled } from "@mui/material/styles";

import CopyAllOutlinedIcon from "@mui/icons-material/CopyAllOutlined";
import FilterListIcon from "@mui/icons-material/FilterList";
import { alerts, displayAlert } from "../Helper/AlertToastify";

///**** Custom Components *****/


// 15.10.2024 - is this being used? if not then delete
const CustomHeaderGrid = styled(Grid)(({ theme }) => ({
  "& .MuiTextField-root": {
    width: "75%",
  },
}));

export const CustomGridToolbar = (toolbarProps) => {
  // Access the Grid API
  const apiRef = useGridApiContext();

  // Clipboard Export Function
  const handleExportToClipboard = () => {
    let clipboardContent = apiRef.current.getDataAsCsv({
      delimiter: "\t",
      includeHeaders: true,
    });
    console.log(clipboardContent);
    // Copy to Clipboard
    navigator.clipboard.writeText(clipboardContent).then(() => {
      displayAlert(alerts.SUCCESS, "Tabla copiada.");
    });
  };

  return (
    <GridToolbarContainer>
      <Grid container size={12} alignItems="center">
        <Grid
          xs="auto" // Adjusts size based on content
          display="flex"
          alignItems="center"
          justifyContent="flex-start" // Aligns items to the left
        >
          <GridToolbarQuickFilter />
          {/* <GridToolbarFilterButton slotProps={{ button: { sx: { '& .MuiButton-startIcon': { margin: 0 }, '& .MuiButton-text': { display: 'none' } } } }}/> */}
          {toolbarProps ? toolbarProps.component1 : null}
        </Grid>
        <Grid
          xs="auto" // Adjusts size based on content
          display="flex"
          justifyContent="flex-end" // Aligns items to the right
          alignItems="center"
          marginLeft="auto" // Pushes this Grid to the far right
        >
          {toolbarProps ? toolbarProps.component2 : null}
          {/* Add Clipboard Export Button */}
          <Button
            variant="outlined"
            size="small"
            onClick={handleExportToClipboard}
            sx={{ marginRight: 0.5, marginLeft: 0.5 }}
            startIcon={<CopyAllOutlinedIcon sx={{ marginRight: 0 }} />} // Icon placed before the label
          >
            Tabla
          </Button>
          <GridToolbarColumnsButton />
          <GridToolbarExport />
        </Grid>
      </Grid>
    </GridToolbarContainer>
  );
};

export const CustomDataGrid = (props) => {
  return (
    <DataGrid
      getEstimatedRowHeight={() => 100}
      getRowHeight={() => "auto"}
      // ToDo: 10.10.24 - is this needed? used in customer loans
      // isRowSelectable={() => false}
      slotProps={{
        pagination: {
          labelRowsPerPage: "lineas por página",
        },
      }}
      localeText={toolbarTranslations()}
      disableColumnMenu
      sx={{
        fontSize: { xs: 12, md: 15 },
        "& .MuiDataGrid-columnHeaderTitle": {
          whiteSpace: "normal",
          lineHeight: "normal",
          overflowWrap: "normal",
        },
        "& .MuiDataGrid-columnHeader": {
          // Forced to use important since overriding inline styles
          maxHeight: "168px !important",
          backgroundColor: "#D3D3D3",
        },
        "& .MuiTextField-root": {
          width: "75%",
        },
        "& .MuiDataGrid-cell": {
          display: "flex",
          alignItems: "center",
          whiteSpace: "normal",
          wordBreak: "break-word",
          overflowWrap: "break-word",
          textAlign: "left", // Adjust the alignment as needed
        },
        "& .MuiDataGrid-cellRight": {
          textAlign: "right", // Adjust the alignment as needed for right-aligned cells
        },
      }}
      {...props}
    />
  );
};

export const CustomFooter = ({ totalAmount }) => {
  // console.log("CustomFooter is being rendered");
  return (
    <GridFooterContainer style={{ backgroundColor: "#f7f7f7" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "left",
        }}
      >
        <Typography variant="h6" align="left" sx={{ marginLeft: 1 }}>
          Total:
        </Typography>
        <Typography
          variant="h5"
          align="left"
          sx={{ marginLeft: 1, fontWeight: "bold" }}
        >
          ${totalAmount}
        </Typography>
      </Box>
      <GridFooter />
    </GridFooterContainer>
  );
};

export const CircularProgressWithLabel = ({ typographyVariant, ...props }) => {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant={typographyVariant} component="div">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
};

export const GridPageContainer = styled(Paper)(({ theme }) => ({
  padding: { xs: "10px", md: " 25px" },
}));
//trying to create a general Container for all of the pages with a default padding
export const BoxPageContainer = styled("div")(({ theme }) => ({
  padding: { xs: "5px 10px 5px 10px", md: "80px 25px 0 25px" },
}));

export const ItemPaper = styled(Paper)(({ theme }) => ({
  //   margin: theme.spacing(1),
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.primary,
  boxShadow: "1",
  borderRadius: 20,
  height: "100%",
}));

export const ItemCaja = styled(Paper)(({ theme }) => ({
  //   margin: theme.spacing(1),
  padding: theme.spacing(1),
  textAlign: "right",
  color: theme.palette.text.primary,
  //   boxShadow: "none",
  borderRadius: 20,
  borderColor: "#ACF",
  height: "100%",
}));

//used in the CustomerProfile page
export const ItemBox = styled(Paper)(({ theme }) => ({
  // margin: theme.spacing(1),
  padding: theme.spacing(0.5),
  textAlign: "center",
  height: "100%",
  backgroundColor: "#fff",
  boxShadow: "none",
  borderRadius: 20,
}));

export const ItemPaperHeader = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  fontWeight: 700,
  color: theme.palette.text.primary,
  // background: theme.palette.info.main,
}));

// create a general Dialog as a template
export const CustomDialog = styled(Dialog)(({ theme }) => ({
  sx: {
    bgcolor: "#ebedf3",
  },
  // background: theme.palette.info.main,
}));

export const CustomSwitch = styled(Switch)(({ theme }) => ({
  width: 64,
  height: 32,
  padding: 6,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,

    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(32px)",
      "& .MuiSwitch-thumb:before": {
        //   backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        //     '#fff',
        //   )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.success.light,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: theme.palette.success.dark,
    width: 28,
    height: 28,
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",

      // backgroundImage: convertSvg(<CheckCircleIcon color={primaryPale} />)
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: "lightgrey",
    borderRadius: 20 / 2,
  },
}));

///**** Styled Components *****/

const getBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.7) : lighten(color, 0.7);

const getHoverBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

const getSelectedBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5);

const getSelectedHoverBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.4) : lighten(color, 0.4);


export const toolbarTranslations = () => {
  return {
    toolbarDensity: "Densidad",
    toolbarDensityLabel: "Densidad",
    toolbarDensityCompact: "Compacto",
    toolbarDensityStandard: "Estándar",
    toolbarDensityComfortable: "Cómodo",
    toolbarColumns: "Columnas",
    toolbarFilters: "Filtros",
    toolbarExport: "Exportar",
    toolbarExportLabel: "Exportar",
    toolbarExportCSV: "Descargar como CSV",
    toolbarExportPrint: "Imprimir",

    // Columns panel
    columnsPanelTextFieldLabel: "Buscar columna",
    columnsPanelTextFieldPlaceholder: "Título de la columna",
    columnsPanelDragIconLabel: "Reordenar columna",
    columnsPanelShowAllButton: "Mostrar todas",
    columnsPanelHideAllButton: "Ocultar todas",

    // Filter panel
    filterPanelAddFilter: "Añadir filtro",
    filterPanelRemoveAll: "Eliminar todos",
    filterPanelDeleteIconLabel: "Eliminar",
    filterPanelLogicOperator: "Operador lógico",
    filterPanelOperator: "Operador",
    filterPanelOperatorAnd: "Y",
    filterPanelOperatorOr: "O",
    filterPanelColumns: "Columnas",
    filterPanelInputLabel: "Valor",
    filterPanelInputPlaceholder: "Valor del filtro",

    // Filters
    filterOperatorContains: "contiene",
    filterOperatorEquals: "es igual a",
    filterOperatorStartsWith: "comienza con",
    filterOperatorEndsWith: "termina con",
    filterOperatorIs: "es",
    filterOperatorNot: "no es",
    filterOperatorAfter: "es posterior a",
    filterOperatorOnOrAfter: "es en o posterior a",
    filterOperatorBefore: "es anterior a",
    filterOperatorOnOrBefore: "es en o anterior a",
    filterOperatorIsEmpty: "está vacío",
    filterOperatorIsNotEmpty: "no está vacío",
    filterOperatorIsAnyOf: "es cualquiera de",

    // Filter values
    filterValueAny: "cualquier",
    filterValueTrue: "verdadero",
    filterValueFalse: "falso",

    // Column menu
    columnMenuLabel: "Menú",
    columnMenuShowColumns: "Mostrar columnas",
    columnMenuManageColumns: "Gestionar columnas",
    columnMenuFilter: "Filtrar",
    columnMenuHideColumn: "Ocultar columna",
    columnMenuUnsort: "Desordenar",
    columnMenuSortAsc: "Orden ascendente",
    columnMenuSortDesc: "Orden descendente",

    // Footer
    footerRowSelected: (count) =>
      count !== 1
        ? `${count.toLocaleString()} filas seleccionadas`
        : `${count.toLocaleString()} fila seleccionada`,
    footerTotalVisibleRows: (visibleCount, totalCount) =>
      `${visibleCount.toLocaleString()} de ${totalCount.toLocaleString()}`,

    // Quick filter
    toolbarQuickFilterPlaceholder: "Buscar...",
    toolbarQuickFilterLabel: "Buscar",
    toolbarQuickFilterDeleteIconLabel: "Borrar",

    // Pagination
    MuiTablePagination: {
      labelDisplayedRows: ({ from, to, count }) =>
        `${from}-${to} de ${count !== -1 ? count : `más de ${to}`}`,
      labelRowsPerPage: "Filas por página:",
      firsticonbuttontext: "Primera página",
      previousiconbuttontext: "Página anterior",
      nexticonbuttontext: "Página siguiente",
      lasticonbuttontext: "Última página",
    },
  };
};