import axios from "axios";

/** 
 * Generalise the outgoing call
 * @param {string} path determines the desired route
 * @param {string} method determine REST method. GET is default
 * @param {string} body payload to be sent
 * @param {string} credentials authentication in the form of a JWT
 * @param {string} extraHeaders typcially sending blob or octet stream
*/

export default class SendRequest {
    async request (
        path,
        method = "GET",
        body,
        credentials,
        extraHeaders
    ) {
        if ( credentials ) {
            axios.defaults.headers.authorization = `Bearer ${credentials}`;
        }

        // console.log("api_request: ", credentials)

        // if ( extraHeaders ) {
        //     axios.defaults.headers.contentType = extraHeaders;
        // }

        switch( method ) {
            case "GET":
                return await axios.get(path);
            
            case "POST":
                if ( extraHeaders !== null ) {
                    return await axios.post(path, body, { headers: {'Content-Type': 'multipart/form.data'} });
                } else {
                    return await axios.post(path, body);
                }
                // return await axios.post(path, body);
            case "PUT":
                return await axios.put(path, body);

            case "PATCH":
                return await axios.patch(path, body);

            case "DELETE":
                return await axios.delete(path);
        }
    }
}