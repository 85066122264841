import { EXPENSES as expenses } from "../config/routes";
import SendRequest from "../../lib/api_request/api_request";
import { GET, POST, DELETE } from "./enum_apitypes";
import { ServerURI, ServerPORT } from "../config/uri";
import { NOVACOOKIE } from "../config/novacookies";
import { getCookie_V2, getCookie } from "../../lib/cookiesjs/cookiesjs";
import { parseApiError } from "./api_errorhandler";
import apiClient from "../../lib/api_request/api_request_V2";

const sendRequest = new SendRequest();
const endpoint = `${ServerURI}:${ServerPORT}/${expenses}`;
const accessToken = await getCookie(NOVACOOKIE);

// commented out on 12.12 - remove when new method is stable
// export const getExpenses = async (params) => {
//     // console.log("expenses: ", accessToken);
//     return await
//         sendRequest.request(
//             `${endpoint}?${params}`,
//             GET,
//             null,
//             accessToken,
//             null
//         )
//             .then(resp => resp.data)
//             .catch(err => err)
//     // ideal solution - ToDo - look at structure in parseApiError
//     // .catch(err => parseApiError(err))
// }

export const getExpenses = async (params) => {
    return await
        apiClient.get(`${expenses}?${params}`)
            .then(resp => resp.data)
            .catch(err => err)
}

// commented out on 12.12 - remove when new method is stable
// export const addExpense = async (expense) => {
//     return await
//         sendRequest.request(
//             endpoint,
//             POST,
//             expense,
//             accessToken,
//             null
//         )
//             .then(resp => resp.data)
//             .catch(err => console.log(`An error occured: ${err}`))
// }

export const addExpense = async (expense) => {
    return await
        apiClient.post(`${expenses}`, expense)
            .then(resp => resp.data)
            .catch(err => err)
}

// commented out on 12.12 - remove when new method is stable
// export const deleteExpense = async (id) => {
//     return await
//         sendRequest.request(
//             `${endpoint}/${id}`,
//             DELETE,
//             null,
//             accessToken,
//             null
//         )
//             .then(resp => resp)
//             .catch(err => console.log(`An error occured: ${err}`))
// }

export const deleteExpense = async (id) => {
    return await
        apiClient.delete(`${expenses}/${id}`)
            .then(resp => resp)
            .catch(err => err)
}