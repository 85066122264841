import React, { useContext, useEffect, useState } from "react";
import { NovaContext } from "../../components/context";
import { useParams } from "react-router-dom";

import Loading from "../../components/Actions/Loading";

// Material UI import
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import profile from "../../images/profile-default.png";

// Material UI Date Picker
// import CustomerHeader from "./CustomerHeader";
import { ItemBox } from "../../components/Helper/MUIStyledComponents";

// internal
import { CustomTabPanel } from "../../components/Helper/GlobalHelpers";
import EmployeeHeader from "./EmployeeHeader";
import EmployeeContent from "./EmployeeContentV2";
import Media_New from "../Modal/Media_New";
import { uploadDestination } from "../../components/Helper/MediaHelpers";

const Index = () => {
  const { actions, currentRole, filial, authenticatedUser } = useContext(NovaContext);

  const params = useParams();
  const employeeId = params.id;
  const errors = [];
  const [employee, setEmployee] = useState("");
  const [loans, setLoans] = useState([]);

  // page-related state
  const [loading, setLoading] = useState(true);
  const [lockStatus, setLockStatus] = useState(false);
  const [drawer, setDrawer] = useState(false);
  const [dialogPayment, setDialogPayment] = useState(false);
  const [dialogLoan, setDialogLoan] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [audittrailList, setAudittrailList] = useState([]);
  const [filialList, setFilialList] = useState([]);
  const [filials, setFilials] = useState([]);
  const [selectedLoan, setSelectedLoan] = useState(null);
  const [paymentsList, setPaymentsList] = useState([]);
  const [mediaItems, setMediaItems] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // get employee
        const employeeResp = await actions.getEmployee(employeeId);
        console.log("employee:", employeeResp);
        setEmployee(employeeResp);

        // get loans issued by employee
        const loansResp = await actions.getLoansIssuedByEmployee(employeeId);
        console.log("Loans:", loansResp);
        setLoans(loansResp);

        // get audit items
        const auditResp = await actions.getEmployeeAudit(employeeId);
        setAudittrailList(auditResp);

        // get all filials to get the names
        const filialsResp = await actions.getFilials();
        setFilials(filialsResp);

        //   // get all the filials where the employee has access
        //   await actions
        //     .getAllEmployees()
        //     .then((resp) => {
        //       const filteredFilials = resp.filter(
        //         (filial) => filial.employee_id === employeeId
        //       );
        //       console.log("Filtered Fili:", filteredFilials, "filials", filials);

        //       // Add filial_name directly to the filteredFilials list
        //       const updatedFilials = filteredFilials.map((employee) => {
        //         const filial = filials.find(
        //           (filialObj) => filialObj.filial_id === employee.worksin_filialid
        //         );
        //         return {
        //           ...employee,
        //           // role_name: employee.role_name,
        //           filial_name: filial ? filial.filial_name : "Unknown",
        //         };
        //       });

        //       console.log("Updated Filials:", updatedFilials);
        //       setFilialList(updatedFilials);
        //     })
        //     .catch((err) => errors.push(err));

        //   setLoading(false);
        // };

        // get all the filials where the employee has access
        const employeesResp = await actions.getAllEmployees();
        const filteredFilials = employeesResp.filter(
          (filial) => filial.employee_id === employeeId
        );
        console.log("Filtered Fili:", filteredFilials, "filials", filialsResp);

        // Add filial_name directly to the filteredFilials list
        const updatedFilials = filteredFilials.map((employee) => {
          const filial = filialsResp.find(
            (filialObj) => filialObj.filial_id === employee.worksin_filialid
          );
          return {
            ...employee,
            filial_name: filial ? filial.filial_name : "Unknown",
          };
        });

        console.log("Updated Filials:", updatedFilials);
        setFilialList(updatedFilials);

        setLoading(false);
      } catch (err) {
        errors.push(err);
      }
    };
    // get media added by employee

    fetchData();
  }, []);

  // toggle for payments list
  const toggleDrawer = (loan) => {
    const loanId = loan.loan_id;
    setPaymentsList(getPaymentsFromLoan(loanId));
    setSelectedLoan(loan);
    setDrawer(true);
  };

  // toggle for payment Dialog
  const togglePaymentDialog = (loan) => {
    setSelectedLoan(loan);
    setDialogPayment(true);
  };

  // triggered when the information icon per loan is clicked
  const getPaymentsFromLoan = async (loanId) => {
    await actions
      .getPaymentsFromLoan(loanId)
      .then((resp) => {
        setPaymentsList(resp);
      })
      .catch((err) => console.log(err));
  };

  const updateMedia = (newMedia, command) => {
    if (command === "ADD") {
      setMediaItems((prevState) => {
        return [...prevState, newMedia];
      });
    } else {
      setMediaItems((prevState) => {
        return prevState.filter((item) => item.media_id !== newMedia.media_id);
      });
    }
  };

  const handleUploadMedia = (newMedia) => {
    updateMedia(newMedia, "ADD");
  }

  return (
    // <div className="page-container">
    <Grid container sx={{ padding: { xs: "10px", md: " 25px" } }}>
      <div
        className={`over-page-loading ${lockStatus || loading ? "show-loading" : "hide-loading"
          }`}
      >
        <div className="over-page-loading-content">
          <Loading
            text={"Cargando..."}
            classNames={`over-page-loading ${lockStatus || loading ? "show-loading" : "hide-loading"
              }`}
          />
        </div>
      </div>

      {lockStatus || loading ? (
        <div>Cargando...</div>
      ) : (
        <Grid container size={12} spacing={2}>
          {/* Header section */}
          <Grid size={12}>
            {/* <Grid size={12}> */}
            <EmployeeHeader
              employee={employee}
            // loan={loan}
            // loansOnMeter={loansOnMeter}
            />
          </Grid>
          <Grid size={{ xs: 12, md: 4 }}>
            <Grid size={{ xs: 12 }}>
              <ItemBox>
                <Box
                  padding={1}
                  position={"relative"}
                  sx={{
                    height: 350,
                    orderRadius: "50%",
                    overflow: "hidden",
                  }}
                >
                  <Box
                    component="img"
                    // src={profilePic ? profilePic.location : defaultProfile}
                    src={profile}
                    sx={{
                      height: "100%",
                      // width: "100%",
                      objectFit: "cover",
                      borderRadius: "25%",
                      // borderRadius: "50%",
                    }}
                  />
                  <Box position={"absolute"} right={0} bottom={0}>
                    New Edit
                  </Box>
                </Box>
              </ItemBox>
            </Grid>
            <Grid
              size={{ xs: 12 }}
              sx={{ height: { md: "365px" } }}
            >
              Info Tabs
            </Grid>
            {/* </Grid> */}
          </Grid>

          <Grid size={{ xs: 12, md: 8 }}>
            <Box position={"relative"}>
              <ItemBox padding={1}>
                <EmployeeContent
                employee={employee}
                  employeeLoans={loans}
                  loansLoaded={true}
                  filialList={filialList}
                  // viewLoanDetails={toggleDrawer}
                  // togglePaymentDialog={togglePaymentDialog}
                  // customerAudit={audittrailList}
                  // auditLoaded={true}
                  employeeMedia={mediaItems}
                // mediaLoaded={false}
                // updateMedia={updateMedia}
                />

                <Box
                  position={"absolute"}
                  top={{ xs: 12, md: 20 }}
                  right={12}
                  display={"flex"}
                  alignItems={"center"}
                >
                  <Media_New
                    objectUuid={authenticatedUser.employee_id}
                    afterAddFunction={handleUploadMedia}
                    destination={uploadDestination.EMPLOYEE}
                  />
                </Box>

              </ItemBox>

            </Box>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default Index;
