import React from "react";
import { Link } from "react-router-dom";

// Date-fns imports
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";

// mui
import Download from "@mui/icons-material/Download";
import Delete from '@mui/icons-material/Delete';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { checkAccess } from "../../GlobalHelpers";

export const prepareDocumentColumns = (setSelectedDocument, openConfirmation, currentRole) => {
    const documentColumns = [
        {
            field: "media_name",
            headerName: "Nombre",
            align: "left",
            flex: 1,
            minWidth: 250,
            // maxWidth: 350,
            hideable: false,
            headerClassName: "GridHeader",
        },
        {
            field: "media_datecreated",
            headerName: "Fecha de carga",
            headerClassName: "GridHeader",
            headerAlign: "right",
            align: "right",
            flex: 1,
            minWidth: 150,
            maxWidth: 200,
            valueFormatter: (value) =>
                format(parseISO(value), "dd/MM/yyyy HH:mm"),
            sortComparator: (v1, v2) => v1.createdate - v2.createdate
        },
        {
            field: "employee_lastname",
            headerName: "Empleado",
            headerClassName: "GridHeader",
            flex: 1,
            minWidth: 250,
            // maxWidth: 350,
            renderCell: (params) => {
                return (
                    <Link to={`/Employees/${params?.row.employeeid}`}>
                        {params?.row.employee_fullname}
                    </Link>
                )
            }
        },
        {
            field: "accionDescargar",
            headerName: "",
            hideable: false,
            sortable: false,
            flex: 1,
            align: "right",
            minWidth: 150,
            maxWidth: 200,
            renderCell: (params) => {
                const hasAdminAccess = checkAccess(currentRole, "Administrador");
                const triggerDownload = (e) => {
                    e.stopPropagation();
                    window.location.href = params?.row.location;
                };
                const triggerConfirmationPage = (e) => {
                    e.stopPropagation();
                    setSelectedDocument(params?.row);
                    openConfirmation(true);
                }
                return (
                    <Box>
                        <Button
                            variant="outlined"
                            size="medium"
                            onClick={triggerDownload}
                        >
                            <Download fontSize="inherit" />
                        </Button>
                        <Button
                            color="error"
                            variant="outlined"
                            size="medium"
                            onClick={triggerConfirmationPage}
                            sx={{ display: hasAdminAccess ? '' : 'none' }}
                        >
                            <Delete fontSize="inherit" />
                        </Button>
                    </Box>
                );
            }
        }
    ];
    return documentColumns;
}