import React from "react";

// component
import LoansListWrapper from "../../../components/Wrappers/LoansListWrapper";

// mui
import Grid from "@mui/material/Grid2";
import { ItemPaper } from "../../../components/Helper/MUIStyledComponents";
import BreadcrumbNavigation from "../../Reports/BreadcrumbReports";
import { visibilityModelLoanContainer } from "../../../components/Helper/DataGrid/ColumnDefinitions/Columns_Loans";

const LoansContainer = () => {
  return (
    <Grid container sx={{ padding: { xs: " 10px", md: "25px" } }}>
      <Grid size={12} display="flex" justifyContent="left" alignItems="left">
        <BreadcrumbNavigation currentPage="Historial de préstamos" />
        {/* <h1 className="text-center">Historial de préstamos</h1> */}
      </Grid>
      <Grid size={12}>
        <ItemPaper>
          <LoansListWrapper
            columnVisibilityModel={visibilityModelLoanContainer}
            sortingFilter={"lo.loan_dateissue DESC"}
            statusFilter={"none"}
          />
        </ItemPaper>
      </Grid>
    </Grid>
  );
};

export default LoansContainer;
