import AmountDisplay from "../../../common/AmountDisplay";
import { checkAccess } from "../../GlobalHelpers";

// misc
import { Link } from "react-router-dom";

//date-fns
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";

// mui
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import DescriptionIcon from "@mui/icons-material/Description";
import Box from "@mui/material/Box";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import Button from "@mui/material/Button";
import { differenceInDays } from "date-fns";
import TooltipIconButton from "../../../controls/TooltipIconButton";

export const preparePaymentColumns = (
  openConfirmation,
  setSelectedPayment,
  navigate,
  currentRole
) => {
  const hasRegionalManagerAccess = checkAccess(currentRole, "Gerente Regional");
  const hasAuditorAccess = checkAccess(currentRole, "Auditor");

  const paymentColumns = [
    {
      field: "customer_idnumber",
      headerName: "ID",
      renderHeader: () => <strong>ID</strong>,
      align: "center",
      type: "number",
      width: "50",
      hideable: false,
      headerClassName: "GridHeader",
    },
    {
      field: "customer_fullname",
      headerName: "Cliente",
      headerClassName: "GridHeader",
      type: "string",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <Link to={`/Customers/${params.row.loan_customerid}`}>
            {params.row.customer_fullname}
          </Link>
        );
      },
    },
    {
      field: "payment_total",
      headerName: "Cantidad total",
      headerClassName: "GridHeader",
      headerAlign: "right",
      type: "number",
      flex: 1,
      minWidth: 80,
      maxWidth: 120,
      renderCell: (params) => (
        <AmountDisplay amount={params?.row.payment_total} />
      ),
      //sortComparator: (v1, v2) => v1.payment_total - v2.payment_total,
    },
    {
      field: "payment_interest",
      headerName: "Refrendo",
      headerClassName: "GridHeader",
      headerAlign: "right",
      type: "number",
      flex: 1,
      minWidth: 80,
      maxWidth: 120,
      renderCell: (params) => (
        <AmountDisplay amount={params?.row.payment_interest} />
      ),
      //sortComparator: (v1, v2) => v1.payment_interest - v2.payment_interest,
    },
    {
      field: "payment_delay",
      headerName: "Mora",
      headerClassName: "GridHeader",
      headerAlign: "right",
      type: "number",
      flex: 1,
      minWidth: 80,
      maxWidth: 120,
      renderCell: (params) => (
        <AmountDisplay amount={params?.row.payment_delay} />
      ),
      //sortComparator: (v1, v2) => v1.payment_delay - v2.payment_delay,
    },
    {
      field: "payment_capital",
      headerName: "Abono a capital",
      headerClassName: "GridHeader",
      headerAlign: "right",
      type: "number",
      flex: 1,
      minWidth: 80,
      maxWidth: 120,
      renderCell: (params) => (
        <AmountDisplay amount={params?.row.payment_capital} />
      ),
      //sortComparator: (v1, v2) => v1.payment_capital - v2.payment_capital,
    },
    {
      field: "payment_createdate",
      headerName: "Fecha de pago",
      headerClassName: "GridHeader",
      flex: 1,
      minWidth: 170,
      maxWidth: 250,
      valueFormatter: (value) =>
        `${format(parseISO(value), "dd/MM/yyyy HH:mm")} hrs`,
      //sortComparator: (v1, v2) => v1.created_date - v2.created_date,
    },
    {
      field: "employee_fullname",
      headerName: "Empleado",
      headerClassName: "GridHeader",
      flex: 1,
      minWidth: 180,

      renderCell: (params) => {
        if (hasAuditorAccess || hasRegionalManagerAccess)
          return (
            <Link to={`/Employees/${params?.row.employee_id}`}>
              {params?.row.employee_fullname}
            </Link>
          );
        return params?.row.employee_fullname;
      },
    },
    {
      field: "accVer",
      headerClassName: "GridHeader",
      headerName: "Ticket",
      width: 80,
      align: "right",
      hideable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <Box display="flex" alignItems={"center"}>
            <Tooltip id="button-customer-details" title="Ver ticket de pagos">
              <IconButton
                color="primary"
                variant="contained"
                size="large"
                data-target={`#id${params?.row.customer_id}`}
                onClick={() => navigate(`/TicketPay/${params?.row.payment_id}`)}
              >
                {""}
                <DescriptionIcon fontSize="large" />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
    },
    {
      field: "accionRevocar",
      headerName: "",
      width: 45,
      hideable: false,
      sortable: false,
      renderCell: (params) => {
        const triggerConfirmationPage = (e) => {
          e.stopPropagation();
          setSelectedPayment(params?.row.payment_id);
          openConfirmation(true);
        };
        const isDisabled =
          differenceInDays(
            new Date(),
            parseISO(params?.row.payment_createdate)
          ) > 15;
        return (
          <TooltipIconButton
            title="Rechazar pago"
            onClick={triggerConfirmationPage}
            disabled={isDisabled}
            color="warning"
            size="medium"
            variant="contained"
            icon={<DeleteForeverRoundedIcon fontSize="large" />}
          />
        );
      },
    },
  ];
  return paymentColumns;
};

export const getPaymentVisibleColumns = (currentRole) => {
  const hasAuditorAccess = checkAccess(currentRole, currentRole);
  return {
    accionRevocar: hasAuditorAccess,
  };
};

export const getPaymentsSortModel = () => {
  return {};
};

export const columnVisibilityModelPaymentContainer = (currentRole) => {
  const hasAuditorAccess = checkAccess(currentRole, "Auditor");
  const hasRegionalManagerAccess = checkAccess(currentRole, "Gerente Regional");

  return {
    accionRevocar: hasAuditorAccess || hasRegionalManagerAccess,
  };
};

// export const columnVisibilityModelPaymentFilialPage = {
//     employee_name: false,
//     accionRevocar: false,
//   };
