import React, { useState } from "react";

// internal misc
import {
  CustomDataGrid,
  CustomFooter,
  CustomGridToolbar,
} from "../../../components/Helper/MUIStyledComponents";
import { alerts, displayAlert } from "../../../components/Helper/AlertToastify";
import { usePaycheckContext } from "../../../components/context/PaycheckContext";
import { addPaycheck, deletePaycheck } from "../../../utils/api/api_paychecks";

// components
import DateRange from "../../../components/common/DateRange/DateRange";
import Confirmation from "../../../components/common/Confirmation";
import { renderNoRowDataOverlay } from "../../../components/Helper/DataGrid/Overlays/RenderNoRowDataOverlay";
import CustomLoadingOverlay from "../../../components/Helper/DataGrid/Overlays/CustomLoadingOverlay";

// actions
import { PaycheckActions } from "../../../reducers/PaycheckReducer";
import { DateRangeActions } from "../../../components/common/DateRange/DateRangeReducer";

// helper
import { preparePaycheckColumns } from "../../../components/Helper/DataGrid/ColumnDefinitions/Columns_Paycheck";

import NewPayCheck from "../../../components/popups/NewPayCheckV3";

// mui
import Box from "@mui/material/Box";
import { checkAccess } from "../../../components/Helper/GlobalHelpers";

const PaycheckList = ({ columnVisibilityModel }) => {
  const [selectedPaycheck, setSelectedPaycheck] = useState();
  const {
    loading,
    paycheckList,
    dateRange,
    dispatchDateRange,
    currentRole,
    dispatchPaycheckList,
  } = usePaycheckContext();

  // modal states
  const [payCheckModal, setPayCheckModal] = useState(false);
  const [confirmation, setConfirmation] = useState(false);

  // what does filter model do?
  const [filterModel, setFilterModel] = React.useState({
    items: [],
    quickFilterExcludeHiddenColumns: true,
    quickFilterValues: [""],
  });

  const hasRegionalManagerAccess = checkAccess(currentRole, "Gerente Regional");
  const hasAuditorAccess = checkAccess(currentRole, "Auditor");

  const changeDateRange = (updatedDates) => {
    dispatchDateRange({
      type: DateRangeActions.SET_DATE_RANGE_FILTER,
      payload: updatedDates,
    });
  };

  const handleCreatePaycheck = async (newPaycheck) => {
    dispatchPaycheckList({
      type: PaycheckActions.CREATE_PAYCHECK,
      payload: newPaycheck,
    });
  };

  const handleRemovePaycheck = async () => {
    await deletePaycheck(selectedPaycheck)
      .then((resp) => {
        setConfirmation(false);
        setSelectedPaycheck();
        dispatchPaycheckList({
          type: PaycheckActions.REMOVE_PAYCHECK,
          payload: selectedPaycheck,
        });
      })
      .catch((err) =>
        displayAlert(alerts.ERROR, `No se ha podido eliminar la nómina: ${err}`)
      );
  };

  const visibilityModel = columnVisibilityModel(currentRole);

  // const visibilityModel = columnVisibilityModel(currentRole);
  // Calculate the total for the "amount" column
  const totalAmount =
    paycheckList && paycheckList.length > 0
      ? paycheckList.reduce((acc, row) => acc + (row.amount || 0), 0)
      : 0;
  return (
    <div>
      <Confirmation
        open={confirmation}
        setOpen={setConfirmation}
        textContent={"¿Estás seguro de que quieres eliminar esta nómina?"}
        confirmationMethod={handleRemovePaycheck}
      />
      <Box height="85vh" width="100%">
        <CustomDataGrid
          columns={preparePaycheckColumns(
            setConfirmation,
            setSelectedPaycheck,
            currentRole
          )}
          rows={paycheckList}
          getRowId={(row) => row.id}
          slots={{
            toolbar: CustomGridToolbar,
            footer: CustomFooter,
            loadingOverlay: CustomLoadingOverlay,
            noRowsOverlay: () =>
              renderNoRowDataOverlay("No hay nóminas en este periodo"),
          }}
          slotProps={{
            toolbar: {
              component1:
                hasAuditorAccess || hasRegionalManagerAccess ? (
                  <DateRange
                    dateObject={dateRange}
                    changeMethod={changeDateRange}
                  />
                ) : null,
              component2: (
                <NewPayCheck
                  open={payCheckModal}
                  setOpen={setPayCheckModal}
                  updatePaychecks={handleCreatePaycheck}
                  showButton={true}
                />
              ),
            },
            footer: { totalAmount },
          }}
          onFilterModelChange={(newModel) => setFilterModel(newModel)}
          filterModel={filterModel}
          initialState={{
            columns: {
              columnVisibilityModel: visibilityModel,
            },
            filter: {
              filterModel: filterModel,
            },
          }}
          loading={loading}
        />
      </Box>
    </div>
  );
};

export default PaycheckList;
