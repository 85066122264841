import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// internal misc
import { useCashflowContext } from "../../../components/context/CashflowContext";
import {
  CustomDataGrid,
  CustomFooter,
  CustomGridToolbar,
} from "../../../components/Helper/MUIStyledComponents";
import { alerts, displayAlert } from "../../../components/Helper/AlertToastify";
import { deleteCashflow } from "../../../utils/api/api_cashflow";

// components
import DateRange from "../../../components/common//DateRange/DateRange";
import Confirmation from "../../../components/common/Confirmation";
import { renderNoRowDataOverlay } from "../../../components/Helper/DataGrid/Overlays/RenderNoRowDataOverlay";
import CustomLoadingOverlay from "../../../components/Helper/DataGrid/Overlays/CustomLoadingOverlay";
import NewCashFlow from "../../../components/popups/NewCashFlowV4";

// actions
import { CashflowActions } from "../../../reducers/CashFlowReducer";
import { DateRangeActions } from "../../../components/common/DateRange/DateRangeReducer";

// helper
import {
  prepareCashflowColumns,
  getCashflowVisibleColumns,
  getCashflowAutoSizeOptions,
} from "../../../components/Helper/DataGrid/ColumnDefinitions/Columns_Cashflow";
import { checkAccess } from "../../../components/Helper/GlobalHelpers";

// mui
import Box from "@mui/material/Box";

const CashflowList = () => {
  const [selectedCashflow, setSelectedCashflow] = useState();
  const {
    currentRole,
    loading,
    cashflowList,
    dateRange,
    dispatchDateRange,
    dispatchCashflow,
  } = useCashflowContext();
  const cashflowAutoSizeOptions = getCashflowAutoSizeOptions();
  const columnVisibilityModel = getCashflowVisibleColumns(currentRole);
  const hasRegionalManagerAccess = checkAccess(currentRole, "Gerente Regional");
  const navigate = useNavigate();

  // modal states
  const [confirmation, setConfirmation] = useState(false);
  const [cashFlowModal, setCashFlowModal] = useState(false);

  // what does filter model do?
  const [filterModel, setFilterModel] = React.useState({
    items: [],
    quickFilterExcludeHiddenColumns: true,
    quickFilterValues: [""],
  });

  const changeDateRange = (updatedDates) => {
    dispatchDateRange({
      type: DateRangeActions.SET_DATE_RANGE_FILTER,
      payload: updatedDates,
    });
  };

  const handleCreateCashFlow = async (newCashFlow) => {
    dispatchCashflow({
      type: CashflowActions.CREATE_CASHFLOW,
      payload: newCashFlow,
    });
  };

  const handleRemoveCashflow = async () => {
    await deleteCashflow(selectedCashflow)
      .then((resp) => {
        if (resp.status === 200) {
          dispatchCashflow({
            type: CashflowActions.REMOVE_CASHFLOW,
            payload: selectedCashflow,
          });
          displayAlert(
            alerts.SUCCESS,
            "Transacción de caja eliminado correctamente"
          );
          setConfirmation(false);
          setSelectedCashflow();
          // removePaycheck(selectedPaycheck);
        } else {
          displayAlert(alerts.ERROR, `${resp.text}`);
        }
      })
      .catch((err) => displayAlert(alerts.ERROR, `${err}`));
  };

  // Calculate the total for the "amount" column
  const totalAmount = cashflowList && cashflowList.length > 0
      ? cashflowList.reduce((acc, row) => acc + (row.amount || 0), 0)
      : 0;

  return (
    <div>
      <Confirmation
        open={confirmation}
        setOpen={setConfirmation}
        textContent={"¿Quiere eliminar este transacción de caja?"}
        confirmationMethod={handleRemoveCashflow}
      />
      <Box height="85vh" width="100%">
        <CustomDataGrid
          columns={prepareCashflowColumns(
            setConfirmation,
            setSelectedCashflow,
            navigate
          )}
          rows={cashflowList}
          getRowId={(row) => row.id}
          slots={{
            toolbar: CustomGridToolbar,
            footer: CustomFooter,
            loadingOverlay: CustomLoadingOverlay,
            noRowsOverlay: () =>
              renderNoRowDataOverlay("No hay movimientos en este periodo"),
          }}
          slotProps={{
            toolbar: {
              component1: (
                <DateRange
                  dateObject={dateRange}
                  changeMethod={changeDateRange}
                />
              ),
              component2: hasRegionalManagerAccess ? (
                <NewCashFlow
                  open={cashFlowModal}
                  setOpen={setCashFlowModal}
                  updateCashflowList={handleCreateCashFlow}
                  showButton={true}
                />
              ) : null,
            },
            footer: { totalAmount },
          }}
          onFilterModelChange={(newModel) => setFilterModel(newModel)}
          filterModel={filterModel}
          //   columnVisibilityModel={columnVisibilityModel}
          initialState={{
            columns: {
              columnVisibilityModel: {
                // columnXTohide: hasAdminAccess ? true : false,
                // columnVisibilityModel,
              },
            },
            filter: {
              filterModel: filterModel,
            },
          }}
          autosizeOptions={cashflowAutoSizeOptions}
          loading={loading}
        />
      </Box>
    </div>
  );
};

export default CashflowList;
