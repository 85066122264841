import { addHours, format } from 'date-fns';
import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { NovaContext } from '../components/context';
import { checkAccess, getTimeDifferenceInHours } from '../components/Helper/GlobalHelpers';
import CashRegisterControl from '../components/popups/CashRegisterControlCheck';
import NewCashFlow from '../components/popups/NewCashFlow';
import NewExpense from '../components/popups/NewExpense';
import NewPayCheck from '../components/popups/NewPayCheck';

const CashRegister = () => {

    const { actions, filial, currentRole } = useContext(NovaContext);
    const [currentFilial, setFilial] = useState("");
    const [cashRegisterTotal, setCashRegisterTotal] = useState("");
    const [counterEmployees, setCounterEmployees] = useState("");
    const [counterLoans, setCounterLoans] = useState("");
    const [counterCustomers, setCounterCustomers] = useState("");
    const [currentDateTime, setCurrentDateTime] = useState();
    const hasAdminAccess = checkAccess(currentRole, 'Administrador');    
    let navigate = useNavigate();

    const getData = () => {
        actions.getFilial(filial).then((data) => setFilial(data));
        actions.countEmployees().then((data) => setCounterEmployees(data));
        actions.countCustomers().then((data) => setCounterCustomers(data));
        actions.getCashRegister().then((data) => setCashRegisterTotal(data.total));
        actions.countLoans('OPEN').then((data) => setCounterLoans(data));
    }

    useEffect(() => {
        const currentDate = addHours(new Date(),+getTimeDifferenceInHours());
        setCurrentDateTime(format(currentDate, 'EEE dd MMMM'));
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filial]);

    const handleView = (string) => {
        const path = `${string}`;
        navigate(path);
    }

    return (
        <div className="page-container">
            <div className="subheader py-2 py-lg-12 subheader-transparent" id="kt_subheader">
                <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                    <div className="d-flex align-items-center flex-wrap mr-1">
                        <div className="d-flex flex-column">
                            <h2 className="text-black font-weight-bold my-2 mr-5">Caja de sucursal {currentFilial.filial_name}</h2>
                            <div className="d-flex align-items-center font-weight-bold my-2">
                                <span className="label label-dot label-sm  mx-3"></span>
                                <button className="text-black text-hover-white opacity-75 hover-opacity-100">{`Estado a fecha : ${currentDateTime}`}</button>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center">

                        <div className="dropdown dropdown-inline mr-5" data-toggle="tooltip" title="" data-placement="top" data-original-title="Quick actions">

                            <button href="#" className="btn btn-primary font-weight-bold py-3 px-6" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Acciones</button>
                            <div className="dropdown-menu p-0 m-0 dropdown-menu-md dropdown-menu-right btns-transparent">
                                <ul className="">
                                    <li className="navi-item">
                                        <button className="navi-link">
                                            <NewExpense />
                                        </button>
                                    </li>
                                    <li className="navi-item">
                                        <button className="navi-link">
                                            <NewCashFlow />
                                        </button>
                                    </li>
                                    <li className="navi-item">
                                        <button className="navi-link">
                                            <NewPayCheck />
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="cashreg-total-box">
                            <div>
                                Total en Caja: <h1>{cashRegisterTotal}</h1>
                                <CashRegisterControl cashRegisterTotal={cashRegisterTotal} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex flex-column-fluid">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-8">
                            <div className="card card-custom  card-stretch">
                                <div className="card-header border-0 pt-5">
                                    <div className="card-title">
                                        <div className="card-label">
                                            <div className="font-weight-bolder">Informacion actual de sucursal</div>
                                            <div className="font-size-sm text-muted mt-2">890,344 Sales</div>
                                            <div className="font-size-sm text-muted mt-2">890,344 Sales</div>
                                            <div className="font-size-sm text-muted mt-2">890,344 Sales</div>
                                            <div className="font-size-sm text-muted mt-2">890,344 Sales</div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4">
                            <div className="row">
                                <div className="col-xl-6">
                                    <div className="card card-custom  card-stretch bg-primary" onClick={() => handleView('/Customers')}>
                                        <div className="card-header border-0 pt-5">
                                            <div className="card-title">
                                                <div className="card-label">
                                                    <div className="font-weight-bolder">Clientes</div>
                                                    <div className="font-size-sm text-muted mt-2">totales - {counterCustomers}  </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6">
                                    <div className="card card-custom  card-stretch bg-secondary" onClick={() => handleView('/Loans')}>
                                        <div className="card-header border-0 pt-5">
                                            <div className="card-title">
                                                <div className="card-label">
                                                    <div className="font-weight-bolder">préstamos</div>
                                                    <div className="font-size-sm text-muted mt-2">totales - {counterLoans}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                {hasAdminAccess?
                                    <div className="col-xl-6">
                                    <div className="card card-custom  card-stretch bg-info" onClick={ () => handleView('/Employees')}>
                                        <div className="card-header border-0 pt-5">
                                            <div className="card-title">
                                                <div className="card-label">
                                                    <div className="font-weight-bolder">Empleados</div>
                                                    <div className="font-size-sm text-muted mt-2">totales - {counterEmployees}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : null}
                                <div className="col-xl-6">
                                    <div className="card card-custom  card-stretch">
                                        <div className="card-header border-0 pt-5">
                                            <div className="card-title">
                                                <div className="card-label">
                                                    <div className="font-weight-bolder">otra info</div>
                                                    <div className="font-size-sm text-muted mt-2">890,344 Sales</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                                <div className="col-xl-6">
                                    <div className="card card-custom  card-stretch">
                                        <div className="card-header border-0 pt-5">
                                            <div className="card-title">
                                                <div className="card-label">
                                                    <div className="font-weight-bolder">Clientes morosos</div>
                                                    <div className="font-size-sm text-muted mt-2">totales - {counterEmployees}</div>
                                                    <div className="font-size-sm text-muted mt-2">Lista con solo los morosos - TBD</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6">
                                    <div className="card card-custom  card-stretch">
                                        <div className="card-header border-0 pt-5">
                                            <div className="card-title">
                                                <div className="card-label">
                                                    <div className="font-weight-bolder">algo que se les ocurra</div>
                                                    <div className="font-size-sm text-muted mt-2">otra lista con algo importantisimo</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                </div>
            </div>
        </div>

    )
}


export default CashRegister;