import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { NovaContext } from "../../components/context";
import { calculateDueDate } from "../../components/Helper/LoanHelpers";
import { addHours, format, formatISO, getDay } from "date-fns";
import {
  checkAccess,
  getTimeDifferenceInHours,
} from "../../components/Helper/GlobalHelpers";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  Grid2 as Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import {
  CustomDialog,
  CustomSwitch,
  ItemPaper,
} from "../../components/Helper/MUIStyledComponents";
import ValidateLoan from "../../components/validation/validateLoan";
import ValidateCustomer from "../../components/validation/validateCustomer";
import { loanStatus, WeekDayDueToPayment } from "../../components/Helper/Enums";
import { alerts, displayAlert } from "../../components/Helper/AlertToastify";

// import ValidateLoan from "../validation/validateLoan";
// import ValidateCustomer from "../validation/validateCustomer";

const Loan_New = (props) => {
  const {
    customer,
    customerLoanStatus,
    currentLoanLimit,
    nextEligibleLimit,
    setNewCreditLimit,
    open,
    toggleDialog,
  } = props;

  const { actions, filial, authenticatedUser, currentRole } = useContext(
    NovaContext
  );
  const navigate = useNavigate();

  const [dueDay, setDueDay] = useState("1");
  const [status, setStatus] = useState("OPEN");
  const [nextValue, setNextValue] = useState(0);
  const [amount, setAmount] = useState(0);
  const [password, setPassword] = useState("");
  const [extendFlag, setExtendFlag] = useState(false);
  const [todaysDay, setTodaysDay] = useState();
  const [lockStatus, setLockStatus] = useState(false);
  const [errors, setErrors] = useState([]);

  // const isNewMode = !loanId && customerLoanStatus === "INACTIVE";
  const hasAdminAccess = checkAccess(currentRole, "Administrador");
  const validateLoan = new ValidateLoan();
  const validateCustomer = new ValidateCustomer();
  // Set up refs for the form
  const currentDate = addHours(new Date(), +getTimeDifferenceInHours());

  var dueDate = calculateDueDate(currentDate, dueDay, extendFlag);
  console.log("fecha de pago:", dueDate, nextEligibleLimit);

  const checkForErrors = (fieldId) => {
    const nameError = errors.find((error) => error.label === fieldId);
    return nameError !== undefined ? nameError.message : "";
  };

  const initializeForm = () => {
    setAmount(currentLoanLimit);
    setPassword("");
    setDueDay("1");
    setStatus("OPEN");
    setErrors([]);
    const currentDate = addHours(new Date(), +getTimeDifferenceInHours());
    setTodaysDay(getDay(currentDate));
    // setExtendFlag(false);
    calculateDueDate(currentDate, dueDay);
    if (nextEligibleLimit !== null) {
      setNextValue(nextEligibleLimit);
    } else {
      setNextValue(currentLoanLimit);
    }
  };

  useEffect(() => {
    if (customer) {
      initializeForm();
    } else {
      clearFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer]);

  const closeDialog = () => {
    toggleDialog(false);
    clearFields();
    setExtendFlag();
  };

  const clearFields = () => {
    setAmount(currentLoanLimit ? currentLoanLimit : 0);
    setPassword("");
    setDueDay("1");
    // setStatus("OPEN");
    setErrors([]);
    // setNextValue(0);
    // initializeForm();
  };

  const handleView = (string) => {
    const path = `${string}`;
    navigate(path);
  };

  const handleExtendDueDate = () => {
    setExtendFlag(!extendFlag);
  };

  const handleChange = (event, section) => {
    const value = event.target.value;
    switch (section) {
      case "dueDay":
        setDueDay(value);
        break;
      case "status":
        setStatus(value);
        break;
      default:
        break;
    }
  };

  const validateData = async () => {
    // reset error fields
    setErrors([]);
    let isValid = true;

    const filialObj = await actions.getFilial(filial);

    // validations for the Amount of the Loan
    // 1st loan of customer cannot be more than the initialloanvalue
    const firstLoanMsg = validateLoan.isValidFirstLoan(
      amount,
      customer.amountofloans,
      filialObj.initialloanvalue
    );

    const nextInitialMaxValue =
      filialObj.initialloanvalue > nextValue
        ? filialObj.initialloanvalue
        : nextValue;

    const denominator =
      nextEligibleLimit !== null ? nextEligibleLimit : nextInitialMaxValue;
    const withinLimits = validateLoan.validateAmount(amount, denominator);

    const validateAmount = validateLoan.validateAmount(
      amount,
      filialObj.maxloanvalue
    );
    if (firstLoanMsg !== "") {
      setErrors((arr) => [...arr, { label: "amount", message: firstLoanMsg }]);
      isValid = false;
    } else if (withinLimits !== "") {
      setErrors((arr) => [...arr, { label: "amount", message: withinLimits }]);
      isValid = false;
    } else if (validateAmount !== "") {
      setErrors((arr) => [
        ...arr,
        { label: "amount", message: validateAmount },
      ]);
      isValid = false;
    }

    // validation of the password input ( using a Regex )
    const validatePasswordMsg = validateCustomer.validatePassword(password);
    if (validatePasswordMsg !== "") {
      // const passwordErrorMsg = 'La Contraseña tiene que ser de 4 digitos'
      setErrors((arr) => [
        ...arr,
        { label: "password", message: validatePasswordMsg },
      ]);
      isValid = false;
    } else {
      const passValid = await actions.validateCustomerPassword(
        password,
        customer.customer_id
      );
      if (!passValid) {
        const passwordErrorMsg = "La Contraseña no es válida.";
        setErrors((arr) => [
          ...arr,
          { label: "password", message: passwordErrorMsg },
        ]);
        isValid = false;
      }
    }

    if (!dueDay || dueDay === 0) {
      const dueDayErrorMsg = "El día de pago es incorrecto.";
      setErrors((arr) => [
        ...arr,
        { label: "dueDay", message: dueDayErrorMsg },
      ]);
      isValid = false;
    }

    return isValid;
  };

  const handleCreate = async (e) => {
    e.preventDefault();
    if (lockStatus) {
      console.log("La aplicación está procesando la solicitud");
    } else {
      setLockStatus(true);
      // console.log(extendFlag);
      // var dueDate = calculateDueDate(currentDate, dueDay, extendFlag);

      // validate amount and check for value limit of 1st loan
      // this method will add messages to error array if they exist
      const dataValid = await validateData();
      if (dataValid) {
        const tempLoan = {
          amount: amount,
          dateIssue: formatISO(currentDate),
          dateChange: formatISO(currentDate),
          dueDay: dueDay,
          dateDue: formatISO(dueDate),
          status: status,
          employeeId: authenticatedUser.employee_id,
          customerId: customer ? customer.customer_id : "",
          filialId: filial,
        };
        // add if/else to see if errors exists. If so then don't allow closure of pop up
        const newLoan = await actions.addLoan(tempLoan);

        if (newLoan) {
          displayAlert(alerts.SUCCESS, "Préstamo creado con éxito");
        } else {
          displayAlert(alerts.ERROR, "Algo ha fallado en la solicitud");
        }

        if (newLoan) {
          // if loan amount is bigger or equal than the current max loan value then do nothing
          if (
            parseInt(tempLoan.amount) >= parseInt(customer.currentmaxloanvalue)
          ) {
            // if loan amount is equal to current max loan then increase counter by one
            if (
              parseInt(tempLoan.amount) ===
              parseInt(customer.currentmaxloanvalue)
            ) {
              setNewCreditLimit(
                customer.currentmaxloanvalue,
                customer.counteratloanvalue + 1
              );
            } else {
              // getting this far indicates that the current loan value has increased
              // in which case the value needs to be reset to 1
              setNewCreditLimit(nextValue, 1);
            }
          }
          closeDialog();
          handleView(`/Ticket/${newLoan.loan_id}`);
        } else {
          console.log("Loan create/update failed");
        }
      }
      setLockStatus(false);
    }
  };

  return (
    <form onSubmit={handleCreate}>
      {customer ? (
        <CustomDialog
          open={open}
          onClose={closeDialog}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            <Grid container alignItems={"center"}>
              <Grid size={10} align="left">
                <Typography variant="h5" align="left">
                  <Box sx={{ fontWeight: "bold" }}>Agregar préstamo</Box>
                </Typography>
              </Grid>
              <Grid size={2} align="right">
                <IconButton onClick={() => closeDialog()}>
                  <CloseIcon fontSize="medium" color="primary" />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <Divider />
          <DialogContent>
            {/* <DialogContentText> */}
            <Grid container>
              <ItemPaper>
                <Grid container spacing={2}>
                  <Grid size={12} align="left">
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        mb: 1,
                      }}
                    >
                      <Typography
                        variant="h5"
                        align="left"
                        sx={{
                          marginLeft: 2,
                          textTransform: "uppercase",
                          fontWeight: "bold",
                        }}
                      >
                        {customer.customer_name} {customer.customer_lastname}{" "}
                        (ID:
                        {customer.customer_idnumber})
                      </Typography>
                    </Box>
                  </Grid>
                  {nextEligibleLimit !== null ? (
                    <Grid
                      size={12}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Typography variant="body1" align="left" component="div">
                        <Box sx={{ m: 0.5 }}>
                          {" "}
                          Valor de préstamo recomendado:
                        </Box>
                      </Typography>
                      <Typography variant="body1" align="left" component="div">
                        <Box sx={{ fontWeight: "bold", m: 0.5 }}>
                          {" "}
                          ${nextValue}
                        </Box>
                      </Typography>
                    </Grid>
                  ) : null}

                  <Grid size={12}>
                    <TextField
                      label="Cantidad"
                      name="Cantidad"
                      value={amount}
                      onChange={(e) => setAmount(e.target.value)}
                      fullWidth
                      size="small"
                      type="number"
                      variant={"outlined"}
                      slotProps={{
                        input: {
                          // readOnly: openTotalDebit <= 0, // Dynamically enable/disable edit
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        },
                        htmlInput: {
                          step: 100, // Step logic enforced
                          min: 0, // Minimum value
                        },
                        inputLabel: { shrink: true },
                      }}
                      helperText={checkForErrors("amount")}
                      error={checkForErrors("amount") ? true : false}
                    />
                  </Grid>
                  <Grid size={12}>
                    <TextField
                      id="dueDay"
                      label="Día de pago"
                      sx={{ textAlign: "left" }}
                      value={dueDay}
                      onChange={(e) => handleChange(e, "dueDay")}
                      slotProps={{ shrink: true }}
                      size="small"
                      select
                      fullWidth
                      helperText={checkForErrors("dueDay")}
                      error={checkForErrors("dueDay") ? true : false}
                    >
                      <MenuItem name="0" value="">
                        -- Seleccionar --
                      </MenuItem>
                      {WeekDayDueToPayment &&
                        WeekDayDueToPayment.map((item, index) => {
                          return (
                            <MenuItem
                              key={index}
                              name={item.label}
                              value={item.value}
                            >
                              {item.label}
                            </MenuItem>
                          );
                        })}
                    </TextField>
                  </Grid>

                  <Grid size={12}>
                    <TextField
                      name="password"
                      label="Contraseña del Cliente"
                      type="password"
                      value={password}
                      autoComplete="off" // Prevents browser autofill
                      onChange={(e) => setPassword(e.target.value)}
                      fullWidth
                      variant={"outlined"}
                      slotProps={
                        {
                          // inputLabel: { shrink: true },
                        }
                      }
                      size="small"
                      helperText={checkForErrors("password")}
                      error={checkForErrors("password") ? true : false}
                    />
                  </Grid>
                  <Grid size={7}>
                    {todaysDay === 5 || todaysDay === 6 || todaysDay === 0 ? (
                    // {true ? (
                      <FormControlLabel
                        control={
                          <CustomSwitch
                            // checked={!creditLimitBlock}
                            onChange={() => handleExtendDueDate()}
                          />
                        }
                        label={
                          <Typography variant="h6">{"Posponer"}</Typography>
                        }
                        labelPlacement={"end"}
                        sx={{
                          justifyContent: "flex-start", // Align to the left
                          width: "100%", // Ensure it spans the full width of the container
                          ml:1
                        }}
                      />
                    ) : null}
                  </Grid>

                  <Grid size={5}>
                    <Box>
                      <Typography variant="h6" align="right">
                        {`Nueva Fecha de pago:`}
                      </Typography>
                      <Typography
                        variant="h5"
                        align="right"
                        color="primary"
                        // sx={{ marginLeft: 1 }}
                      >
                        {dueDate ? format(dueDate, "dd/MM/yyyy") : "---"}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </ItemPaper>
            </Grid>
            {/* </DialogContentText> */}
          </DialogContent>
          <Divider />

          <DialogActions>
            {extendFlag ? (
              <Typography variant="h6" align="left" component="div">
                <Box sx={{ mr: 2, color: "blue" }}> Posponiendo 1 Semana</Box>
              </Typography>
            ) : null}

            <Button
              onClick={handleCreate}
              color="success"
              variant="contained"
              disabled={lockStatus}
            >
              Prestar
            </Button>
            <Button
              autoFocus
              onClick={closeDialog}
              variant="outlined"
              disabled={lockStatus}
            >
              Cancelar
            </Button>
          </DialogActions>
        </CustomDialog>
      ) : null}
    </form>
  );
};
export default Loan_New;
