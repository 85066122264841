import React from "react";
import { ExpenseProvider } from "../context/ExpensesContext";

// component
import ExpensesList from "../../pages/ExpensesOverview/components/ExpensesList";

const ExpensesListWrapper = ({
  columnVisibilityModel,
  sortingFilter,
  customerId,
  employeeId,
  boxHeight
}) => {
  return (
    <ExpenseProvider
    // sortingFilter={sortingFilter}
    // customerIdFilter={customerId}
    // employeeIdFilter={employeeId}
    >
      <ExpensesList columnVisibilityModel={columnVisibilityModel} height={boxHeight}/>
    </ExpenseProvider>
  );
};

export default ExpensesListWrapper;
