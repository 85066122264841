import React, { useState, useContext, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  calculateReportDates,
  getTimeDifferenceInHours,
} from "../../components/Helper/GlobalHelpers";

import {
  CircularProgressWithLabel,
  ItemPaper,
  ItemPaperHeader,
} from "../../components/Helper/MUIStyledComponents";

// Date fns
import addHours from "date-fns/addHours";
import format from "date-fns/format";

// Internal imports
import { NovaContext } from "../../components/context";

// Component imports
import FilialPopover from "./FilialPopover";
import ListExpenses from "../../components/Actions/ListExpenses";
import ListLoansBySearch from "../../components/Actions/ListLoanBySearch";
import Loading from "../../components/Actions/Loading";

// Page imports
import NewExpense from "../../components/popups/NewExpenseV3";
import NewCashFlow from "../../components/popups/NewCashFlowV4";
import NewPayCheck from "../../components/popups/NewPayCheckV3";

// MUI imports
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import CashRegisterTotal from "../../components/CashRegisterTotal";
import FilialGoalItem from "./FilialGoalItem";
import ExpensesListWrapper from "../../components/Wrappers/ExpensesListWrapper";
import { columnVisibilityModelExpensesFilialPage } from "../../components/Helper/DataGrid/ColumnDefinitions/Columns_Expenses";
import LoansListWrapper from "../../components/Wrappers/LoansListWrapper";
import {
  columnVisibilityModelLoanFilialDetailsPager,
  visibilityModelLoanFilialDetailsPager,
} from "../../components/Helper/DataGrid/ColumnDefinitions/Columns_Loans";

const Filial = () => {
  const { actions, filial, currentRole } = useContext(NovaContext);

  const [counterDelayedLoans, setCounterDelayedLoans] = useState(0);
  const [CashRegisterReport, setCashRegisterReport] = useState(0);
  const [counterCustomers, setCounterCustomers] = useState("");
  const [counterActiveCustomers, setCounterActiveCustomers] = useState(0);
  const [currentDateTime, setCurrentDateTime] = useState();
  const [reportTotalData, setReportTotalData] = useState({});
  const [currentFilial, setFilial] = useState("");
  const [arrearTotal, setArrearTotal] = useState(0);
  const [reportMode, setReportMode] = useState("WEEK");

  const [loans, setLoans] = useState([]);
  const filterStatus = "OPEN";
  const boxHeight = "85vh";

  // Utility states
  let navigate = useNavigate();
  let currentPath = useLocation();
  const params = useParams();

  const [lockStatus, setLockStatus] = useState(false);

  // Modal states
  const [expenseModal, setExpenseModal] = useState(false);
  const [cashFlowModal, setCashFlowModal] = useState(false);
  const [payCheckModal, setPayCheckModal] = useState(false);

  // Expense state/methods
  const [expenses, setExpenses] = useState([]);

  const updateExpenses = (newExpense) => {
    setExpenses(() => [...expenses, newExpense]);
  };

  useEffect(() => {
    setCurrentDateTime(format(new Date(), "dd/MM/yyyy HH:mm:ss") + " hrs");
    const currentDate = addHours(new Date(), +getTimeDifferenceInHours());

    const fetchData = async () => {
      const filialId = params.id;
      await actions.setActiveFilial(filialId);

      const filialData = await actions.getFilial(filialId);
      setFilial(filialData);

      const customerCount = await actions.countCustomers(filialId);
      setCounterCustomers(customerCount);

      const activeCustomerCount = await actions.countActiveCustomers();
      setCounterActiveCustomers(activeCustomerCount);

      const arrearTotal = await actions.sumLoansRemaining(currentDate);
      setArrearTotal(arrearTotal);

      const cashRegisterReport = await actions.getCurrentCashRegisterReport();
      setCashRegisterReport(cashRegisterReport);

      const loansOpen = await actions.getLoansOpen(currentDate);
      setLoans(loansOpen);

      const delayedLoansCount = await actions.countLoans("OPEN", currentDate);
      setCounterDelayedLoans(delayedLoansCount);

      const dateFilter = new Date();
      dateFilter.setDate(dateFilter.getDate() - 30);
      const expensesData = await actions.getExpenses(null, dateFilter);
      setExpenses(expensesData);

      const DataRange = calculateReportDates(reportMode, currentDate);
      DataRange.filialId = filialId;
      const reportData = await actions.getReports(DataRange);
      setReportTotalData(reportData);
    };
    fetchData();
  }, [filial, params.id, currentPath]);

  // const calculateGoalPercentage = () => {
  //   if (
  //     CashRegisterReport &&
  //     reportTotalData &&
  //     CashRegisterReport.weeklygoal !== 0 &&
  //     reportTotalData.interest
  //   ) {
  //     const Percentage =
  //       (reportTotalData.interest / CashRegisterReport.weeklygoal) * 100;
  //     return Percentage.toFixed(3);
  //   }
  //   return 0;
  // };

  const calculateRisk = () => {
    if (
      arrearTotal &&
      CashRegisterReport &&
      CashRegisterReport.openloanstotal
    ) {
      const Percentage =
        (arrearTotal / CashRegisterReport.openloanstotal) * 100;
      return Percentage.toFixed(3);
    }
    return 0;
  };

  // const calculateArrearPaid = () => {
  //   if (
  //     CashRegisterReport &&
  //     CashRegisterReport.arrearstotal &&
  //     CashRegisterReport.weeklygoal !== 0
  //   ) {
  //     const Percentage =
  //       (CashRegisterReport.arrearstotal / CashRegisterReport.weeklygoal) * 100;
  //     return Percentage.toFixed(3);
  //   }
  //   return 0;
  // };

  const handleView = (string) => {
    const path = `${string}`;
    navigate(path);
  };

  return (
    <Grid container sx={{ padding: { xs: " 10px", md: "25px" }, flexGrow: 1 }}>
      <NewExpense
        open={expenseModal}
        setOpen={setExpenseModal}
        updateExpenses={updateExpenses}
      />
      <NewCashFlow open={cashFlowModal} setOpen={setCashFlowModal} />
      <NewPayCheck open={payCheckModal} setOpen={setPayCheckModal} />

      <div
        className={`over-page-loading ${lockStatus ? "show-loading" : "hide-loading"
          }`}
      >
        <div className="over-page-loading-content">
          <Loading
            text={"Cargando..."}
            classNames={`over-page-loading ${lockStatus ? "show-loading" : "hide-loading"
              }`}
          />
        </div>
      </div>

      <Grid container spacing={2}>
        <Grid size={{ xs: 8, sm: 7, md: 7, lg: 8 }}>
          <Typography align="left" component="div">
            <Box sx={{ fontSize: "2.5em", fontWeight: "bold", m: 0.3 }}>
              Sucursal {currentFilial.filial_name}
            </Box>
            <Box sx={{ m: 0.3 }}>Tel.- {currentFilial.telephone}</Box>

            <Box sx={{ m: 0.3 }}>{currentDateTime}</Box>
          </Typography>
        </Grid>
        <Grid
          size={{ xs: 4, sm: 2 }}
          display="flex"
          justifyContent="right"
          alignItems="center"
        >
          <Box>
            <FilialPopover
              setExpenseModal={setExpenseModal}
              setCashFlowModal={setCashFlowModal}
              setPayCheckModal={setPayCheckModal}
            />
          </Box>
        </Grid>
        <Grid
          size={{ xs: 12, sm: 3, lg: 2 }}
          display="flex"
          justifyContent="right"
          sx={{ marginTop: { xs: "-16px", sm: "initial" } }}
        >
          <CashRegisterTotal />
        </Grid>

        <Grid size={{ xs: 6, sm: 4, md: 3 }}>
          <FilialGoalItem filialId={filial} />
        </Grid>
        <Grid size={{ xs: 6, sm: 8, md: 3 }}>
          <ItemPaper>
            <Typography
              sx={{ fontWeight: "bold", fontSize: { xs: 25, md: 32 } }}
            >
              Información
            </Typography>
            <Divider />
            {/* <Box sx={{ display: 'flex',  flexDirection: 'column'}}> */}
            <Box direction="column" alignItems="center" justifyContent="center">
              <Typography variant="h5" sx={{ mt: 1, mb: 1 }}>
                Teléfono: {currentFilial.telephone}
              </Typography>
              <Typography variant="h5">
                Dirección: {currentFilial.filial_address}
              </Typography>
            </Box>
          </ItemPaper>
        </Grid>
        <Grid size={{ xs: 6, sm: 6, md: 3 }}>
          <ItemPaper
            onClick={() => handleView("/Customers")}
            sx={{ cursor: "pointer" }}
          >
            <ItemPaperHeader variant="h3">Clientes</ItemPaperHeader>
            <Divider />
            <Typography variant="h5">Totales - {counterCustomers}</Typography>
            <Typography variant="h5">
              Activos - {counterActiveCustomers}
            </Typography>
            <Typography variant="h5">
              En mora - {counterDelayedLoans}
            </Typography>
            <Typography variant="h5">Riesgo - ${arrearTotal}</Typography>
            <Typography variant="h5">{calculateRisk()}% en mora</Typography>
          </ItemPaper>
        </Grid>
        <Grid size={{ xs: 6, sm: 6, md: 3 }}>
          <ItemPaper
            onClick={() => handleView("/Loans")}
            sx={{ cursor: "pointer" }}
          >
            <Typography variant="h3" sx={{ fontWeight: "bold" }}>
              Créditos
            </Typography>
            <Divider />
            <Typography variant="h5">
              Nuevos - {CashRegisterReport.counternewloans}
            </Typography>
            <Typography variant="h5">
              Renovados - {CashRegisterReport.counterrenewals}
            </Typography>
            <Typography variant="h5">Cancelados - 0</Typography>
            <Typography variant="h5">
              Totales - {CashRegisterReport.counterloantotal}
            </Typography>
          </ItemPaper>
        </Grid>
        <Grid size={{ xs: 12, sm: 4, md: 4 }}>
          <ItemPaper>
            {/* <Box height={boxHeight} width="100%"> */}
            <Typography variant="h3" sx={{ fontWeight: "bold" }}>
              Gastos
            </Typography>
            <Divider />
            {/* <ListExpenses expenses={expenses} pageReportMode={false} /> */}
            <ExpensesListWrapper
              columnVisibilityModel={columnVisibilityModelExpensesFilialPage}
              height={boxHeight}
            />
            {/* </Box> */}
          </ItemPaper>
        </Grid>
        <Grid size={{ xs: 12, sm: 8, md: 8 }}>
          {/* <Box height={boxHeight} width="100%"> */}
          <ItemPaper>
            <Typography variant="h3" sx={{ fontWeight: "bold" }}>
              Clientes morosos
            </Typography>
            <Divider />
            {/* <ListLoansBySearch
                loans={loans}
                filterStatus={filterStatus}
                pageReportMode={false}
                setLockStatus={setLockStatus}
              /> */}
            <LoansListWrapper
              columnVisibilityModel={visibilityModelLoanFilialDetailsPager}
              statusFilter={"OPEN"}
              sortingFilter={"lo.loan_datedue ASC"}
              boxHeight={boxHeight}
              startDateFilter={true}
              endDateFilter={true}
              hideDateRange={true}
            // sortingFilter={"cus.delayedWeeks DESC"}
            />
          </ItemPaper>
          {/* </Box> */}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Filial;
