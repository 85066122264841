import React, { useState, useRef, useContext } from 'react';
import PropTypes from 'prop-types';

// Material UI import
import Button from '@mui/material/Button';
import { styled, createTheme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/system/Box';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid2';
import ListItemIcon from '@mui/material/ListItemIcon';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import ErrorIcon from '@mui/icons-material/Error';
import Camera from '@mui/icons-material/CameraAlt';
import Edit from '@mui/icons-material/Edit';

// Misc import
import { addHours, format } from 'date-fns';
import { getTimeDifferenceInHours } from '../../components/Helper/GlobalHelpers';
import { encode } from '../../components/Helper/blob';

// Internal import
import { NovaContext } from '../../components/context';
import { alerts, displayAlert } from '../../components/Helper/AlertToastify';
import { uploadDestination } from '../../components/Helper/MediaHelpers';
import { createMedia } from '../../utils/api/api_document';

const theme = createTheme({
  components: {
    roundButton: {
      borderRadius: "50%",
      padding: "5px 5px",
      minWidth: "auto",
    },
  },
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};


const Image_NewEdit = (props) => {
  const [open, setOpen] = React.useState(false);
  const [path, setPath] = useState("");
  const [selectedFile, setSelectedFile] = useState('');
  const [lock, setLock] = useState(false);
  const [errors, setErrors] = useState([]);

  // props
  const {
    buttonTitle,
    photo,
    customerId,
    objectUuid,
    updateProfile,
    isProfile,
    roundButton,
    destination
  } = props;

  const { actions, authenticatedUser, filial } = useContext(NovaContext);
  let fileRef = useRef();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    fileRef.current.value = "";
    setPath("");
    setSelectedFile("");
    setOpen(false);
  };

  const handleSelection = (e) => {
    e.preventDefault();
    setPath(fileRef.current.value);
    const uploadedImage = e.target.files[0];
    setSelectedFile(uploadedImage);
  }

  const validateUpload = () => {
    // check if path has been uploaded
    if (fileRef.current.value === undefined) {
      errors.push("No se ha detectado ningún archivo de la captura de imágenes");
      return false;
    }

    // Check if file extension is correct
    const filePath = fileRef.current.value;
    const fileExtension = filePath.substring(filePath.lastIndexOf('.'));
    if (fileExtension === ".jpg" ||
      fileExtension === ".JPG" ||
      fileExtension === ".jpeg" ||
      fileExtension === ".JPEG" ||
      fileExtension === ".png" ||
      fileExtension === ".PNG") {
      return true;
    } else {
      errors.push("Tipo de archivo no válido. Sólo se permiten jpg, jpeg o png");
      return false
    }
  }

  const handleUpload = async (e) => {
    e.preventDefault();
    setLock(true);
    let finalProfileImage = {
      customerId: customerId, //31.10.24 - removed to test
      employeeId: authenticatedUser.employee_id,
      filialId: filial,
      isProfile: isProfile,
      mediaType: "IMAGE",
      dateCreated: format(addHours(new Date(), +getTimeDifferenceInHours()), "yyyy-MM-dd HH:mm:ss")
    };

    // Reset errors
    setErrors([]);

    if (validateUpload()) {
      // finalise image path
      const imagePath = fileRef.current.value;
      finalProfileImage.imageName = imagePath.substring(imagePath.lastIndexOf('\\') + 1);

      switch (destination) {
        case uploadDestination.CUSTOMER:
          finalProfileImage = { ...finalProfileImage, customerId: objectUuid }
        case uploadDestination.EMPLOYEE:
          // add a new column -> foremployee_id
          // change this so it isn't confusion
          // add documentation to highlight what objectUuid can be
          finalProfileImage = { ...finalProfileImage, forEmployeeId: objectUuid }
      }

      // finalse image payload
      const newImage = {
        image: selectedFile,
        otherinfo: JSON.stringify(finalProfileImage)
      };

      // send new image to backend and set new profile picture
      await
        actions.createMedia(newImage)
          .then(resp => {
            if (resp && (toString(resp.status) === toString(200))) {
              displayAlert(alerts.SUCCESS, "Imagen cargada correctamente");
              if (isProfile) {
                updateProfile(resp.data)
              } else {
                // NOTE! -> This method doesn't call the same method as above. 
                // This updates an array of images while the other method
                // replaced the profile photo.
                updateProfile(resp.data, "ADD")
              }
            }

          })
          .catch(resp => {
            displayAlert(alerts.ERROR, "Algo ha fallado en la solicitud");
            errors.push(resp);
          });

      // send new image to backend and set new profile picture (if applicable)
      // commented out 10.11.24 to make the code backwards compatible
      // await createMedia("image", newImage)
      //   .then(resp => {
      //     console.log("test: ", resp)
      //     displayAlert(alerts.SUCCESS, "Imagen cargada correctamente");
      //     if (isProfile) {
      //       updateProfile(resp)
      //     } else {
      //       updateProfile(resp, "ADD")
      //     }
      //   })
      //   .catch(resp => {
      //     displayAlert(alerts.ERROR, "Algo ha fallado en la solicitud");
      //     errors.push(resp);

      //   })
    }

    // Unlock buttons
    setLock(false);

    if (errors.length === 0) {
      handleClose();
      setSelectedFile("");
    }
  }

  return (
    <Box
      sx={{ ml: { xs: 0.5, md: 0.5 } }}
    >
      <Tooltip
        title={buttonTitle}
        placement="top"
        arrow={true}
      >
        {roundButton ?
          <Button
            variant="contained"
            color='primary'
            onClick={handleClickOpen}
            fullWidth
            sx={theme.components.roundButton}
          >
            {
              photo ?
                <Edit />
                :
                <Camera />
            }
          </Button>
          :
          <Button
            variant="contained"
            color="primary"
            onClick={handleClickOpen}
            // fullWidth //31.10 removed to test
            size='small'
          >
            {buttonTitle}
          </Button>
        }
      </Tooltip>

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="sm"
        fullWidth
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          {buttonTitle}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Grid container>
            <Grid size={8} paddingRight={"5px"}>
              <TextField
                label="Documento"
                name="document-upload"
                value={path}
                slotProps={{ shrink: path ? true : false }}
                disabled
                size='small'
                fullWidth
              />
            </Grid>
            <Grid size={4} display={'flex'} justifyContent={'flex-end'}>
              <Button
                variant="contained"
                component="label"
                sx={{ marginRight: "1rem" }}
                disabled={lock}
              >
                Cargar documento
                <input
                  type="file"
                  accept="image/png, image/jpeg"
                  hidden
                  ref={fileRef}
                  onChange={handleSelection}
                />
              </Button>
            </Grid>
            {
              errors && errors.length > 0 ?
                <Grid size={12}>
                  <List dense>
                    {errors.map(error => (
                      <ListItem>
                        <ListItemIcon>
                          <ErrorIcon color='error' />
                        </ListItemIcon>
                        <ListItemText primary={error} />
                      </ListItem>
                    ))}
                  </List>
                </Grid>
                : ""
            }

          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            color='success'
            variant='contained'
            disabled={lock}
            onClick={(e) => handleUpload(e)}>
            Guardar
          </Button>
          <Button
            autoFocus
            variant="outlined"
            disabled={lock}
            onClick={handleClose}>
            Cancelar
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </Box>
  );
}

Image_NewEdit.propTypes = {
  buttonTitle: PropTypes.string,
  photo: PropTypes.object,
  customerId: PropTypes.string,
  updateProfile: PropTypes.func
}

export default Image_NewEdit;

// Form components