import React from "react";
import { LoansProvider } from "../context/LoansContext";

// component
import LoansList from "../../pages/LoansHistory/components/LoansList";

const LoansListWrapper = ({
  columnVisibilityModel,
  sortingFilter,
  customerId,
  employeeId,
  statusFilter,
  boxHeight,
  startDateFilter,
  endDateFilter,
  hideDateRange
}) => {
  return (
    <LoansProvider
      statusFilter={statusFilter}
      sortingFilter={sortingFilter}
      customerIdFilter={customerId}
      employeeIdFilter={employeeId}
      startDateFilter={startDateFilter}
      endDateFilter={endDateFilter}
    >
      <LoansList
        columnVisibilityModel={columnVisibilityModel}
        boxHeight={boxHeight}
        hideDateRange={hideDateRange}
      />
    </LoansProvider>
  );
};

export default LoansListWrapper;
