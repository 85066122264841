import Cookies from "js-cookie";

export const setCookie = ( name, value ) => {
    Cookies.set(name, value);
}

// modified 05.12
export const getCookie = async ( name ) => {
    // const rawState = localStorage.getItem(name);
    // const state = JSON.parse(rawState);
    // // console.log("source: ", source)
    // console.log("state: ", state)
    // if (state === null || state.accessToken === null ) {
    //     console.log("returning null")
    //     return null;
    // } else {
    //     console.log("returning token")
    //     return state.accessToken; 
    // }
    return Cookies.get(name);

}

export const getCookie_V2 = async ( name ) => {
    return Cookies.get(name);
}

export const removeCookie = ( name ) => {
    Cookies.remove(name);
}

