import React, { useContext, useMemo, useState } from "react";
import { NovaContext } from "../../components/context";

import { Grid2 as Grid, Button, Box } from "@mui/material";
// import EditIcon from '@mui/icons-material/Edit';
import { grey } from "@mui/material/colors";
import ActionChanges from "./ActionChanges";
import ActionOpenNewEdit from "./ActionOpenNewEdit";
import { GridToolbar, gridClasses } from "@mui/x-data-grid";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {
  CustomDataGrid,
  CustomGridToolbar,
} from "../../components/Helper/MUIStyledComponents";
import LoanIncrementForm from "./LoanIncrementFormV2";
// import { v4 as uuidv4 } from "uuid";
import { prepareLoanIncrementColumns } from "../../components/Helper/DataGrid/ColumnDefinitions/Columns_LoanIncrements";
import { useNavigate } from "react-router-dom";

const LoanIncrementList = (props) => {
  const {
    loanIncrements,
    // setItemForEdit,
    // setOpenForm,
  } = props;

  const { actions } = useContext(NovaContext);
  const navigate = useNavigate();

  const [rowId, setRowId] = useState(null);
  const [loanIncrementModal, setLoanIncrementModal] = useState(false);
  const [selectedLoanIncrement, setSelectedLoanIncrement] = useState();

  // const prepareColumns = () => {
  //   const columns = [
  //     {
  //       field: "value",
  //       headerName: "Valor de Crédito",
  //       flex: 1,
  //     },
  //     {
  //       field: "renewals",
  //       headerName: "# de Renovaciones",
  //       editable: true,
  //       flex: 1,
  //     },
  //     {
  //       field: "actions",
  //       type: "actions",
  //       width: 100,
  //       // renderCell: (params) => [
  //       //   <Box display="flex" alignItems={"center"}>
  //       //     <ActionOpenNewEdit
  //       //       params={params}
  //       //       setItemForEdit={setItemForEdit}
  //       //       setOpenForm={setOpenForm}
  //       //       icon={<EditOutlinedIcon />}
  //       //     />
  //       //     <ActionChanges params={params} rowId={rowId} setRowId={setRowId} />,
  //       //   </Box>,
  //       // ],
  //       renderCell: (params) => {
  //         const viewLoanIncrementDialog = (e) => {
  //           e.stopPropagation();
  //           handleClickLoanIncrement(params?.row);
  //         };
  //         return (
  //           <Box display="flex" alignItems={"center"}>
  //             <Tooltip id="button-customer-details" title="Ver ticket de pagos">
  //             <IconButton
  //               color="primary"
  //               variant="contained"
  //               size="small"
  //               data-target={`#id${params?.row.loan_id}`}
  //               onClick={viewLoanIncrementDialog}
  //             >
  //               {""}
  //               <RequestQuoteOutlinedIcon
  //                 variant="contained"
  //                 fontSize="large"
  //               />
  //             </IconButton>
  //             </Tooltip>
  //           </Box>
  //         );
  //       },
  //     },
  //   ];
  //   return [...columns];
  // };

  const addOrEditLoanIncrement = async (loanDetails) => {
    if (loanDetails.id === 0) {
      // await addExpense(newExpense)
      await actions
        .addLoanIncrement(loanDetails)
        .then((resp) => {
          // dispatchloanIncrement({
          //   type: LoanIncrementActions.CREATE_LOANINCREMENT,
          //   payload: resp,
          // });
          navigate(0);
        })
        .catch((err) => {
          console.log("An error occurred while creating an loanIncrement");
        });
      console.log("new");
    } else {
      await actions
        .updateLoanIncrement(loanDetails)
        .then((resp) => {
          // dispatchloanIncrement({
          //   type: LoanIncrementActions.SET_LOANINCREMENTS,
          //   payload: resp,
          // });
          navigate(0);
        })
        .catch((err) => {
          console.log("An error occurred while creating an loanIncrement");
        });
      console.log("cambio");
    }
  };

  return (
    <Grid container size={12}>
      <Box width="100%" height="500px">
        <CustomDataGrid
          columns={prepareLoanIncrementColumns(
            // loanIncrements,
            setSelectedLoanIncrement,
            setLoanIncrementModal
          )}
          rows={loanIncrements}
          getRowId={(row) => row.id}
          slots={{
            toolbar: CustomGridToolbar,
          }}
          slotProps={{
            toolbar: {
              component2: (
                <LoanIncrementForm
                  open={loanIncrementModal}
                  setOpen={setLoanIncrementModal}
                  // updateExpenses={handleCreateExpense}
                  showButton={true}
                  recordForEdit={selectedLoanIncrement}
                  addOrEditLoanIncrement={addOrEditLoanIncrement}
                />
              ),
            },
          }}
          onCellEditStop={(params) => {
            console.log("edit ", params);
            setRowId(params.id);
          }}
        />
      </Box>
    </Grid>
  );
};

export default LoanIncrementList;
