import React from "react";
import { Route, Navigate } from "react-router-dom";
import { Consumer } from "./context/index";
import { checkAccess } from "./Helper/GlobalHelpers";
import NoAccessComponent from "../pages/NoAccessComponent";
import { getCookie } from "../lib/cookiesjs/cookiesjs";
import { NOVACOOKIE } from "../utils/config/novacookies";
import Cookies from "js-cookie";

const PrivateRoute = ({ component: Component,requiredRole, ...rest }) => {

  return (
    <Consumer>
      {(context) => {
        // console.log(context);
        
        const currentRole = context.currentRole;
        const hasRequiredAccess = requiredRole ? checkAccess(currentRole, requiredRole) : true;
        // const hasRequiredAccess = true;
        // console.log(hasRequiredAccess);
        
        // note: remove this once the new token retrieval is stable
        const accessToken = context.accessToken;
        const cookieToken = Cookies.get(NOVACOOKIE)

        if (cookieToken !== accessToken) {
          Cookies.set(NOVACOOKIE, accessToken);
        }

        if (!context.actions.validate()) {
          return (
            <Navigate
              to={{
                pathname: "/login",
                state: { from: rest.location },
              }}
            />
          );
        }

        if (!hasRequiredAccess) {
          return <NoAccessComponent />;
        }

        return <Component {...rest} />;
      }}
    </Consumer>
  );
};

export default PrivateRoute;
