export const LoanActions = {
  SET_LOAN: "SET_LOAN",
  CREATE_LOAN: "CREATE_LOAN",
  REMOVE_LOAN: "REMOVE_LOAN",
};

export const LoanReducer = (state, action) => {
  switch (action.type) {
    case LoanActions.SET_LOAN:
      return action.payload;
    case LoanActions.CREATE_LOAN:
      return [action.payload, ...state];
    case LoanActions.REMOVE_LOAN:
      return state.filter((loan) => loan.loan_id !== action.payload);
    case LoanActions.UPDATE_LOAN:
      return state.map((loan) =>
        loan.loan_id === action.payload.loan_id
          ? { ...loan, ...action.payload }
          : loan
      ); // Updates a specific loan
    default:
      return state;
  }
};
