import React, { useMemo, useState } from "react";
import {
  Grid2 as Grid,
  Button,
  Box,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import {
  CustomDataGrid,
  CustomGridToolbar,
} from "../../components/Helper/MUIStyledComponents";
import TooltipIconButton from "../../components/controls/TooltipIconButton";
import { MdPlaylistRemove, MdRebaseEdit } from "react-icons/md";

// Predefined roles
const roles = [
  { label: "Empleado", value: "Employee" },
  { label: "Administrador", value: "Administrator" },
  { label: "Root", value: "Root" },
  { label: "Gerente", value: "Manager" },
  { label: "Auditor", value: "Auditor" },
  { label: "Gerente Regional", value: "RegionalManager" },
];

const boxHeight = 650;

const EmployeeUserRolesList = (props) => {
  const { objectsArray } = props;
  const [openDialog, setOpenDialog] = useState(false); // Dialog open/close state
  const [selectedRole, setSelectedRole] = useState(""); // Selected role state
  const [selectedRowId, setSelectedRowId] = useState(null); // The row that is being edited

  // Columns configuration for the grid
  const columns = useMemo(
    () => [
      {
        field: "filial_name",
        headerName: "Nombre",
        flex: 1,
        minWidth: 150,
      },
      {
        field: "role_name",
        headerName: "Permiso",
        flex: 1,
        minWidth: 150,
        // renderCell: (params) => {
        //   const triggerRoleSelectionPage = (e) => {
        //     e.stopPropagation();
        //     // setSelectedLoan(params?.row);
        //     // setConfirmationRemoveLoan(true);
        //   };
        //   const triggerRemoveRoleConfirmation = (e) => {
        //     e.stopPropagation();
        //     // setSelectedLoan(params?.row);
        //     // setConfirmationRemoveLoan(true);
        //   };

        //   return (
        //     <Box sx={{ display: "flex", alignItems: "center" }}>
        //       <Typography variant="body2" sx={{ marginRight: 2 }}>
        //         {params.row.role_name || "Sin rol"}{" "}
        //         {/* Show role name or "Sin rol" */}
        //       </Typography>
        //       <TooltipIconButton
        //         title="Editar permiso"
        //         onClick={triggerRoleSelectionPage}
        //         disabled={false}
        //         color="info"
        //         size="medium"
        //         variant="contained"
        //         icon={<MdRebaseEdit fontSize="large" />}
        //       />
        //       <TooltipIconButton
        //         title="Eliminar permiso"
        //         onClick={triggerRemoveRoleConfirmation}
        //         disabled={false}
        //         color="error"
        //         size="medium"
        //         variant="contained"
        //         icon={<MdPlaylistRemove fontSize="large" />}
        //       />
        //       {/* <Button
        //         variant="outlined"
        //         color="primary"
        //         sx={{ marginRight: 1 }}
        //         onClick={() => handleRoleChangeClick(params)}
        //       >
        //         Editar
        //       </Button>
        //       <Button
        //         variant="outlined"
        //         color="secondary"
        //         onClick={() => handleRemoveRole(params)}
        //       >
        //         Eliminar
        //       </Button> */}
        //     </Box>
        //   );
        // },
      },
      {
        field: "actions",
        type: "actions",
        width: 100,
        renderCell: (params) => {
          const triggerRoleSelectionPage = (e) => {
            e.stopPropagation();
            // setSelectedLoan(params?.row);
            // setConfirmationRemoveLoan(true);
          };
          const triggerRemoveRoleConfirmation = (e) => {
            e.stopPropagation();
            // setSelectedLoan(params?.row);
            // setConfirmationRemoveLoan(true);
          };

          return (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TooltipIconButton
                title="Editar permiso"
                onClick={triggerRoleSelectionPage}
                disabled={false}
                color="info"
                size="medium"
                variant="contained"
                icon={<MdRebaseEdit fontSize="large" />}
              />
              <TooltipIconButton
                title="Eliminar permiso"
                onClick={triggerRemoveRoleConfirmation}
                disabled={false}
                color="error"
                size="medium"
                variant="contained"
                icon={<MdPlaylistRemove fontSize="large" />}
              />
              {/* <Button
                variant="outlined"
                color="primary"
                sx={{ marginRight: 1 }}
                onClick={() => handleRoleChangeClick(params)}
              >
                Editar
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => handleRemoveRole(params)}
              >
                Eliminar
              </Button> */}
            </Box>
          );
        },
      },
    ],
    []
  );

  // Handle dialog opening and row selection for role change
  const handleRoleChangeClick = (params) => {
    setSelectedRowId(params.id); // Set the row being edited
    setSelectedRole(params.row.role_name); // Set the current role of the row
    setOpenDialog(true); // Open the dialog
  };

  // Handle role change confirmation
  const handleConfirmRoleChange = () => {
    // Update the role in the objectsArray based on selectedRowId and selectedRole
    const updatedRows = [...objectsArray];
    const rowIndex = updatedRows.findIndex((row) => row.id === selectedRowId);
    if (rowIndex !== -1) {
      updatedRows[rowIndex].role_name = selectedRole; // Update role name
    }
    // Update the parent or state with the new rows
    // For example, you could call props.onUpdateRows(updatedRows);
    setOpenDialog(false); // Close the dialog
  };

  // Handle dialog close without changing the role
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  // Handle role removal
  const handleRemoveRole = (params) => {
    const updatedRows = [...objectsArray];
    const rowIndex = updatedRows.findIndex((row) => row.id === params.id);
    if (rowIndex !== -1) {
      updatedRows[rowIndex].role_name = ""; // Remove role by setting to an empty string or null
    }
    // Update the parent or state with the new rows
    // For example, you could call props.onUpdateRows(updatedRows);
  };

  return (
    <Box sx={{ width: "100%", height: "100%", overflowX: "auto" }}>
      <Box height={boxHeight} width="100%">
        <CustomDataGrid
          slots={{
            toolbar: CustomGridToolbar,
          }}
          rows={objectsArray}
          columns={columns}
          getRowId={(row) => uuidv4()}
        />
      </Box>

      {/* Dialog for role change */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Cambiar Permiso</DialogTitle>
        <DialogContent>
          <Typography variant="body1" gutterBottom>
            Selecciona un nuevo permiso para el usuario:
          </Typography>
          <FormControl fullWidth>
            <InputLabel>Rol</InputLabel>
            <Select
              value={selectedRole}
              onChange={(e) => setSelectedRole(e.target.value)}
              label="Rol"
            >
              {roles.map((role) => (
                <MenuItem key={role.value} value={role.label}>
                  {role.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary">
            Cancelar
          </Button>
          <Button onClick={handleConfirmRoleChange} color="primary">
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default EmployeeUserRolesList;
