import React, { useContext, useEffect } from "react";
import { menuData } from "./MenuData";
import CustomNavLink from "./NavLinkCustom";

// internal import
import { NovaContext } from "../context/index";

// Material ui import
import ListItemIcon from "@mui/material/ListItemIcon";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { renderIcon } from "../Helper/GlobalHelpers";
import { useTheme } from "@mui/material/styles";

const NavBarMenuList = () => {
  const { authenticatedUser } = useContext(NovaContext);

  const theme = useTheme();
  useEffect(() => {}, []);

  return authenticatedUser ? (
    <MenuList
      autoFocus={false}
      autoFocusItem={false}
      sx={{
        display: "flex",
        alignItems: { xs: "left", md: "center" },
        flexDirection: { xs: "column", md: "row" },
      }}
      // disablePadding
    >
      {menuData.map((item) => (
        <CustomNavLink
          to={item.link}
          key={item.link}
          style={{ textDecoration: "none", ml: { xs: 0, md: 2 } }}
          // sx={{mr:2}}

          activeStyle={{
            backgroundColor: theme.palette.secondary.main,
            borderRadius: "8px",
          }}
        >
          <MenuItem
            sx={{
              borderRadius: "8px",
              display: "flex", // Use flexbox to align items
              alignItems: "left", // Center icon and label vertically
              padding: "2px", // Reduce padding between items
              "&:hover": {
                backgroundColor: theme.palette.secondary.light,
              },
            }}
          >
            <ListItemIcon
              sx={{
                color: { xs: "black", md: "white" },
                // fontSize: { xs: 24, md: 50 }, // icon size
                minWidth: "auto",
                marginRight: 0,
              }}
            >
              {renderIcon(item)}
            </ListItemIcon>
            <Typography
              sx={{
                color: { xs: "black", md: "white" },
                fontSize: { xs: 14, md: 14, lg: 18 },
              }}
            >
              {item.title}
            </Typography>
          </MenuItem>
        </CustomNavLink>
      ))}
    </MenuList>
  ) : null;
};

export default NavBarMenuList;
