import { Box, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";

function BackendStatus() {
  const [isBackendAccessible, setIsBackendAccessible] = useState(true);
  const [lastErrorMessage, setLastErrorMessage] = useState(null);
  const [lastFailureTime, setLastFailureTime] = useState(null);

  useEffect(() => {
    const checkBackendStatus = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_IPADDRESS}:${process.env.REACT_APP_SERVER_PORT}/health`
        );
        if (response.ok) {
          const data = await response.json();
          if (data.status === "UP and Running") {
            setIsBackendAccessible(true);
            setLastErrorMessage(null);
            setLastFailureTime(null); // Reset failure time on success
            return;
          }
          throw new Error(`Unexpected response: ${JSON.stringify(data)}`);
        } else {
          throw new Error(`HTTP Error: ${response.status}`);
        }
      } catch (error) {
        setIsBackendAccessible(false);
        setLastErrorMessage(error.message);
        if (!lastFailureTime) {
          setLastFailureTime(new Date()); // Set failure time only once
        }
      }
    };

    checkBackendStatus(); // Initial check on mount

    const interval = setInterval(() => {
      checkBackendStatus();
    }, 30000); // Check every 30 seconds

    return () => {
      clearInterval(interval); // Clean up interval on unmount
    };
  }, []); // Watch lastFailureTime, ensures the effect runs when this state changes

  const timeSinceLastFailure = lastFailureTime
    ? Math.floor((new Date() - lastFailureTime) / 1000) // Time in seconds
    : null;

  return (
    <Box display="flex" alignItems="center">
      <Tooltip
        title={
          isBackendAccessible
            ? "El servidor está connectado"
            : `El servidor está desconnectado desde hace ${timeSinceLastFailure}s`
        }
      >
        <Box
          sx={{
            width: 20,
            height: 20,
            borderRadius: "50%",
            backgroundColor: isBackendAccessible ? "green" : "red",
            // marginRight: 0.5,
          }}
        />
      </Tooltip>{" "}
      <Box
        component="span"
        sx={{ display: { xs: "none", lg: "flex" }, color: "white" }}
      >
        {" "}
        {isBackendAccessible ? "En linea" : `Fuera de linea`}
      </Box>
    </Box>
  );
}

export default BackendStatus;
