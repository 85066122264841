import React, { useContext, useEffect, useState } from "react";
import { Typography, Box, Divider, Grid2 as Grid } from "@mui/material";
import {
  checkAccess,
} from "../../components/Helper/GlobalHelpers";
import {
  CircularProgressWithLabel,
  ItemPaper,
  ItemPaperHeader,
} from "../../components/Helper/MUIStyledComponents";
import { calculateGoalPercentage } from "../../components/Helper/ReportsHelpers";
import { NovaContext } from "../../components/context";

const FilialPaperCard = React.memo((props) => {
  const { filialObj, triggerPage, dataRange } = props;

  const { actions } = useContext(NovaContext);
  const [CashRegisterReport, setCashRegisterReport] = useState(0);
  const [reportTotalData, setReportTotalData] = useState({});
  const [loading, setLoading] = useState(true);
  const hasAdminAccess = checkAccess(filialObj.role_name, "Administrador");

  useEffect(() => {

    const fetchData = async () => {
      const filialId = filialObj.filial_id;

      // const cashRegisterReport = await actions.getCurrentCashRegisterReport(filialId);
      // setCashRegisterReport(cashRegisterReport);

      // const DataRange = dataRange;
      // DataRange.filialId = filialId;
      // const reportData = await actions.getReports(DataRange);
      // setReportTotalData(reportData);
      setLoading(false);
    };

    fetchData();
  }, [filialObj]);


  const goalPercentage = calculateGoalPercentage(CashRegisterReport, reportTotalData);
  // console.log(filialObj.filial_name, goalPercentage,dataRange, CashRegisterReport, reportTotalData);

  return (
    <ItemPaper
      onClick={triggerPage}
      sx={{
        cursor: "pointer",
        "&:hover": {
          backgroundColor: "lightgrey",
        },
      }}
    >
      <ItemPaperHeader
        sx={{
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
          fontWeight: "bold",
          fontSize: "2.5rem !important",
        }}
      >
        {filialObj.filial_name}
      </ItemPaperHeader>
      <Divider />
      <Grid container>
        <Grid size={{ xs: 12 }}>
          <Typography variant="h6">{filialObj.filial_address}</Typography>
        </Grid>
        <Grid size={{ xs: 12 }}>
          <Typography variant="h5" sx={{ mt: 1 }}>
            {" "}
            Tel.- {filialObj.telephone}
          </Typography>
        </Grid>
        {/**** commenting this area until it is correctly implemented **** */}
        {/* <Grid size={{ xs: 12 }}>
          {hasAdminAccess ? (
            loading ? 
              <div>Cargando...</div>
            :
            <CircularProgressWithLabel
            value={Math.floor(goalPercentage)}
            sx={{
              color: (theme) =>
                goalPercentage > 79.999
                  ? goalPercentage > 89.999
                    ? goalPercentage > 94.999
                      ? theme.palette.success.dark
                      : theme.palette.warning.main
                    : theme.palette.error.light
                  : theme.palette.error.dark,
            }}
              size={100}
              thickness={6}
              typographyVariant={"h4"}
            />
          ) : null}
        </Grid> */}
      </Grid>
    </ItemPaper>
  );
});

export default FilialPaperCard;
