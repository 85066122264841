import React, { useState } from "react";

// Material UI import
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const ProgressBar = (props) => {
  const { percentage } = props;

  const borderRadius = 30;

  // progress colours
  const red = "#f0324f";
  const orange = "#f5742f";
  const yellow = "#f5a22f";
  const green = "#66a303";

  // text colour
  const white = "#f8faf5";
  const black = "#2b2b2a";

  return (
    <Box
      width="100%"
      border="1px solid grey"
      borderRadius={`${borderRadius}px`}
      position="relative"
      height="28px"
      bgcolor="lightgray"
    >
      <Box
        position="absolute"
        width="100%"
        display="flex"
        justifyContent="center"
        lineHeight="28px"
        fontWeight="bold"
      >
        <Typography
          color={percentage === 0 ? white : black}
          variant="h5"
          align="center"
        >
          {percentage}% pagado
        </Typography>
      </Box>
      <Box
        maxWidth={`${percentage}%`}
        borderRadius={`${borderRadius}px`}
        height="100%"
        bgcolor={
          percentage < 20
            ? red
            : percentage < 50
            ? orange
            : percentage < 75
            ? yellow
            : green
        }
        boxSizing="inherit"
      />
    </Box>
  );
};

export default ProgressBar;
